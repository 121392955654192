import { IArticle } from '@graphql/article/types'
import { IGoodsReceiptLine, IGoodsReceiptLineLocation } from '@graphql/goodsReceipt/types'
import { ILocation } from '@graphql/location/types'
import { useStore } from '@store/store'
import { computed } from 'vue'

export default () => {
  const store = useStore()

  const activeGoodsReceipt = computed<IGoodsReceiptLine | undefined>(
    () => store.getters['goodsReceipt/activeGoodsReceipt']
  )
  const activeGoodsReceiptLine = computed<IGoodsReceiptLine | undefined>(
    () => store.getters['goodsReceipt/activeGoodsReceiptLine']
  )

  const goodsReceiptLineLocations = computed<IGoodsReceiptLineLocation[]>(() => {
    if (activeGoodsReceiptLine.value) {
      return activeGoodsReceiptLine.value.goodsReceiptLineLocation
    }

    return []
  })

  const goodsEnteredLocations = computed<ILocation[]>(() => {
    if (goodsReceiptLineLocations.value.length === 0) {
      return []
    }

    return goodsReceiptLineLocations.value.map((grll) => grll.location)
  })

  const lineArticle = computed<IArticle | null>(() => {
    if (activeGoodsReceiptLine.value) {
      return activeGoodsReceiptLine.value?.purchaseOrderLine.article
    }

    return null
  })

  return {
    activeGoodsReceipt,
    activeGoodsReceiptLine,
    goodsReceiptLineLocations,
    goodsEnteredLocations,
    lineArticle
  }
}
