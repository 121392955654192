import { Module } from 'vuex'
import { IRootState } from '../../types'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { IAuthState } from './types'

const state: IAuthState = {
  user: undefined,
  token: undefined,
  tokenLifetime: undefined
}

const auth: Module<IAuthState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default auth
