import { IGetPackStationsResponse, IPackStation } from '@graphql/pack/types'
import { useStore } from '@store/store'
import { computed } from 'vue'

export default () => {
  const store = useStore()

  const getPackStations = async (): Promise<IGetPackStationsResponse> =>
    store.dispatch('settings/getPackStations')
  const packStation = computed<IPackStation | undefined>(
    () => store.getters['settings/packStation']
  )
  const updatePackStation = async (payload: Partial<IPackStation> | IPackStation | undefined) =>
    store.dispatch('settings/setPackStation', payload)
  const hasPackStationConfigured = computed<boolean>(() => packStation.value !== undefined)

  const deviceId = computed<number | null>(() => store.getters['settings/deviceId'])
  const setDeviceId = async (context: number | null) =>
    store.dispatch('settings/setDeviceId', context)
  const unsetDeviceId = async () => store.dispatch('settings/setDeviceId', null)

  return {
    getPackStations,
    packStation,
    updatePackStation,
    hasPackStationConfigured,
    deviceId,
    setDeviceId,
    unsetDeviceId
  }
}
