import actions from '@store/modules/goods-receipt/actions'
import getters from '@store/modules/goods-receipt/getter'
import mutations from '@store/modules/goods-receipt/mutations'
import { IGoodsReceiptState } from '@store/modules/goods-receipt/types'
import { IRootState } from '@store/types'
import { Module } from 'vuex'

const state: IGoodsReceiptState = {
  activeGoodsReceipt: undefined,
  openGoodsReceipts: [],
  activeGoodsReceiptLine: undefined
}

const goodsReceipt: Module<IGoodsReceiptState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default goodsReceipt
