import Base from '@graphql/base'
import { AxiosInstance } from 'axios'
import { IGetByIdRequest, IGetByIdResponse } from '@graphql/task/types'
import GRAPHQL_API_CLIENT from '@graphql/client'

export class Task extends Base {
  constructor(protected axios: AxiosInstance) {
    super(axios, 'task')
  }

  async getById(request: IGetByIdRequest) {
    return this.makeRequest<IGetByIdResponse>('getByIdQuery', request)
  }
}

export default new Task(GRAPHQL_API_CLIENT)
