<template>
  <f7-list-item swipeout @swipeout:opened="onOverSwipe">
    <template #media>
      <search-result-icon :icon="['far', 'box']" />
    </template>
    <template #title>
      <string-label value="Collo" />
    </template>
    <template #subtitle> #{{ searchResult.searchPreview }} </template>
    <template v-if="searchResult.courier.code" #after-title>
      <f7-badge>{{ searchResult.courier.code }}</f7-badge>
    </template>
    <slot name="actions" />
  </f7-list-item>
</template>
<script lang="ts" setup>
import SearchResultIcon from '@components/search/SearchResultIcon.vue'
import StringLabel from '@components/label/StringLabel.vue'
import { ICollo } from '@graphql/shipment/types'

defineProps<{
  searchResult: Partial<ICollo>
}>()

const emit = defineEmits<{
  (e: 'over-swipe', result: Partial<ICollo>): void
}>()

const onOverSwipe = async (result: Partial<ICollo>) => emit('over-swipe', result)
</script>
