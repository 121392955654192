import { Module } from 'vuex'
import { IRootState } from '@store/types'
import actions from '@store/modules/sort/actions'
import { ISortState } from '@store/modules/sort/types'

const state: ISortState = {}

const sort: Module<ISortState, IRootState> = {
  namespaced: true,
  state,
  actions
}

export default sort
