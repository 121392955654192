<template>
  <f7-sheet
    swipe-to-close
    backdrop
    :opened="sheetOpen"
    class="sheet bg-color-white"
    @sheet:closed="onClosed"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-title>Stock counts</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onClosed">
            <f7-icon material="close" />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <stock-count-item-table
        @update:model-value="onPopupOpened"
        :stock-count-items="articleStockCounts"
      />
    </f7-page>
  </f7-sheet>
</template>
<script lang="ts" setup>
import StockCountItemTable from '@components/Stock/StockCountItemTable.vue'
import { IStockCountItem } from '@graphql/stock/types'

const sheetOpen = defineModel<boolean>()

defineProps<{
  articleStockCounts: IStockCountItem[]
}>()

const onClosed = () => {
  sheetOpen.value = false
}

const onPopupOpened = (isOpen: boolean) => {
  sheetOpen.value = !isOpen
}
</script>

<style scoped>
.sheet {
  height: 85vh;
}
</style>
