import { Router } from 'framework7/types'
import StartPage from '@pages/sorting/StartPage.vue'
import ProcessPage from '@pages/sorting/ProcessPage.vue'
import ScanSortLoadCarrierPage from '@pages/sorting/ScanSortLoadCarrierPage.vue'
import { redirectMiddleware } from '@framework7/routes/sorting/redirectMiddleware'
import ScanPickLoadCarrierPage from '@pages/sorting/ScanPickLoadCarrierPage.vue'
import PrintDocumentsPage from '@pages/sorting/PrintDocumentsPage.vue'
import PrintDocumentPage from '@pages/sorting/PrintDocumentPage.vue'

const sorting: Partial<Router.RouteParameters>[] = [
  {
    path: '/sorting/',
    component: StartPage
  },
  {
    path: '/sorting/entry/',
    redirect: redirectMiddleware
  },
  {
    path: '/sorting/:pickLoadCarrierId/process/:sortLoadCarrierId/',
    component: ProcessPage
  },
  {
    path: '/sorting/:pickLoadCarrierId/scan-sort-load-carrier/',
    component: ScanSortLoadCarrierPage
  },
  {
    path: `/sorting/:pickBatchId/scan-pick-load-carrier/`,
    component: ScanPickLoadCarrierPage
  },
  {
    path: `/sorting/:pickBatchId/print-documents/`,
    component: PrintDocumentsPage
  },
  {
    path: `/sorting/:pickBatchId/print-documents/:sortLoadCarrierId/`,
    component: PrintDocumentPage
  }
]

export default sorting
