import Base from '@graphql/base'
import GRAPHQL_API_CLIENT from '@graphql/client'
import {
  IBookReadyRequest,
  IDeleteGoodsEntryRequest,
  IGoodsEntryQueryContext,
  IGoodsEntryRequest,
  IGoodsReceiptQueryContext,
  IGoodsReceiptResponse,
  IStartGoodsEntryRequest
} from '@graphql/goodsReceipt/types'
import { AxiosInstance } from 'axios'
import { ID } from '@graphql/types'

class GoodsReceipt extends Base {
  constructor(protected axios: AxiosInstance) {
    super(axios, 'goodsReceipt')
  }

  public async getGoodsReceipt(context: IGoodsReceiptQueryContext) {
    return await this.makeRequest('GoodsReceiptQuery', context)
  }

  public async getGoodsEntry(id: ID) {
    return await this.makeRequest<IGoodsReceiptResponse>('GoodsEntryQuery', { id })
  }

  public async getGoodsEntries(context: IGoodsEntryQueryContext) {
    return await this.makeRequest('GoodsEntriesQuery', context)
  }

  public async createGoodsEntry(context: IGoodsEntryRequest) {
    return await this.makeRequest('GoodsEntryMutation', context)
  }

  public async removeGoodsEntry(context: IDeleteGoodsEntryRequest) {
    return await this.makeRequest('DeleteGoodsEntryMutation', context)
  }

  public async markAsBookReady(context: IBookReadyRequest) {
    return await this.makeRequest('BookReadyMutation', context)
  }

  public async getGoodsReceiptsTillState() {
    return await this.makeRequest('GoodsReceiptsTillStateQuery')
  }

  public async startGoodsEntry(context: IStartGoodsEntryRequest) {
    return await this.makeRequest('StartGoodsEntryMutation', context)
  }
}

export default new GoodsReceipt(GRAPHQL_API_CLIENT)
