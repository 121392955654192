<template>
  <f7-page :page-content="false">
    <navigation></navigation>
    <f7-page-content :class="{ 'skeleton-text': isLoading }">
      <f7-card>
        <f7-card-content>
          <f7-list media-list>
            <f7-list-item class="bg-color-yellow no-margin" media-list>
              This article has no weight defined! Weigh the article and enter the weight (in Grams)
              below.
            </f7-list-item>
            <f7-list-item header="Weight in Grams (1 kilogram = 1000 grams)">
              <input-number
                v-if="!isLoading"
                :value="weight"
                :auto-focus="true"
                @change="onWeightChange"
              />
            </f7-list-item>
          </f7-list>
        </f7-card-content>
      </f7-card>

      <f7-block-title>Article details</f7-block-title>
      <f7-list media-list inset>
        <f7-list-item header="Description">
          <string-label :value="article.name" />
        </f7-list-item>
        <f7-list-item header="Article code">
          <string-label :value="article.code" />
        </f7-list-item>
        <f7-list-item header="Primary barcode">
          <string-label :value="article.primaryBarcode" />
        </f7-list-item>
      </f7-list>
    </f7-page-content>
    <f7-fab position="center-bottom" @click="onConfirm">
      <f7-icon material="done" text="Confirm" />
    </f7-fab>
  </f7-page>
</template>
<script lang="ts" setup>
import InputNumber from '@components/form/InputNumber.vue'
import StringLabel from '@components/label/StringLabel.vue'
import Navigation from '@components/AppNavigation.vue'
import useGoodsEntry from '@composables/useGoodsEntry'
import useLoading from '@composables/useLoading'
import useProjectInbound from '@composables/useProjectInbound'
import { IGoodsReceipt, IGoodsReceiptLine } from '@graphql/goodsReceipt/types'
import { toast } from '@services/toast'
import { generateInboundItemId } from '@store/modules/projects/inbound/mutations'
import { IInboundProjectItem } from '@store/modules/projects/inbound/types'
import { f7 } from 'framework7-vue'
import { ref } from 'vue'

defineProps<{
  item: IGoodsReceiptLine
}>()

const weight = ref<number | string>('')

const { isLoading } = useLoading()
const { goodsEntries, activeGoodsEntry, setActiveGoodsEntry } = useProjectInbound()
const { activeGoodsReceiptLine, lineArticle: article } = useGoodsEntry()

const onConfirm = async () => {
  if (weight.value <= 0) {
    toast.error('Weight should be a positive number').open()
    return
  }

  const goodsReceiptLine = activeGoodsReceiptLine.value as IGoodsReceiptLine
  const inboundProject: IInboundProjectItem = {
    id: generateInboundItemId(goodsEntries.value as IGoodsReceipt, goodsReceiptLine),
    goodsReceiptLine,
    quantity: null,
    weight: Number(weight.value),
    location: null
  }

  await setActiveGoodsEntry(inboundProject)

  return f7.views.main.router.navigate('/goods-entry-item/', {
    props: {
      item: activeGoodsEntry.value,
      companyId: activeGoodsReceiptLine.value?.purchaseOrderLine.purchaseOrder.supplier.id,
      warehouseId: activeGoodsReceiptLine.value?.goodsReceipt.warehouse.id
    }
  })
}

const onWeightChange = (value: number | Event) => {
  if (value instanceof Event) {
    //@ts-ignore
    value = Number(value.target.value)
  }

  weight.value = value
}
</script>
