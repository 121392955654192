import { scanInput } from '@/utilities/scanInput'
import { f7 } from 'framework7-vue'

export const confirmThat = (text: string, callback?: () => void) => {
  scanInput.disable()
  f7.dialog
    .alert(text, () => {
      if (callback) {
        callback()
      }

      scanInput.enable()
    })
    .open()
}

export interface IConfirmYesNoOptions {
  title: string
  yesButtonText?: string
  yesButtonCallback?: () => void
  noButtonText?: string
  noButtonCallback?: () => void
}

export const confirmYesNo = (options: IConfirmYesNoOptions) => {
  const keyboardActions = Boolean(f7.params.dialog?.keyboardActions)

  f7.dialog
    .create({
      title: String(f7.params.dialog?.title ?? f7.name),
      text: options.title,
      destroyOnClose: f7.params.dialog?.destroyPredefinedDialogs,
      buttons: [
        {
          text: options.noButtonText ?? 'No',
          keyCodes: keyboardActions ? [27] : undefined,
          bold: true,
          color: f7.theme === 'aurora' ? 'gray' : undefined,
          onClick: options.noButtonCallback
        },
        {
          text: options.yesButtonText ?? 'Yes',
          keyCodes: keyboardActions ? [13] : undefined,
          bold: true,
          onClick: options.yesButtonCallback
        }
      ]
    })
    .open()
}
