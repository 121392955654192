import ScannerPickPage from '@pages/pick/PickStartPage.vue'
import ScannerPickLinePage from '@pages/pick/PickLinePage.vue'
import ScannerPickConfirmPicked from '@pages/pick/ConfirmPickedPage.vue'
import LoadCarrierDetailPage from '@pages/pick/LoadCarrierDetailPage.vue'
import { Router } from 'framework7/types'
import { redirect } from '@framework7/routes/pick/redirectMiddleware'

const pick: Partial<Router.RouteParameters>[] = [
  {
    path: '/pick-entry/',
    redirect
  },
  {
    path: '/pick/',
    component: ScannerPickPage,
    routes: [
      {
        path: 'line/:pickBatchId/',
        component: ScannerPickLinePage
      },
      {
        path: 'confirm-picked/',
        component: ScannerPickConfirmPicked,
        options: {
          props: {
            pickBatchId: undefined
          }
        }
      },
      {
        path: 'load-carrier/:loadCarrierId/',
        component: LoadCarrierDetailPage
      }
    ]
  }
]

export default pick
