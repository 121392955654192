<template>
  <f7-list-item swipeout @swipeout:opened="onOverSwipe">
    <template #media>
      <search-result-icon :icon="['far', 'truck']" />
    </template>
    <template #header> Purchase order </template>
    <template #title> PO{{ searchResult.searchPreview }} </template>
    <template #subtitle>
      {{ searchResult.supplier.name }}<br />
      <string-label
        v-if="searchResult.supplierCreditor"
        :value="searchResult.supplierCreditor.supplier.name"
      />
    </template>
    <slot name="actions" />
  </f7-list-item>
</template>
<script lang="ts" setup>
import StringLabel from '@components/label/StringLabel.vue'
import { IPurchaseOrder } from '@graphql/purchaseOrder/types'
import SearchResultIcon from '@components/search/SearchResultIcon.vue'

defineProps<{
  searchResult: Partial<IPurchaseOrder>
}>()

const emit = defineEmits<{
  (e: 'over-swipe', result: Partial<IPurchaseOrder>): void
}>()

const onOverSwipe = async (result: Partial<IPurchaseOrder>) => emit('over-swipe', result)
</script>
