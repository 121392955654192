import { ActionTree } from 'vuex'
import { IRootState } from '../../types'
import { SearchResults, ISearchState } from './types'

const actions: ActionTree<ISearchState, IRootState> = {
  async search({ commit }, items: SearchResults): Promise<SearchResults> {
    commit('setSearchResults', items)
    return items
  },
  async setSearchResults({ commit }, items: SearchResults) {
    commit('setSearchResults', items)
  },
  async clearSearchResults({ commit }) {
    commit('clearSearchResults')
  }
}

export default actions
