import { Router } from 'framework7/types'
import { store } from '@store/store'

export const beforeEnter = async ({ reject }: Router.RouteCallbackCtx) => {
  await store.dispatch('auth/logout')

  reject()

  return
}
