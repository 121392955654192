<template>
  <f7-page :page-content="false">
    <navigation>
      <f7-subnavbar class="no-padding">
        <search-bar @no-result="onNoResult" />
      </f7-subnavbar>
    </navigation>

    <f7-page-content>
      <f7-block-title class="margin-top">Relocate items in transit</f7-block-title>
      <f7-list media-list>
        <f7-list-item v-for="(item, key) in items" :key="key">
          <template #media>
            {{ item.qty }}
          </template>
          <template #header>
            <span v-if="item.moveAll"> Took all items from location</span>

            <div class="float-right">
              <location-chip :text="item.location.name"></location-chip>
            </div>
          </template>
          <template #title
            >({{ item.article.company.code }}) {{ item.article.primaryBarcode }}
          </template>
          <template #subtitle>
            <div style="font-size: 0.75rem">{{ item.article.name }}</div>
          </template>
          <template #footer>
            <br v-if="item.addedBy" />
            <string-label v-if="item.addedBy" :value="item.addedBy.name" />
            /
            <date-time-label v-if="item.addedOn" :value="item.addedOn" />
          </template>
        </f7-list-item>
      </f7-list>
    </f7-page-content>

    <location-suggestion-popup
      :opened="locationSuggesterPopupOpened"
      @close="closeLocationSuggester"
      @on-selected-item="onSelectedItem"
    >
    </location-suggestion-popup>
    <app-actions :actions="actions" @action-clicked="onActionClick" />
  </f7-page>
</template>
<script lang="ts" setup>
import DateTimeLabel from '@components/label/DateTime.vue'
import StringLabel from '@components/label/StringLabel.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import LocationChip from '@components/chip/LocationChip.vue'
import useProjectRelocate from '@composables/useProjectRelocate'
import useSearch from '@composables/useSearch'
import { UnionTypeSearchResult } from '@store/modules/search/types'
import { Permissions } from '@services/security/permissions'
import { confirmYesNo } from '@/functions/dialog'
import { f7 } from 'framework7-vue'
import { toast } from '@services/toast'
import { ref } from 'vue'
import LocationSuggestionPopup from '@components/LocationSuggestionPopup.vue'
import { ILocation } from '@graphql/location/types'
import AppActions from '@components/AppActions.vue'
import { IAction } from '@components/actions'
import useAction from '@composables/useAction'

defineEmits<{
  (e: 'result', result: UnionTypeSearchResult): void
  (e: 'results', results: UnionTypeSearchResult[]): void
  (e: 'no-result', query: string): void
}>()

const { items, clear } = useProjectRelocate()
const { onNoResult } = useSearch()
const { onActionClick } = useAction()

let locationSuggesterPopupOpened = ref<boolean>(false)
const actions = ref<IAction[]>([])

const closeLocationSuggester = () => {
  locationSuggesterPopupOpened.value = false
}

const onSelectedItem = (location: ILocation) => {
  closeLocationSuggester()
  f7.views.main.router.navigate(`/location/${location.id}/`, { item: location })
}

const openLocationSuggester = () => {
  locationSuggesterPopupOpened.value = true
}

const onDeleteProjects = async () => {
  await confirmYesNo({
    title: 'Are you sure that you want to clear this project?',
    yesButtonCallback: async () => {
      await clear()

      await toast.success('Cleared relocate project.').open()

      await f7.views.main.router.navigate('/home/')
    }
  })
}

actions.value.push({
  href: '#',
  name: 'Location Suggester',
  callback: () => openLocationSuggester(),
  icon: 'my_location'
})

actions.value.push({
  href: '#',
  name: 'Delete projects',
  callback: () => onDeleteProjects(),
  icon: 'delete_forever',
  permissions: Permissions.SCANNER_DELETE_PROJECTS
})
</script>
