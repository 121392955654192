<template>
  <table>
    <thead>
      <tr>
        <th class="label-cell"><br /></th>
        <th class="numeric-cell">Quantity ({{ totalFree }})</th>
        <th class="numeric-cell">Packing ({{ totalPacking }})</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(stockLevelResult, index) in stockLevelResults"
        :key="index"
        @click="onRowClick(stockLevelResult)"
      >
        <td class="label-cell">
          <slot name="reference" v-bind="stockLevelResult">
            {{ stockLevelResult.location.name }}
          </slot>
        </td>
        <td class="numeric-cell">{{ getPhysicalTotal(stockLevelResult.stockLevel) }}</td>
        <td class="numeric-cell">{{ stockLevelResult.stockLevel.packing }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script lang="ts" setup>
import { IStockLevelResult } from '@graphql/article/types'
import { getPhysicalTotal } from '@store/modules/article/helper'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    items: IStockLevelResult[]
  }>(),
  {
    items: () => []
  }
)

const emits = defineEmits<{
  (e: 'row-click', stockLevelResult: IStockLevelResult): void
}>()

const stockLevelResults = computed<IStockLevelResult[]>(() => props.items)

const totalFree = computed(() => {
  if (stockLevelResults.value.length === 0) {
    return 0
  }

  return stockLevelResults.value
    .map((stockLevelResult: IStockLevelResult) => getPhysicalTotal(stockLevelResult.stockLevel))
    .reduce((total: number, current: number) => total + current)
})

const totalPacking = computed(() => {
  if (stockLevelResults.value.length === 0) {
    return 0
  }

  return stockLevelResults.value
    .map((stockLevelResult) => stockLevelResult.stockLevel.packing)
    .reduce((total, current) => total + current)
})

const onRowClick = (stockLevelResult: IStockLevelResult) => {
  emits('row-click', stockLevelResult)
}
</script>
