import { IRootState } from '@store/types'
import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { IProjectCountState } from './types'

const state: IProjectCountState = {
  count: 0,
  title: 'Count',
  type: 'count',
  isActive: false,
  itemCount: 0,
  items: []
}

const projectCount: Module<IProjectCountState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default projectCount
