import { IPackStation } from '@graphql/pack/types'
import { ISettingState } from '@store/modules/settings/types'
import { MutationTree } from 'vuex'
import { ID } from '@graphql/types'

const mutations: MutationTree<ISettingState> = {
  setPackStation(state, payload: IPackStation) {
    state.packStation = payload
  },
  setDeviceId(state, payload: ID | null) {
    state.deviceId = payload
  }
}

export default mutations
