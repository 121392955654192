<template>
  <f7-page :page-content="false">
    <navigation />
    <f7-page-content>
      <f7-block-title class="margin-top" :class="{ 'skeleton-text': isLoading }">
        Pick batch #{{ id }}
      </f7-block-title>
      <f7-card :class="{ 'skeleton-text': isLoading }">
        <f7-card-content v-if="!isLoading && pickBatch">
          <f7-list media-list>
            <f7-list-item title="State" :subtitle="pickBatch?.humanState" />
            <f7-list-item title="Total lines" :subtitle="pickBatch?.lineCount" />
            <f7-list-item title="Created at">
              <template #subtitle>
                <date-time-label :value="pickBatch?.createdAt" />
              </template>
            </f7-list-item>
          </f7-list>
        </f7-card-content>
      </f7-card>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import DateTimeLabel from '@components/label/DateTime.vue'
import Navigation from '@components/AppNavigation.vue'
import useLoading from '@composables/useLoading'
import usePickBatch from '@composables/usePickBatch'
import { IPickBatch } from '@graphql/pick/types'
import { onMounted, ref } from 'vue'
import { ID } from '@graphql/types'

const props = defineProps<{
  id: ID
}>()

const { getById } = usePickBatch()
const { isLoading, withAsyncLoading } = useLoading()

const pickBatch = ref<IPickBatch>()

onMounted(async () => {
  await withAsyncLoading(async () => {
    pickBatch.value = await getById(props.id)
  })
})
</script>
