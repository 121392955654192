import Base from '@graphql/base'
import GRAPHQL_API_CLIENT from '@graphql/client'
import {
  IDeregisterContext,
  IDeregisterResponse,
  IRegisterContext,
  IRegisterResponse
} from '@graphql/device/types'
import { AxiosInstance, AxiosResponse } from 'axios'

class Device extends Base {
  constructor(axios: AxiosInstance) {
    super(axios, 'device')
  }

  public async register(context: IRegisterContext): Promise<AxiosResponse<IRegisterResponse>> {
    const currentToken = this.authToken

    this.authToken = context.authToken

    const response = await this.makeRequest<IRegisterResponse>('registerMutation', context)

    this.authToken = currentToken

    return response
  }

  public async deregister(
    context: IDeregisterContext
  ): Promise<AxiosResponse<IDeregisterResponse>> {
    return await this.makeRequest('deregisterMutation', context)
  }
}

export default new Device(GRAPHQL_API_CLIENT)
