<template>
  <DataTableCard :items="stockCountItems" :is-collapsible="false" :is-loading="isLoading">
    <template #no-items>
      <icon-with-text :icon="['far', 'abacus']" title="No stock counts available" />
    </template>
    <DataTable>
      <template #header>
        <table-row>
          <label-cell element="th">Location</label-cell>
          <label-cell element="th">Counted qty</label-cell>
          <label-cell element="th">Expected qty</label-cell>
        </table-row>
      </template>
      <table-row
        v-for="stockCountItem in stockCountItems"
        :key="stockCountItem.id"
        @click="onItemClick(stockCountItem)"
      >
        <label-cell data-collapsible-title="Location">
          {{ stockCountItem.location.name }}
        </label-cell>
        <label-cell data-collapsible-title="Counted qty">
          {{ stockCountItem.countedQty }}
        </label-cell>
        <label-cell data-collapsible-title="Expected qty">
          {{ stockCountItem.systemQty }}
        </label-cell>
      </table-row>
    </DataTable>
  </DataTableCard>
  <f7-popup :opened="popupOpened" @popup:closed="popupOpened = false">
    <div>
      <stock-count-popup v-if="activeStockCountItem" :stock-count-item="activeStockCountItem" />
    </div>
  </f7-popup>
</template>

<script lang="ts" setup>
import { IStockCountItem } from '@graphql/stock/types'
import DataTableCard from '@components/data-table/DataTableCard.vue'
import DataTable from '@components/data-table/DataTable.vue'
import LabelCell from '@components/data-table/LabelCell.vue'
import TableRow from '@components/data-table/TableRow.vue'
import IconWithText from '@components/IconWithText.vue'
import { ref } from 'vue'
import StockCountPopup from '@components/StockCountPopup.vue'

const popupOpened = defineModel<boolean>({ default: false })
const activeStockCountItem = ref<IStockCountItem>()

withDefaults(
  defineProps<{
    stockCountItems: IStockCountItem[]
    isLoading: boolean
  }>(),
  {
    isLoading: false
  }
)

const onItemClick = (stockCountItem: IStockCountItem) => {
  activeStockCountItem.value = stockCountItem
  popupOpened.value = true
}
</script>
