<template>
  <f7-page :page-content="false">
    <app-navigation />
    <f7-page-content>
      <f7-block-title class="margin-top">Sorting projects</f7-block-title>
      <f7-block v-if="!itemCount">
        <p>No open sorting tasks.</p>
      </f7-block>
      <f7-list media-list>
        <f7-list-item v-for="item in items" :key="item.pickBatchId" @click="onClick(item)">
          <template #title>Pick batch: #{{ item.pickBatchId }}</template>
        </f7-list-item>
      </f7-list>
      <is-granted :attributes="Permissions.SCANNER_DELETE_PROJECTS">
        <f7-fab position="right-bottom" @click="onDeleteProject">
          <font-awesome-icon :icon="['far', 'trash']" />
        </f7-fab>
      </is-granted>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import AppNavigation from '@components/AppNavigation.vue'
import { f7 } from 'framework7-vue'
import useProjectSorting from '@composables/useProjectSorting'
import { ISortingProject } from '@store/modules/projects/sorting/types'
import { Permissions } from '@services/security/permissions'
import IsGranted from '@components/IsGranted.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { confirmYesNo } from '@/functions/dialog'
import { toast } from '@services/toast'
import { findRedirectUrlForProject } from '@framework7/routes/sorting/redirectMiddleware'
import useSort from '@composables/useSort'

const { itemCount, items, clearProjects } = useProjectSorting()
const { getPickBatchSortState } = useSort()

const onClick = async (project: ISortingProject) => {
  const pickBatchSortState = await getPickBatchSortState({ pickBatchId: project.pickBatchId })

  const url = await findRedirectUrlForProject(project, pickBatchSortState)

  await f7.views.main.router.navigate(url)
}

const onDeleteProject = async () => {
  await confirmYesNo({
    title: 'Are you sure that you want to clear the sort project!',
    yesButtonCallback: async () => {
      await clearProjects()

      await toast.success('Cleared sort project.').open()

      await f7.views.main.router.navigate('/home/')
    }
  })
}
</script>
