<template>
  <f7-page :page-content="false">
    <navigation />
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#gr-detail" tab-link-active>Details</f7-link>
      <f7-link tab-link="#gr-articles">Articles</f7-link>
      <f7-link tab-link="#gr-reception">Reception</f7-link>
    </f7-toolbar>

    <f7-tabs>
      <f7-tab id="gr-detail" class="page-content" tab-active>
        <f7-block-title
          v-if="goodsReceipt"
          class="margin-top"
          :class="{ 'skeleton-text': isLoading }"
        >
          Goods receipt #{{ goodsReceipt.id }}
        </f7-block-title>
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list v-if="goodsReceipt && goodsReceipt.purchaseOrder" media-list>
              <f7-list-item header="Purchase order">
                <string-label
                  :value="`${goodsReceipt.purchaseOrder.id} / ${goodsReceipt.purchaseOrder.reference}`"
                />
              </f7-list-item>
              <f7-list-item header="State">
                <string-label
                  :value="goodsReceipt.stateString"
                  :fallback-value="goodsReceipt.state"
                />
              </f7-list-item>
              <f7-list-item v-if="goodsReceipt.purchaseOrder.supplier" header="Supplier">
                <string-label :value="goodsReceipt.purchaseOrder.supplier.name" />
              </f7-list-item>
              <f7-list-item header="Delivered at">
                <date-time-label :value="goodsReceipt.deliveredAt" />
              </f7-list-item>
              <f7-list-item header="Booked at">
                <date-time-label :value="goodsReceipt.bookedAt" />
              </f7-list-item>
              <f7-list-item header="Created at">
                <date-time-label :value="goodsReceipt.createdAt" />
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
        <f7-card v-if="goodsReceipt && goodsReceipt.note" :class="{ 'skeleton-text': isLoading }">
          <f7-card-header> Notes </f7-card-header>
          <f7-card-content>
            {{ goodsReceipt.note }}
          </f7-card-content>
        </f7-card>
      </f7-tab>

      <f7-tab id="gr-articles" class="page-content">
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list media-list v-if="goodsReceiptLines">
              <f7-list-item
                v-for="goodsReceiptLineEdge in goodsReceiptLines.edges"
                :key="goodsReceiptLineEdge.cursor"
              >
                <template #header>
                  {{ goodsReceiptLineEdge.node.purchaseOrderLine.article.name }}
                </template>
                <template #title>
                  {{ getDeliveryQty(goodsReceiptLineEdge.node) }}/{{
                    goodsReceiptLineEdge.node.amountExpected
                  }}
                  Delivered<br />
                </template>
                <template #subtitle>
                  <div
                    v-if="
                      goodsReceiptLineEdge.node.goodsReceiptLineLocation &&
                      goodsReceiptLineEdge.node.goodsReceiptLineLocation.length > 0
                    "
                  >
                    <div
                      v-for="goodsReceiptLineLocation in goodsReceiptLineEdge.node
                        .goodsReceiptLineLocation"
                      :key="goodsReceiptLineLocation.id"
                    >
                      {{
                        flattenGoodsLocations(goodsReceiptLineEdge.node.goodsReceiptLineLocation)
                      }}
                    </div>
                  </div>
                </template>
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-tab>

      <f7-tab id="gr-reception" class="page-content">
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content v-if="goodsReceipt">
            <f7-list media-list>
              <f7-list-item header="Num. of Sku's">
                <string-label :value="goodsReceipt.numSkus" fallback-value="0" />
              </f7-list-item>
              <f7-list-item header="Num. of unique Sku's" v-if="goodsReceipt.numUniqueSkus">
                <string-label :value="goodsReceipt.numUniqueSkus" fallback-value="0" />
              </f7-list-item>
              <f7-list-item header="Num. of colli">
                <string-label :value="goodsReceipt.numColli" fallback-value="0" />
              </f7-list-item>
              <f7-list-item header="Num. of pallet">
                <string-label :value="goodsReceipt.numPallet" fallback-value="0" />
              </f7-list-item>
              <f7-list-item header="Num. of block pallet">
                <string-label :value="goodsReceipt.numBlockPallet" fallback-value="0" />
              </f7-list-item>
              <f7-list-item header="Num. of container 20ft">
                <string-label :value="goodsReceipt.numContainer20Ft" fallback-value="0" />
              </f7-list-item>
              <f7-list-item header="Num. of container 40ft">
                <string-label :value="goodsReceipt.numContainer40Ft" fallback-value="0" />
              </f7-list-item>
              <f7-list-item header="Num. of container 40ft high cube">
                <string-label :value="goodsReceipt.numContainer40FtHighCube" fallback-value="0" />
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-tab>
    </f7-tabs>
    <app-actions v-if="actions.length > 0" :actions="actions" @action-clicked="onActionClick" />
  </f7-page>
</template>
<script lang="ts" setup>
import { IAction } from '@components/actions'
import AppActions from '@components/AppActions.vue'
import DateTimeLabel from '@components/label/DateTime.vue'
import StringLabel from '@components/label/StringLabel.vue'
import Navigation from '@components/AppNavigation.vue'
import useAuthentication from '@composables/useAuthentication'
import useGoodsReceipt from '@composables/useGoodsReceipt'
import useLoading from '@composables/useLoading'
import useProjectInbound from '@composables/useProjectInbound'
import { IGoodsReceipt, IGoodsReceiptLine, StateEnum } from '@graphql/goodsReceipt/types'
import { IInboundProject } from '@store/modules/projects/inbound/types'
import { f7 } from 'framework7-vue'
import { computed, onMounted, ref } from 'vue'

const props = defineProps<{
  id: ID
}>()

const { isLoading, withAsyncLoading } = useLoading()
const { getGoodsReceipt, startGoodsEntry, flattenGoodsLocations } = useGoodsReceipt()
const { add: addInboundProject } = useProjectInbound()
const { currentUser } = useAuthentication()

const actions = ref<IAction[]>([])
const goodsReceipt = ref<IGoodsReceipt | undefined>(undefined)

const goodsReceiptLines = computed(() =>
  goodsReceipt.value ? goodsReceipt.value?.goodsReceiptLine : undefined
)

const onActionClick = async (action: IAction) => {
  if (action.href === '/projects/inbound/') {
    const project: IInboundProject = {
      id: action.props.item.id,
      items: [],
      addedOn: new Date(),
      addedBy: currentUser.value
    }

    await addInboundProject(project)

    await startGoodsEntry({ goodsReceiptId: props.id })

    await f7.views.main.router.navigate(`/goodsentry/${project.id}/`)
  }
}

const getDeliveryQty = (goodsReceiptLine: IGoodsReceiptLine) => {
  if (goodsReceiptLine.goodsReceiptLineLocation.length === 0) {
    return 0
  }

  return goodsReceiptLine.goodsReceiptLineLocation
    .map((l) => l.amount)
    .reduce((total, current) => total + current)
}

onMounted(async () => {
  await withAsyncLoading(async () => {
    goodsReceipt.value = await getGoodsReceipt({ id: props.id, completed: false })

    if (
      goodsReceipt.value &&
      (goodsReceipt.value.state === StateEnum.GOODS_RECEPTION ||
        goodsReceipt.value.state === StateEnum.GOODS_ENTRY)
    ) {
      actions.value.push({
        name: 'Goods entry',
        href: '/projects/inbound/',
        props: {
          item: goodsReceipt.value
        }
      })
    }
  })
})
</script>
