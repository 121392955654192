import { Module } from 'vuex'
import { IProjectSortingState } from '@store/modules/projects/sorting/types'
import { IRootState } from '@store/types'
import actions from '@store/modules/projects/sorting/actions'
import mutations from '@store/modules/projects/sorting/mutations'
import getters from '@store/modules/projects/sorting/getters'

const state: IProjectSortingState = {
  count: 0,
  itemCount: 0,
  title: 'Sorting',
  type: 'sorting',
  items: [],
  isActive: false,
  link: '/project/sorting/',
  activeItem: null
}

const projectSorting: Module<IProjectSortingState, IRootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default projectSorting
