import { useStore } from '@store/store'
import { ID } from '@graphql/types'

export default () => {
  const store = useStore()

  const getById = async (pickBatchId: ID) => store.dispatch('pickBatch/getById', pickBatchId)

  return {
    getById
  }
}
