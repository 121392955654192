import { soundBoard } from '@services/sound'
import { toast } from '@services/toast'

export class PerceptibleToast {
  public async success(message: string, closeTimeout = 3000, closeButton = true) {
    await soundBoard.playSuccessSound()

    await toast.success(message, closeTimeout, closeButton).open()
  }

  public async finishedTask(message: string, closeTimeout = 3000, closeButton = true) {
    await soundBoard.playTaskCompleteSound()

    await toast.success(message, closeTimeout, closeButton).open()
  }

  public async staticSuccess(message: string, closeButton = true) {
    await this.success(message, 0, closeButton)
  }

  public async error(message: string, closeTimeout = 5000, closeButton = true) {
    await soundBoard.playFailedSound()

    await toast.error(message, closeTimeout, closeButton).open()
  }

  public async noSearchHit(message: string, closeTimeout = 3000, closeButton = true) {
    await soundBoard.playSearchNoHitsSound()

    await toast.error(message, closeTimeout, closeButton).open()
  }

  public async staticError(message: string, closeButton = true) {
    await this.error(message, 0, closeButton)
  }
}

export const perceptibleToast = new PerceptibleToast()
