import Base from '@graphql/base'
import GRAPHQL_API_CLIENT from '@graphql/client'
import { ID, IGraphQLResponse } from '@graphql/types'
import { AxiosInstance, AxiosResponse } from 'axios'

class PurchaseOrder extends Base {
  constructor(protected axios: AxiosInstance) {
    super(axios, 'purchaseOrder')
  }

  public getPurchaseOrder(id: ID): Promise<AxiosResponse<IGraphQLResponse>> {
    return this.makeRequest('PurchaseOrderQuery', {
      id
    })
  }

  public getPurchaseOrdersToHandle(): Promise<AxiosResponse<IGraphQLResponse>> {
    return this.makeRequest('PurchaseOrdersToHandleQuery', {})
  }
}

export default new PurchaseOrder(GRAPHQL_API_CLIENT)
