import { MutationTree } from 'vuex'
import { IAuthState, IUser } from './types'
import { IAuthToken } from '@graphql/auth/types'

const mutations: MutationTree<IAuthState> = {
  updateUser(state, user: IUser) {
    state.user = user
  },

  removeUser(state) {
    state.user = undefined
  },

  setTokenLifetime(state, lifetime: number) {
    state.tokenLifetime = lifetime
  },

  updateToken(state, token: IAuthToken) {
    state.token = token.token
    state.tokenLifetime = token.lifetime
  },

  removeToken(state) {
    state.token = undefined
    state.tokenLifetime = undefined
  }
}

export default mutations
