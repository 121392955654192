import articleApi from '@graphql/article'
import { IArticle } from '@graphql/article/types'
import { IArticleRequestContext, IArticleState } from '@store/modules/article/types'
import { IRootState } from '@store/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<IArticleState, IRootState> = {
  async setActiveArticle({ commit }: ActionContext<IArticleState, IRootState>, article: IArticle) {
    commit('setActiveArticle', article)
  },

  async getArticle(
    { commit }: ActionContext<IArticleState, IRootState>,
    context: IArticleRequestContext
  ) {
    const response = await articleApi.getArticle(context)

    await commit('setActiveArticle', response.data.data.article)

    return response.data.data.article
  }
}

export default actions
