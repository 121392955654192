import { MutationTree } from 'vuex'
import { SearchResults, ISearchState } from './types'

const mutations: MutationTree<ISearchState> = {
  setSearchResults(state, searchResults: SearchResults): void {
    state.searchResults = searchResults
  },
  clearSearchResults(state) {
    state.searchResults = []
  }
}

export default mutations
