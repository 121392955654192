<template>
  <f7-page :page-content="false">
    <navigation />
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#sorting-compartment">Sort</f7-link>
      <f7-link tab-link="#sorting-todo">Todo ({{ itemTodoCount }})</f7-link>
      <f7-link tab-link="#sorting-done">Compartments ({{ sortCompartments.length }})</f7-link>
    </f7-toolbar>
    <f7-tabs v-if="sortingResult">
      <f7-tab id="sorting-compartment" class="page-content" tab-active>
        <compartment-tab
          :compartment-direction-result="sortingResult.compartmentDirectionResult"
          :sort-completed="isSortCompleted"
          @wants-new-sort-compartment="onWantsNewSortCompartment"
          @placement-confirmed="onPlacementConfirmed"
          :is-loading="isLoading"
        />
      </f7-tab>
      <f7-tab
        id="sorting-todo"
        class="page-content ptr-content"
        :data-ptr-distance="55"
        :data-ptr-mousewheel="true"
        @ptr:refresh="onPullToRefresh"
      >
        <pull-to-refresh-preloader />
        <sort-items-tab :items="itemsTodo" :is-loading="isLoading">
          <template #no-result>
            <icon-with-text :icon="['far', 'laugh-beam']" title="Hooray, all done!" />
          </template>
        </sort-items-tab>
      </f7-tab>
      <f7-tab
        id="sorting-done"
        class="page-content"
        :data-ptr-distance="55"
        :data-ptr-mousewheel="true"
        @ptr:refresh="onPullToRefresh"
      >
        <pull-to-refresh-preloader />
        <sort-compartments-tab :sort-compartments="sortCompartments" />
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>
<script lang="ts" setup>
import SortItemsTab from '@components/sorting/SortItemsTab.vue'
import Navigation from '@components/AppNavigation.vue'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import IconWithText from '@components/IconWithText.vue'
import useLoading from '@composables/useLoading'
import PullToRefreshPreloader from '@components/PullToRefreshPreloader.vue'
import CompartmentTab from '@components/sorting/CompartmentTab.vue'
import useProjectSorting from '@composables/useProjectSorting'
import { perceptibleToast } from '@services/perceptibleToast'
import { eventBus } from '@/utilities/scanInput'
import { f7, f7ready } from 'framework7-vue'
import { confirmThat } from '@/functions/dialog'
import { toast } from '@services/toast'
import { ID } from '@graphql/types'
import {
  IPickLoadCarrierSortResult,
  IPutItemInCompartmentResult,
  ISortableItem,
  ISortCompartment,
  SortCompartment
} from '@graphql/sort/types'
import useSort from '@composables/useSort'
import { soundBoard } from '@services/sound'
import { findRedirectUrlForProject } from '@framework7/routes/sorting/redirectMiddleware'
import SortCompartmentsTab from '@components/sorting/SortCompartments.vue'

const props = defineProps<{
  pickLoadCarrierId: ID
  sortLoadCarrierId: ID
}>()

const { withAsyncLoading, isLoading } = useLoading()
const { putArticleInSortCompartment, wantsNewCompartment, getSortResult, getPickBatchSortState } =
  useSort()
const { activeItem } = useProjectSorting()

const sortingResult = ref<IPickLoadCarrierSortResult>()
const placementConfirmed = ref<boolean>(false)

const itemsTodo = computed<ISortableItem[]>(() => {
  if (!sortingResult.value) {
    return []
  }

  return sortingResult.value.itemsTodo
})

const itemsDone = computed<ISortableItem[]>(() => {
  if (!sortingResult.value) {
    return []
  }

  return sortingResult.value.itemsDone
})

const sortCompartments = computed<ISortCompartment[]>(() => {
  if (!sortingResult.value) {
    return []
  }
  return sortingResult.value.sortCompartments
})

const isSortCompleted = computed<boolean>(() => {
  if (sortingResult.value) {
    return sortingResult.value.completed
  }
  return itemsTodo.value.length === 0 && itemsDone.value.length > 0
})

const itemTodoCount = computed(() => {
  if (itemsTodo.value.length === 0) {
    return 0
  }

  return itemsTodo.value
    .map((item) => item.quantity - item.quantitySorted)
    .reduce((total, current) => total + current)
})

const onPullToRefresh = async () => {
  await withAsyncLoading(async () => {
    sortingResult.value = await getSortResult({ pickLoadCarrierId: props.pickLoadCarrierId })
  })
}

const unsubscribe = eventBus.on('scanInput', async (event) => {
  await onResult(String(event.payload))
})

const mustConfirmPlacementOfItemWithBarcode = (barcode: string): boolean => {
  if (placementConfirmed.value) {
    return false
  }

  if (!sortingResult.value?.compartmentDirectionResult) {
    return false
  }

  return sortingResult.value?.compartmentDirectionResult?.compartmentReference?.value === barcode
}

const onResult = async (barcode: string) => {
  await withAsyncLoading(async () => {
    try {
      if (mustConfirmPlacementOfItemWithBarcode(barcode)) {
        await onPlacementConfirmed()

        await soundBoard.playSearchSingleHit()
        return
      }

      if (!placementConfirmed.value && sortingResult.value?.compartmentDirectionResult) {
        await soundBoard.playFailedSound()
        await confirmThat(`Confirm first, before scanning the next item`)

        return
      }

      const result = await putArticleInSortCompartment({
        barcode,
        pickLoadCarrierId: props.pickLoadCarrierId
      })

      if (result.isSortLoadCarrierFull) {
        await handleFullSortLoadCarrier()

        return
      }

      if (sortingResult.value) {
        sortingResult.value.compartmentDirectionResult = result
      }

      placementConfirmed.value = false

      if (!result.sortableItem) {
        return
      }

      await soundBoard.playSuccessSound()

      const itemIndex = itemsTodo.value.findIndex(
        (itemTodo) => itemTodo.identifier === result.sortableItem?.identifier
      )
      if (itemIndex === -1) {
        return
      }

      itemsTodo.value[itemIndex].quantitySorted++ //TODO: server state needs to be fixed.

      await updateSortingResultCompartmentsState(sortingResult.value, result)

      if (itemsTodo.value[itemIndex].quantity === itemsTodo.value[itemIndex].quantitySorted) {
        itemsDone.value.push(itemsTodo.value[itemIndex])
        itemsTodo.value.splice(itemIndex, 1)
      }
    } catch (e: any) {
      await perceptibleToast.error(e.message)
    }
  })
}

const updateSortingResultCompartmentsState = (
  state: IPickLoadCarrierSortResult,
  result: IPutItemInCompartmentResult
) => {
  const compartmentIndex = state.sortCompartments.findIndex(
    (sortCompartment) =>
      sortCompartment.compartmentReference.value === result.compartmentReference.value
  )

  if (compartmentIndex === -1) {
    state.sortCompartments.push(
      new SortCompartment(result.compartmentReference, [result.sortableItem])
    )

    return
  }

  const compartment = state.sortCompartments[compartmentIndex]
  const sortItemIndex = compartment.sortableItems.findIndex(
    (sortableItem) => sortableItem.identifier === result.sortableItem?.identifier
  )

  if (sortItemIndex === -1) {
    state.sortCompartments[compartmentIndex].sortableItems.push(result.sortableItem)
    return
  }

  state.sortCompartments[compartmentIndex].sortableItems[sortItemIndex] = result.sortableItem
}

const onPlacementConfirmed = async () => {
  if (!sortingResult.value) {
    return
  }

  if (sortingResult.value.compartmentDirectionResult?.isPickLoadCarrierSorted) {
    await confirmThat(
      `Sorting complete, confirm that all items have been sorted.`,
      onAllItemsSorted
    )
  }

  sortingResult.value.compartmentDirectionResult = null
  placementConfirmed.value = true
}

const onWantsNewSortCompartment = async () => {
  //TODO: only allow for non empty compartments
  await withAsyncLoading(async () => {
    const result = await wantsNewCompartment({
      loadCarrierItemId: String(sortingResult.value?.compartmentDirectionResult?.loadCarrierItemId)
    })

    if (result.isSortLoadCarrierFull) {
      await handleFullSortLoadCarrier()

      return
    }

    if (sortingResult.value) {
      sortingResult.value.compartmentDirectionResult = result
    }
  })
}

const handleFullSortLoadCarrier = async () => {
  await toast.success('Sort load carrier is full, please add a new one').open()

  await f7.views.main.router.navigate(`/sorting/${props.pickLoadCarrierId}/scan-sort-load-carrier/`)
}

const onAllItemsSorted = async () => {
  const pickBatchId = activeItem.value?.pickBatchId

  try {
    const pickBatchSortState = await getPickBatchSortState({ pickBatchId })

    const url = await findRedirectUrlForProject(activeItem.value, pickBatchSortState)

    await f7.views.main.router.navigate(url)
  } catch (e) {
    await perceptibleToast.error(e.message)
    throw e
  }
}

onMounted(async () => {
  await onPullToRefresh()
  if (sortingResult.value && sortingResult.value?.completed) {
    await onAllItemsSorted()
  }

  await f7ready((f7) => {
    f7.on('pageBeforeOut', unsubscribe)
  })
})

onBeforeUnmount(() => {
  unsubscribe()
})
</script>
