import { useStore } from '@store/store'
import { computed } from 'vue'
import { IRelocateActionItem, IRelocateItem } from '@store/modules/projects/relocate/types'

export default function () {
  const store = useStore()

  const isActive = computed<boolean>(() => store.getters['projects/relocate/isActive'])
  const items = computed<IRelocateItem[]>(() => store.getters['projects/relocate/items'])
  const itemCount = computed<number>(() => store.getters['projects/relocate/itemCount'])

  const relocateToLocation = async (payload: IRelocateActionItem) =>
    store.dispatch('projects/relocate/relocateToLocation', payload)
  const relocateToTransitStock = async (payload: IRelocateActionItem) =>
    store.dispatch('projects/relocate/relocateToTransitStock', payload)
  const relocateFromTransitStock = async (payload: IRelocateActionItem) =>
    store.dispatch('projects/relocate/relocateFromTransitStock', payload)

  const add = async (payload: IRelocateItem) => store.commit('projects/relocate/add', payload)
  const remove = async (payload: IRelocateItem) => store.commit('projects/relocate/remove', payload)
  const clear = async () => store.commit('projects/relocate/clear')

  return {
    isActive,
    items,
    itemCount,
    relocateToLocation,
    relocateToTransitStock,
    relocateFromTransitStock,
    add,
    remove,
    clear
  }
}
