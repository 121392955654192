<template>
  <f7-page :page-content="false">
    <app-navigation />
    <f7-page-content>
      <f7-block :class="{ 'skeleton-text': isLoading }">
        <f7-block-title>Finalizing sort</f7-block-title>

        <p>
          In order to fully finalize the sort process, a label needs to be printed for each sort
          load carrier. Please scan each sort load carrier shown in the list below in order to print
          the labels and finalize the process.
        </p>
      </f7-block>

      <f7-block-title :class="{ 'skeleton-text': isLoading }">Load carriers</f7-block-title>
      <f7-card :class="{ 'skeleton-text': isLoading }">
        <f7-list v-if="pickBatchSortState">
          <f7-list-item
            v-for="sortLoadCarrierReference in pickBatchSortState.sortLoadCarrierReferences"
            :key="sortLoadCarrierReference.identifier"
            :header="sortLoadCarrierReference.identifier"
            :title="sortLoadCarrierReference.reference"
            :swipeout="sortLoadCarrierReference.sorted"
            @swipeout:opened="onOverSwipeLeft(sortLoadCarrierReference)"
          >
            <template #after-title>
              <font-awesome-icon
                :icon="['far', 'check']"
                :class="calculateStateClass(sortLoadCarrierReference.sorted)"
              />
            </template>

            <f7-swipeout-actions left>
              <f7-swipeout-button
                color="dimass"
                overswipe
                close
                v-if="sortLoadCarrierReference.sorted"
              >
                <f7-icon material="swipe-right" class="text-color-white" />
              </f7-swipeout-button>
            </f7-swipeout-actions>
          </f7-list-item>
        </f7-list>
      </f7-card>
    </f7-page-content>
    <app-actions :actions="actions" v-if="actions.length > 0" @action-clicked="onActionClick" />
  </f7-page>
</template>
<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import useLoading from '@composables/useLoading'
import { ID } from '@graphql/types'
import { ILoadCarrierReference, IPickBatchSortState } from '@graphql/sort/types'
import AppNavigation from '@components/AppNavigation.vue'
import useSort from '@composables/useSort'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { eventBus } from '@/utilities/scanInput'
import { perceptibleToast } from '@services/perceptibleToast'
import { f7 } from 'framework7-vue'
import AppActions from '@components/AppActions.vue'
import { IAction } from '@components/actions'
import { confirmYesNo } from '@/functions/dialog'
import useAction from '@composables/useAction'
import { toast } from '@services/toast'
import useProjectSorting from '@composables/useProjectSorting'

const props = defineProps<{
  pickBatchId: ID
}>()

const { onActionClick } = useAction()
const { withAsyncLoading, isLoading } = useLoading()
const { getPickBatchSortState, calculateStateClass } = useSort()
const { removeProject } = useProjectSorting()

const pickBatchSortState = ref<IPickBatchSortState | undefined>()
const captureScanInput = ref<boolean>(true)
const actions = ref<IAction[]>([])

const unsubscribe = eventBus.on('scanInput', async (event) => {
  if (!captureScanInput.value) {
    return
  }

  await onScanInput(String(event.payload))
})

const sortLoadCarrierReferences = computed<ILoadCarrierReference[]>(() => {
  if (!pickBatchSortState.value) {
    return []
  }
  return pickBatchSortState.value?.sortLoadCarrierReferences ?? []
})

const onScanInput = async (query: string) => {
  if (!query || query.length === 0) {
    await perceptibleToast.error('Scan not recognized')
    return
  }

  const index = sortLoadCarrierReferences.value.findIndex(
    (loadCarrier) => loadCarrier.reference === query
  )

  if (index === -1) {
    await perceptibleToast.error(`Sort Load carrier with barcode ${query} not found!`)
    return
  }

  captureScanInput.value = false

  const sortLoadCarrier = sortLoadCarrierReferences.value[index]

  if (sortLoadCarrier.sorted) {
    await perceptibleToast.error(`Document is already printed for ${query}!`)
    return
  }

  await f7.views.main.router.navigate(
    `/sorting/${props.pickBatchId}/print-documents/${sortLoadCarrier.identifier}/`
  )
}

const onOverSwipeLeft = async (loadCarrierReference: ILoadCarrierReference) => {
  if (!loadCarrierReference.sorted) {
    return
  }

  await confirmYesNo({
    title: 'Are you sure you want to reprint the sort label?',
    yesButtonCallback: async () => {
      await f7.views.main.router.navigate(
        `/sorting/${props.pickBatchId}/print-documents/${loadCarrierReference.identifier}/`,
        {
          props: {
            confirmBarcode: false
          }
        }
      )
    }
  })
}

onMounted(async () => {
  captureScanInput.value = true
  await withAsyncLoading(async () => {
    pickBatchSortState.value = await getPickBatchSortState({ pickBatchId: props.pickBatchId })
  })

  const isProjectClosable = pickBatchSortState.value?.sortLoadCarriersSorted

  if (isProjectClosable) {
    actions.value.push({
      href: '#',
      name: 'Close project',
      requiresConfirmation: true,
      callback: async () => {
        await removeProject(props.pickBatchId)

        await toast.success(`Project #${props.pickBatchId} closed!`).open()

        await f7.views.main.router.navigate('/sorting/')
      }
    })
  }
})

onBeforeUnmount(() => {
  unsubscribe()
})
</script>
