import useProjectCount from '@composables/useProjectCount'
import useProjectInbound from '@composables/useProjectInbound'
import useProjectPick from '@composables/useProjectPick'
import useProjectRelocate from '@composables/useProjectRelocate'
import Collection from '@services/collection'
import { IProject } from '@store/modules/projects/types'
import { computed } from 'vue'
import useProjectSorting from '@composables/useProjectSorting'

/**
 * @TODO Only export projects, promote usage of specific composables
 */
export default () => {
  const { isActive: projectPickActive, itemCount: projectPickItemCount } = useProjectPick()
  const { isActive, itemCount } = useProjectRelocate()
  const { isActive: isCountActive, itemCount: countedCount } = useProjectCount()
  const { isActive: isInboundActive, itemCount: inboundCount } = useProjectInbound()
  const { isActive: isSortingActive, itemCount: sortingItemCount } = useProjectSorting()

  const projects = computed<IProject[]>((): IProject[] => {
    // TODO move logic to a dedicated service?
    const projectItems = new Collection<IProject>()
    const typeComparer = <T extends IProject>(a: T, b: T): boolean => a.type === b.type

    if (isActive.value) {
      const project: IProject = {
        type: 'relocate',
        title: 'Relocate',
        count: 1,
        link: '/projects/relocate/',
        itemCount: itemCount.value
      }

      if (!projectItems.contains(project, typeComparer)) {
        projectItems.add(project)
      }
    }

    if (isCountActive.value) {
      const project: IProject = {
        type: 'count',
        title: 'Count',
        count: 1,
        link: '/projects/count/',
        itemCount: countedCount.value
      }

      if (!projectItems.contains(project, typeComparer)) {
        projectItems.add(project)
      }
    }

    if (isInboundActive.value) {
      projectItems.add({
        type: 'inbound',
        title: 'Inbound',
        count: inboundCount.value,
        link: '/projects/inbound/',
        itemCount: inboundCount.value
      })
    }

    if (projectPickActive.value) {
      projectItems.add({
        type: 'pick',
        title: 'Pick',
        count: 1,
        link: '/projects/pick/',
        itemCount: projectPickItemCount.value
      })
    }

    if (isSortingActive.value) {
      projectItems.add({
        type: 'sorting',
        title: 'Sorting',
        count: 1,
        link: '/projects/sorting/',
        itemCount: sortingItemCount.value
      })
    }

    return projectItems.all()
  })

  const activeProjectCount = computed<number>((): number => {
    if (projects.value.length === 0) {
      return 0
    }

    return projects.value.map((p) => p.count).reduce((total, current) => total + current)
  })

  const hasActiveProjects = computed<boolean>(() => activeProjectCount.value > 0)

  return {
    projects,
    activeProjectCount,
    hasActiveProjects
  }
}
