<template>
  <f7-page>
    <navigation></navigation>
    <f7-block-title v-if="!location" class="margin-top" color="red">
      No location selected
    </f7-block-title>
    <f7-block-title class="margin-top" v-if="location"> Relocate</f7-block-title>
    <f7-card>
      <f7-card-content>
        <f7-list media-list>
          <f7-list-item header="Article">
            <template #title v-if="article">
              <div style="width: 100%">{{ article.name }} ({{ article.company.code }})</div>
            </template>
          </f7-list-item>
          <f7-list-item header="Barcode">
            <template #title>{{ article.primaryBarcode }}</template>
          </f7-list-item>
          <f7-list-item header="Location">
            <template #title>{{ location.name }}</template>
          </f7-list-item>
          <f7-list-item v-if="!moveAll" header="Quantity">
            <form-input-number :value="qty" @change="onQtyChange" :auto-focus="true" />
          </f7-list-item>
          <f7-list-item
            checkbox
            value="highPriorityLocation"
            title="High priority location"
            @change="toggleHighPriorityLocation"
            :checked="highPriorityLocation"
          />
          <f7-list-item
            checkbox
            value="moveAll"
            :title="`Relocate all (${relocatableQty})`"
            @change="toggleMoveAll"
            :checked="moveAll"
          />
        </f7-list>
      </f7-card-content>
    </f7-card>

    <f7-block>
      <f7-button
        :disabled="!relocatable || isLoading"
        large
        color="text-secondary"
        fill-md
        @click="relocate"
      >
        <f7-preloader v-if="isLoading" />
        <span v-else>Relocate</span>
      </f7-button>
    </f7-block>
  </f7-page>
</template>
<script lang="ts" setup>
import FormInputNumber from '@components/form/InputNumber.vue'
import Navigation from '@components/AppNavigation.vue'
import useAuthentication from '@composables/useAuthentication'
import useLoading from '@composables/useLoading'
import useProjectRelocate from '@composables/useProjectRelocate'
import { IArticle } from '@graphql/article/types'
import { ILocation } from '@graphql/location/types'
import { notification } from '@services/notification'
import { toast } from '@services/toast'
import { IRelocateItem } from '@store/modules/projects/relocate/types'
import { f7 } from 'framework7-vue'
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps<{
  itemIndex?: string | number
  article: IArticle
  location: ILocation
}>()

const { items, itemCount, relocateFromTransitStock } = useProjectRelocate()
const { isLoading } = useLoading()
const { currentUser } = useAuthentication()

const moveAll = ref<boolean>(false)
const qty = ref<number | string>('')
const highPriorityLocation = ref<boolean>(false)

const relocatable = computed<boolean>(() => qty.value > 0)
const itemMoveAll = computed<boolean>(() =>
  selectedItem.value ? selectedItem.value.moveAll : false
)

const selectedItem = computed<IRelocateItem>(() => items.value[Number(props.itemIndex)])
const relocatableQty: number = selectedItem.value.qty

watch(moveAll, (value) => {
  if (value) {
    qty.value = selectedItem.value.qty
  }
})

onMounted(() => {
  if (itemMoveAll.value) {
    moveAll.value = itemMoveAll.value
    qty.value = selectedItem.value.qty
  }
})

const toggleMoveAll = () => {
  moveAll.value = !moveAll.value
}

const onQtyChange = (value: number) => {
  qty.value = value
}

const relocate = async () => {
  if (qty.value <= 0 && !moveAll.value) {
    return
  }

  if (moveAll.value) {
    qty.value = selectedItem.value.qty
  }

  try {
    isLoading.value = true

    const relocateItem: IRelocateItem = {
      article: selectedItem.value.article,
      articleID: selectedItem.value.articleID,
      articleName: selectedItem.value.articleName,
      locationID: selectedItem.value.locationID,
      location: selectedItem.value.location,
      moveAll: moveAll.value,
      qty: Number(qty.value),
      addedBy: currentUser.value,
      addedOn: new Date(),
      highPriorityLocation: highPriorityLocation.value
    }

    const response = await relocateFromTransitStock({
      item: relocateItem,
      locationID: props.location.id
    })

    if (response.data) {
      const result = response.data.data.relocateFromTransitStock

      await notification
        .success({
          message: `Relocated: ${result.movedQty} x ${relocateItem.articleName} to location ${props.location.name}`
        })
        .open()
    }

    if (itemCount.value > 0) {
      await f7.views.main.router.navigate('/projects/relocate/', { reloadAll: true })
    } else {
      await f7.views.main.router.navigate('/home/', { reloadAll: true })
    }
  } catch (error: any) {
    await toast.error(`<strong>${error}</strong>`, 10000, true).open()

    throw error
  } finally {
    isLoading.value = false
  }
}

const toggleHighPriorityLocation = async () => {
  highPriorityLocation.value = !highPriorityLocation.value
}
</script>
