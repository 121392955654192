export class NfcReader {
  public static isNfcFeatureAvailable(): boolean {
    return 'NDEFReader' in window
  }

  public static async permissionGranted(
    onChangeCallback: (value: PermissionState) => any
  ): Promise<PermissionState> {
    const permissionStatus = await navigator.permissions.query({ name: 'nfc' as PermissionName })

    permissionStatus.onchange = () => onChangeCallback(permissionStatus.state)

    return permissionStatus.state
  }

  private reader: NDEFReader

  private abortController: AbortController = new AbortController()

  constructor() {
    this.reader = new NDEFReader()
  }

  public async start(): Promise<NDEFReader> {
    this.abortController = new AbortController()
    this.abortController.signal.onabort

    await this.reader.scan({ signal: this.abortController.signal })

    return this.reader
  }

  public stop(reason?: string): void {
    this.abortController.abort(reason)
  }
}
