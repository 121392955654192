import { TypeName } from '@graphql/search/types'
import { UnionTypeSearchResult } from '@store/modules/search/types'

export function isLocation(result: UnionTypeSearchResult) {
  return result.__typename === TypeName.Location
}

export function isArticle(result: UnionTypeSearchResult) {
  return result.__typename === TypeName.Article
}

export function isShipment(result: UnionTypeSearchResult) {
  return result.__typename === TypeName.Shipment
}
