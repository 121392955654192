import config from '@framework7/config'
import { f7 } from 'framework7-vue'
import { Dialog } from 'framework7/types'

export default () => {
  const numberInputDialog = (
    text: string,
    value: number,
    maxValue: number,
    confirmCallback?: (dialog: Dialog.Dialog, event: Event, value: number) => void,
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    cancelCallback?: (dialog: Dialog.Dialog, event: Event) => void
  ) => {
    f7.dialog
      .create({
        text: text,
        content: `<div class="dialog-input-field item-input margin-top"><div class="dialog-input"><div class="item-input-wrap"><input type="number" id="quantity-input" step="1" min="1" max="${maxValue}" value="${value}" required validate data-error-message="Please enter a valid number!"></div></div></div>`,
        buttons: [
          {
            text: cancelButtonText,
            close: true,
            keyCodes: config.dialog.keyboardActions ? [27] : undefined,
            onClick: (dialog, event) => {
              if (cancelCallback) {
                cancelCallback(dialog, event)
              }
            }
          },
          {
            text: confirmButtonText,
            close: false,
            bold: true,
            keyCodes: config.dialog.keyboardActions ? [13] : undefined,
            onClick: (dialog, event) => {
              const inputElement = dialog.$el.find('#quantity-input')
              const value = inputElement.val()

              //@ts-ignore-next-line
              if (!f7.input.validate(inputElement)) {
                inputElement.focus()
                return
              }

              dialog.close()

              if (confirmCallback) {
                confirmCallback(dialog, event, Number(value))
              }
            }
          }
        ]
      })
      .open()
  }

  return {
    numberInputDialog
  }
}
