<template>
  <f7-page :page-content="false">
    <navigation v-slot="{ searchId }">
      <search-bar
        :search-id="searchId"
        search-strategy="default-without-routing"
        :singular-result-callback="onResult"
        :plural-result-callback="onResults"
        :search-type="TypeName.PickBatch"
        @no-result="onNoResult"
        expandable
      />
    </navigation>
    <f7-page-content>
      <f7-card :class="{ 'skeleton-text': isLoading }">
        <f7-card-content>
          <icon-with-text
            :icon="['far', 'conveyor-belt-boxes']"
            title="Scan pick batch id to start"
            description="To start the sorting process, you are required to scan the pick batch barcode."
          />
        </f7-card-content>
      </f7-card>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import useLoading from '@composables/useLoading'
import IconWithText from '@components/IconWithText.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import useSearch from '@composables/useSearch'
import { TypeName } from '@graphql/search/types'
import { perceptibleToast } from '@services/perceptibleToast'
import useSort from '@composables/useSort'

const { isLoading } = useLoading()
const { onNoResult } = useSearch()
const { onResult } = useSort()

const onResults = async () => {
  await perceptibleToast.error(
    'Multiple results found for the scanned item, did you scan the pick batch barcode?'
  )
}
</script>
