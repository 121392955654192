<template>
  <f7-list media-list :class="{ 'skeleton-text': isLoading }">
    <ul>
      <component
        v-for="(searchResult, key) in items"
        :search-result="searchResult"
        :is="`${searchResult.__typename}SearchResult`"
        :key="key"
        @over-swipe="onClick(searchResult)"
      >
        <template #actions>
          <f7-swipeout-actions left>
            <f7-swipeout-button color="dimass" overswipe close>
              <f7-icon material="swipe-right" class="text-color-white" />
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </template>
      </component>
    </ul>
  </f7-list>
</template>
<script lang="ts" setup>
import { SearchStrategy } from '@services/search/search'
import { SearchResults, UnionTypeSearchResult } from '@store/modules/search/types'

withDefaults(
  defineProps<{
    items: SearchResults
    searchType?: string | null
    searchStrategy?: string
    isLoading?: boolean
  }>(),
  {
    items: () => [],
    searchType: null,
    searchStrategy: SearchStrategy.Default,
    isLoading: false
  }
)

const emit = defineEmits<{
  (e: 'result', result: UnionTypeSearchResult): void
}>()

const onClick = async (result: UnionTypeSearchResult) => {
  emit('result', result)
}
</script>
