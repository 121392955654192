import { MutationTree } from 'vuex'
import { ICountItem, IProjectCountState } from './types'

function isMatchingItem(result: ICountItem, item: ICountItem) {
  return result.articleID === item.articleID && result.locationID === item.locationID
}

const mutations: MutationTree<IProjectCountState> = {
  add(state, item: ICountItem): void {
    const index = state.items.findIndex((i) => isMatchingItem(i, item))

    if (index !== -1) {
      item.countedAt = new Date()

      state.items[index] = item
    } else {
      state.items.push(item)
    }
  },

  remove(state, item: ICountItem): void {
    const index = state.items.findIndex((i) => isMatchingItem(i, item))

    if (index !== -1) {
      state.items.splice(index, 1)
    }
  },

  update(state, item: ICountItem) {
    const index = state.items.findIndex((i) => isMatchingItem(i, item))

    if (index === -1 || state.items[index].qty === item.qty) {
      return
    }

    item.countedAt = new Date()

    state.items[index] = item
  },

  clear(state): void {
    state.items = []
  }
}

export default mutations
