import { PluralResultCallback, SingleResultCallback } from '@services/search/search'
import AbstractSearchStrategy from '@services/search/strategy/abstract-strategy'
import { SearchResults, UnionTypeSearchResult } from '@store/modules/search/types'

export default class PackingControlShipmentLineSearchStrategy extends AbstractSearchStrategy {
  public async handleSingularResult(
    result: UnionTypeSearchResult,
    callback?: SingleResultCallback
  ): Promise<void> {
    if (callback) {
      callback(result)
    }
  }

  public async handlePluralResult(
    results: SearchResults,
    callback?: PluralResultCallback
  ): Promise<void> {
    if (callback) {
      callback(results)
    }
  }
}
