import useProjectPick from '@composables/useProjectPick'
import { notification } from '@services/notification'
import { f7 } from 'framework7-vue'
import { Router } from 'framework7/types'
import { IPickBatch, StateEnum } from '@graphql/pick/types'
import { store } from '@store/store'
import { computed } from 'vue'
import { ID } from '@graphql/types'

export const redirect = async ({ resolve }: Router.RouteCallbackCtx) => {
  const { isComplete } = useProjectPick()

  f7.preloader.show('#d6d95f')

  //Store(state) method of composables cannot be used here
  const assignedPickBatch = computed<IPickBatch | undefined>(
    () => store.getters['projects/pick/assignedPickBatch']
  )
  const updateProject = async (payload: Partial<IPickBatch>) =>
    store.dispatch('projects/pick/updateProject', payload)
  const removeProject = async (pickBatchId: number) =>
    await store.dispatch('projects/pick/removeProject', pickBatchId)
  const getProcessablePickBatch = async (): Promise<Partial<IPickBatch> | null> =>
    await store.dispatch('projects/pick/getProcessablePickBatch')

  let processablePickBatch = null
  try {
    processablePickBatch = await getProcessablePickBatch()
    f7.preloader.hide()
  } catch (e) {
    f7.preloader.hide()
    throw e
  }

  if (assignedPickBatch.value && assignedPickBatch.value.id !== processablePickBatch?.id) {
    const pickBatchId: ID = assignedPickBatch.value.id

    await removeProject(assignedPickBatch.value.id)

    notification
      .info({
        message: `Pick batch #${pickBatchId} was completed elsewhere, automatically closed the task on your device.`,
        closeTime: 5000,
        title: 'Pick project closed'
      })
      .open()
  }

  if (!processablePickBatch) {
    resolve({ url: '/pick/' })

    return
  }

  if (assignedPickBatch.value) {
    await updateProject(processablePickBatch)

    if (isComplete(assignedPickBatch.value)) {
      return resolve('/pick/confirm-picked/')
    }

    if (assignedPickBatch.value.state === StateEnum.STARTED) {
      return resolve(`/pick/line/${assignedPickBatch.value.id}/`)
    }
  }

  resolve('/pick/', {
    props: {
      processablePickBatch
    }
  })
}
