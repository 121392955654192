import { f7 } from 'framework7-vue'
import { Router } from 'framework7/types'

export default () => {
  const getIdParamFromRoute = (
    item: any | null = null,
    router?: Router.Router
  ): number | string | null => {
    if (!router) {
      router = f7.views.main.router
    }

    if (!router) {
      return null
    }

    if ('id' in router.currentRoute.params && router.currentRoute.params.id) {
      return router.currentRoute.params.id
    }

    if (item === null) {
      return null
    }

    if ('id' in item) {
      return item.id
    }

    return null
  }

  return {
    getIdParamFromRoute
  }
}
