<template>
  <f7-list-item swipeout @swipeout:opened="onOverSwipe">
    <template #title>
      {{ searchResult.name }}
    </template>
    <template #media>
      <search-result-icon :icon="['far', 'map-marker-alt']" />
    </template>
    <template v-if="searchResult.locationProperties" #after-title>
      <location-height-badge :location-properties="searchResult.locationProperties" />
    </template>
    <slot name="actions"></slot>
  </f7-list-item>
</template>
<script lang="ts" setup>
import LocationHeightBadge from '@components/LocationHeightBadge.vue'
import SearchResultIcon from '@components/search/SearchResultIcon.vue'
import { ILocation } from '@graphql/location/types'

defineProps<{
  searchResult: Partial<ILocation>
}>()

const emit = defineEmits<{
  (e: 'over-swipe', result: Partial<ILocation>): void
}>()

const onOverSwipe = async (result: Partial<ILocation>) => emit('over-swipe', result)
</script>
