<template>
  <f7-page
    no-toolbar
    no-navbar
    no-swipeback
    login-screen
    class="scanner-login-screen"
    style="background-color: #ffffff !important"
  >
    <f7-login-screen-title> Register device</f7-login-screen-title>
    <f7-list style="background: white">
      <f7-list-item>
        <template #inner>
          <div style="margin: 0 auto">
            <icon-with-text
              :icon="['far', 'barcode-scan']"
              title=" "
              description="Scan registration QR code!"
              class="align-items-center"
            />
          </div>
        </template>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>
<script lang="ts" setup>
import useAuthentication from '@/composables/useAuthentication'
import { eventBus, IEvent } from '@/utilities/scanInput'
import IconWithText from '@components/IconWithText.vue'
import useDevice from '@composables/useDevice'
import useSettings from '@composables/useSettings'
import { f7 } from 'framework7-vue'
import { nextTick, onBeforeUnmount } from 'vue'
import { captureException } from '@sentry/vue'
import { perceptibleToast } from '@services/perceptibleToast'

const { registerDevice } = useDevice() //TODO: not authenticated atm.
const { setDeviceId, deviceId } = useSettings()
const { isAuthenticated, logout } = useAuthentication()

const handleState = () => {
  if (deviceId.value) {
    if (!isAuthenticated.value) {
      nextTick(() => f7.views.main.router.navigate('/login/', { reloadAll: true }))

      return
    }

    nextTick(() => f7.views.main.router.navigate('/home/', { reloadAll: true }))

    return
  }
}

handleState()

const unsubscribe = eventBus.on(
  'scanInput',
  async (event) => {
    await onScanInput(event)
  },
  true
)

const decodeBase64Value = (value: string) => {
  try {
    return atob(value)
  } catch (e: any) {
    return
  }
}

const onScanInput = async (event: IEvent) => {
  event.stopPropagation()

  const value = String(event.payload).trim()

  if (!value || value.length === 0) {
    await perceptibleToast.error('Scan not recognized!')
    return
  }

  let decodedValue = decodeBase64Value(value)
  if (!decodedValue) {
    await perceptibleToast.error('Invalid barcode scanned!')
    return
  }

  const [deviceIdValue, authTokenValue] = decodedValue.split(',', 2)

  if (
    !authTokenValue ||
    authTokenValue.length === 0 ||
    !deviceIdValue ||
    Number.isInteger(deviceIdValue)
  ) {
    await perceptibleToast.error('Invalid barcode scanned!')
    return
  }

  try {
    const result = await registerDevice({
      deviceId: deviceIdValue,
      authToken: authTokenValue
    })

    if (result) {
      await setDeviceId(deviceIdValue)

      await perceptibleToast.success('Device registered successfully!')

      await logout()
      return
    }

    await perceptibleToast.error('Registration of device failed!')
  } catch (e: any) {
    await perceptibleToast.error('Device registration failed due a technical error!')

    captureException(e)

    throw e
  }
}

onBeforeUnmount(() => {
  unsubscribe()
})
</script>
