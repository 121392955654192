import { ColorMap, LocationHeightScan, LocationProperties } from '@graphql/location/types'
import { IParser, ParserResult } from '@services/search/parser'
import { UnionTypeSearchResult } from '@store/modules/search/types'

export default class LocationHeightScanParser implements IParser {
  private _indicator = '##LHS##'

  parse(query: string): ParserResult {
    const queryParts = query.split(this._indicator, 2)
    const firstPart = queryParts[0]
    const lastPart = queryParts[1]
    const heights = lastPart.split('')

    const locations = heights.map((height): UnionTypeSearchResult => {
      height = height.toUpperCase()

      return new LocationHeightScan(
        firstPart + height,
        new LocationProperties(height, new ColorMap('#dddddd', '#000000'))
      )
    })

    return new ParserResult(query, 'LocationHeight', locations, false, true)
  }

  supports(query: string): boolean {
    return query.includes(this._indicator)
  }
}
