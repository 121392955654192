<template>
  <load-carrier-scan-to-print
    :pick-batch-id="pickBatchId"
    :verification-popup-opened="verificationPopupOpened"
    @print-documents="onPrintVerificationLabel"
    @confirm-barcode="onMarkCompleted"
    @verification-popup-closed="verificationPopupOpened = false"
  >
    <template #page-title> Finalize load carrier for pick batch #{{ pickBatchId }} </template>
    <template #page-content>
      <icon-with-text
        :icon="['far', 'print']"
        title="Scan a nearby label printer"
        description="Print the control label to finalize the picked items for this load carrier. And place the load carrier in the buffer-zone displayed on the printed label."
      />
    </template>
  </load-carrier-scan-to-print>
</template>
<script lang="ts" setup>
import useLoading from '@composables/useLoading'
import useProjectPick from '@composables/useProjectPick'
import { soundBoard } from '@services/sound'
import { f7 } from 'framework7-vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { DocumentKind, PrintContext } from '@graphql/document/types'
import useDocument from '@composables/useDocument'
import { TypeName } from '@graphql/search/types'
import { perceptibleNotification } from '@services/perceptibleNotification'
import LoadCarrierScanToPrint from '@components/LoadCarrierScanToPrint.vue'
import { ref } from 'vue'
import IconWithText from '@components/IconWithText.vue'

const { isLoading, withAsyncLoading } = useLoading()
const { assignedPickBatch, markPicked, removeProject } = useProjectPick()
const { printDocumentsFor } = useDocument()

const verificationPopupOpened = ref<boolean>(false)

const pickBatchId = assignedPickBatch.value.id

const onPrintVerificationLabel = async (printerCode: string) => {
  await withAsyncLoading(async () => {
    await printDocumentsFor(
      new PrintContext(
        assignedPickBatch.value.id,
        TypeName.PickBatch,
        DocumentKind.PICK_LABELS,
        printerCode
      )
    )
    await soundBoard.playSuccessSound()
    verificationPopupOpened.value = true
  })
}

const onMarkCompleted = async (verificationCode: string) => {
  await withAsyncLoading(async () => {
    try {
      const pickBatchId = assignedPickBatch.value.id
      const response = await markPicked({ pickBatchId, verificationCode })

      verificationPopupOpened.value = false

      if (response.pickCompleted) {
        await removeProject(pickBatchId)

        await perceptibleNotification.finishedTask(`Pick batch #"${pickBatchId}" completed!`)

        await f7.views.main.router.navigate('/home/', {
          clearPreviousHistory: true
        })

        return
      }

      await perceptibleToast.success(
        `Load carrier successfully finalized for pick batch #${pickBatchId}!`
      )

      await f7.views.main.router.navigate(`/pick/line/${pickBatchId}/`)
    } catch (e: any) {
      await perceptibleToast.error(e.message)
      isLoading.value = false
      throw e
    }
  })
}
</script>
