import { IPackState } from '@store/modules/pack/types'
import { IRootState } from '@store/types'
import { ActionTree } from 'vuex'
import {
  IBulkMarkPrePackedRequest,
  IGetPackableItemsByLoadCarrierIdRequest,
  IGetPackingListByContextRequest,
  IMarkPackedRequest,
  IPrePackColloRequest
} from '@graphql/pack/types'
import packApi from '@graphql/pack'

const actions: ActionTree<IPackState, IRootState> = {
  async prePackCollo(context, request: IPrePackColloRequest) {
    const result = await packApi.prePackCollo(request)

    return result.data.data.prePackCollo
  },
  async getPackableItemsByLoadCarrierId(context, request: IGetPackableItemsByLoadCarrierIdRequest) {
    request.perPage = 100

    const response = await packApi.getPackableItemsByLoadCarrierId(request)

    return response.data.data.getPackableItemsByLoadCarrierId
  },
  async getPackingListByContext(context, request: IGetPackingListByContextRequest) {
    const response = await packApi.getPackingListByContext(request)

    return response.data.data.getPackingListByContext
  },
  async markPacked(context, request: IMarkPackedRequest) {
    const response = await packApi.markPacked(request)

    return response.data.data.markPacked
  },
  async bulkMarkPrePacked(context, request: IBulkMarkPrePackedRequest) {
    const response = await packApi.bulkMarkPrePacked(request)

    return response.data.data.bulkMarkPrePacked
  }
}

export default actions
