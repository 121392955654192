<template>
  <f7-page :page-content="false" class="count">
    <navigation>
      <f7-subnavbar class="no-padding">
        <search-bar search-strategy="count" @result="onResult" @no-result="onNoResult" />
      </f7-subnavbar>
    </navigation>

    <f7-page-content>
      <f7-block-title class="margin-top"> Counted items </f7-block-title>
      <f7-card v-if="!isActive">
        <f7-card-content>
          <icon-with-text
            :icon="['far', 'clipboard-check']"
            title="No items left in count project"
            description="Scan a new product if you want to ad new items to this count project"
          />
        </f7-card-content>
      </f7-card>
      <f7-list media-list v-if="isActive">
        <f7-list-item
          v-for="item in items"
          :key="itemKey(item)"
          class="count-item"
          swipeout
          @swipeout:delete="onDelete(item)"
        >
          <template #subtitle>
            {{ item.article.name }}
          </template>
          <template #text>
            <form-input-number :value="item.qty" @change="onQtyChange($event, item)" />
          </template>
          <template #header>
            <div class="float-right">
              <location-chip :text="item.location.name" />
            </div>
          </template>
          <template #title>
            ({{ item.article.company.code }}) {{ item.article.primaryBarcode }}
          </template>
          <f7-swipeout-actions right>
            <f7-swipeout-button
              color="red"
              overswipe
              class="text-white"
              delete
              confirm-text="Are you sure you want to remove this item?"
            >
              <f7-icon material="delete_forever" />
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list>
    </f7-page-content>

    <template #fixed v-if="isActive">
      <f7-fab position="center-bottom" v-if="isLoading">
        <f7-preloader />
      </f7-fab>
      <f7-fab position="center-bottom" @click.prevent="onProjectCountConfirm">
        <f7-icon material="done_all" />
      </f7-fab>
    </template>
  </f7-page>
</template>
<script lang="ts" setup>
import { lcFirst } from '@/utilities/string'
import FormInputNumber from '@components/form/InputNumber.vue'
import IconWithText from '@components/IconWithText.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import useLoading from '@composables/useLoading'
import useProjectCount from '@composables/useProjectCount'
import useSearch from '@composables/useSearch'
import { ISearchResult } from '@graphql/search/types'
import { notification } from '@services/notification'
import { toast } from '@services/toast'
import { ICountItem } from '@store/modules/projects/count/types'
import { store } from '@store/store'
import { f7 } from 'framework7-vue'
import LocationChip from '@components/chip/LocationChip.vue'
import { captureException } from '@sentry/vue'

const { isActive, items, update, confirm, clear, remove } = useProjectCount()
const { withAsyncLoading, isLoading } = useLoading()
const { onNoResult } = useSearch()

const onQtyChange = async (data: any, item: ICountItem) => {
  const updatedItem = Object.assign({}, item)
  updatedItem.qty = Number(data)
  await update(updatedItem)
}

const onProjectCountConfirm = async () => {
  await withAsyncLoading(async () => {
    try {
      await confirm()

      notification.success({ message: 'Article counts confirmed!' }).open()
      await clear()

      f7.views.main.router.navigate('/home/', { reloadAll: true })
    } catch (e) {
      captureException(e)
      toast.error('Something went wrong while submitting article counts!').open()
    } finally {
      isLoading.value = false
    }
  })
}

const itemKey = (item: ICountItem) => `${item.articleID}-${item.locationID}`

const onResult = (result: ISearchResult) => {
  //TODO replace with concrete store action
  store.commit(`${lcFirst(result.__typename)}/setActive${result.__typename}`, result)
}

const onDelete = async (item: ICountItem) => {
  await withAsyncLoading(async () => remove(item))
}
</script>
<style scoped lang="less">
.card p {
  margin: 0;
}

.scan-input {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  position: absolute;
}
</style>
<style lang="less">
.page.count .count-item .item-media {
  align-self: flex-start;
  margin-top: 5px;
}

.page.count .count-item .item-input-wrap input {
  text-align: center;
}
</style>
