import { Module } from 'vuex'
import { IRootState } from '../../types'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { IStockState } from './types'

const state: IStockState = {}

const stock: Module<IStockState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default stock
