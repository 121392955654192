<template>
  <f7-list-item swipeout @swipeout:opened="onOverSwipe">
    <template #header>
      <div class="float-right" v-if="topLocations.length > 0">
        <location-chip :text="topLocationsString" />
      </div>
    </template>
    <template #media>
      <search-result-icon :icon="['far', 'barcode']" />
    </template>
    <template #title>
      <string-label
        :class="{ 'inactive-article': !isActive }"
        :value="`(${company?.code}) ${searchResult.primaryBarcode}`"
      />
    </template>
    <template #subtitle>
      {{ searchResult.searchPreview }}
    </template>
    <slot name="actions" />
  </f7-list-item>
</template>

<script lang="ts" setup>
import { IArticle } from '@graphql/article/types'
import { computed } from 'vue'
import LocationChip from '@components/chip/LocationChip.vue'
import SearchResultIcon from '@components/search/SearchResultIcon.vue'
import { IArticleLocation } from '@graphql/location/types'
import StringLabel from '@components/label/StringLabel.vue'

const props = defineProps<{
  searchResult: Partial<IArticle>
}>()

const emit = defineEmits<{
  (e: 'over-swipe', result: Partial<IArticle>): void
}>()

const articleLocations = computed<IArticleLocation[]>(() => {
  if (props.searchResult && props.searchResult.articleLocation) {
    return props.searchResult.articleLocation
  }

  return []
})

const topLocations = computed<IArticleLocation[]>(() => articleLocations.value.slice(0, 1))

const topLocationsString = computed<string>(() => {
  if (topLocations.value.length === 0) {
    return ''
  }

  const locations = topLocations.value.map((al) => al.location.name).join('')

  if (articleLocations.value.length > 1) {
    return `${locations}, +${articleLocations.value.length - 1}`
  }

  return locations
})

const company = computed(() => props.searchResult.company)
const isActive = computed(() => (props.searchResult.active ? props.searchResult.active : false))
const onOverSwipe = async (result: Partial<IArticle>) => emit('over-swipe', result)
</script>
<style scoped lang="less">
.inactive-article {
  text-decoration: line-through;
}
</style>
