import { AxiosInstance } from 'axios'
import Base from './base'
import GRAPHQL_API_CLIENT from './client'
import {
  IPrintSubjectDocumentsRequest,
  IPrintSubjectDocumentsResponse
} from '@graphql/document/types'

class Document extends Base {
  constructor(protected axios: AxiosInstance) {
    super(axios, 'document')
  }

  public async printSubjectDocuments(request: IPrintSubjectDocumentsRequest) {
    return await this.makeRequest<IPrintSubjectDocumentsResponse>(
      'printSubjectDocumentsQuery',
      request
    )
  }
}

export default new Document(GRAPHQL_API_CLIENT)
