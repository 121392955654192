<template>
  <f7-sheet
    swipe-to-close
    backdrop
    :opened="opened"
    class="location-suggestion-popup"
    @sheet:closed="close"
    style="height: 85vh; --f7-sheet-bg-color: #fff"
  >
    <location-suggestion
      v-if="opened"
      :company-id="companyId"
      :warehouse-id="warehouseId"
      :items="items"
      @close="close"
      @selected-item="onSelectedItem"
    />
  </f7-sheet>
</template>
<script lang="ts" setup>
import { ILocation } from '@graphql/location/types'
import LocationSuggestion from '@components/LocationSuggestion.vue'
import { ID } from '@graphql/types'

const props = withDefaults(
  defineProps<{
    opened: boolean
    companyId?: ID
    warehouseId?: ID
    items?: ILocation[]
  }>(),
  {
    opened: false,
    companyId: undefined,
    warehouseId: undefined,
    items: () => []
  }
)

const close = () => {
  emit('close', true)
}

const emit = defineEmits<{
  (e: 'close', payload: boolean),
  (e: 'onSelectedItem', payload: ILocation),
}>()

const onSelectedItem = (location: ILocation) => {
  emit('onSelectedItem', location)
}
</script>
