<template>
  <f7-page :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar :search-id="searchId" expandable />
    </navigation>

    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#load-carrier-detail" tab-link-active> Detail </f7-link>
      <f7-link tab-link="#load-carrier-items"> Items </f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="load-carrier-detail" class="page-content" tab-active>
        <load-carrier-detail-tab-content
          v-if="loadCarrier"
          :load-carrier="loadCarrier"
          :is-loading="isLoading"
        />
      </f7-tab>
      <f7-tab id="load-carrier-items" class="page-content">
        <load-carrier-detail-tab-items
          v-if="loadCarrier"
          :load-carrier="loadCarrier"
          :is-loading="isLoading"
        />
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>
<script lang="ts" setup>
import SearchBar from '@components/search/SearchBar.vue'
import Navigation from '@components/AppNavigation.vue'
import LoadCarrierDetailTabContent from '@components/pick/LoadCarrierDetailTabContent.vue'
import LoadCarrierDetailTabItems from '@components/pick/LoadCarrierDetailTabItems.vue'
import useLoading from '@composables/useLoading'
import { onMounted, ref } from 'vue'
import useLoadCarrier from '@composables/useLoadCarrier'
import { ILoadCarrier } from '@graphql/pick/types'
import { ID } from '@graphql/types'

const props = defineProps<{
  loadCarrierId: ID
}>()

const { isLoading, withAsyncLoading } = useLoading()
const { getLoadCarrierById } = useLoadCarrier()
const loadCarrier = ref<ILoadCarrier | null>(null)

onMounted(async () => {
  await withAsyncLoading(async () => {
    loadCarrier.value = await getLoadCarrierById({ id: props.loadCarrierId })
  })
})
</script>
