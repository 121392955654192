import { IUser } from '@store/modules/auth/types'

export interface IVoter {
  vote(user: IUser, attributes: string[], subject?: unknown): VoterResult

  supports(attributes: string[], subject?: unknown): boolean
}

export enum VoterResult {
  ACCESS_GRANTED = 1,
  ACCESS_ABSTAIN = 0,
  ACCESS_DENIED = -1
}
