import { IShipment, StateEnum } from '@graphql/shipment/types'

export const isPackingControllable = (shipment: IShipment): boolean => {
  if (!shipment.state) {
    return false
  }
  return (
    shipment.partner.packingControl &&
    shipment.state >= StateEnum.PROCESSING &&
    shipment.state < StateEnum.PACKING_CONTROL &&
    !isPackingControlled(shipment)
  )
}

export const isPackingControlled = (shipment: IShipment): boolean =>
  shipment.packingControlledAt !== null
