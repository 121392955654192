<template>
  <f7-list-item swipeout>
    <f7-swipeout-actions left>
      <f7-swipeout-button
        color="primary"
        v-if="calculateQuantityToPick(lineItem) > 1"
        close
        @click="$emit('quantity-enter', lineItem)"
      >
        <font-awesome-icon :icon="['far', 'input-numeric']" />
      </f7-swipeout-button>
      <f7-swipeout-button
        @click="onClickShowArticleDetails(lineItem)"
        :overswipe="calculateQuantityToPick(lineItem) <= 1"
        close
      >
        <font-awesome-icon :icon="['far', 'hand-holding-box']" />
      </f7-swipeout-button>
    </f7-swipeout-actions>
    <template #media> {{ quantityFulFilled(lineItem) }} / {{ lineItem?.quantityTodo }}</template>
    <template #subtitle>
      {{ lineItem.article.name }}
    </template>
    <template #title>
      {{ lineItem.article.primaryBarcode ?? '#NOBARCODE' }}
    </template>
    <template #after-title>
      <div class="float-right">
        <font-awesome-icon
          :class="[
            isQuantityFulfilled(lineItem) && !hasQuantityMissed(lineItem)
              ? 'text-color-green'
              : 'color-text-gray-light'
          ]"
          :icon="['far', 'check']"
        />
        <font-awesome-icon v-if="hasQuantityMissed(lineItem)" :icon="['far', 'exclamation']" />
      </div>
    </template>
    <f7-swipeout-actions right>
      <f7-swipeout-button color="primary" overswipe close @click="onQuantityMissed(lineItem)">
        <font-awesome-icon :icon="['far', 'map-marker-slash']" />
      </f7-swipeout-button>
    </f7-swipeout-actions>
  </f7-list-item>
</template>
<script lang="ts" setup>
import useArticle from '@composables/useArticle'
import useLoading from '@composables/useLoading'
import usePickBatchLine from '@composables/usePickBatchLine'
import { ILineItem } from '@graphql/pick/types'

defineProps<{
  lineItem: ILineItem
}>()

const emits = defineEmits<{
  (e: 'article-popup-open', payload: boolean)
  (e: 'quantity-entered', payload: unknown)
  (e: 'quantity-enter', payload: ILineItem)
}>()

const { getArticle } = useArticle()
const { withAsyncLoading } = useLoading()
const {
  quantityFulFilled,
  isQuantityFulfilled,
  hasQuantityMissed,
  onQuantityMissed,
  calculateQuantityToPick
} = usePickBatchLine()

const onClickShowArticleDetails = async (lineItem: ILineItem) => {
  await withAsyncLoading(async () => {
    await emits('article-popup-open', true)
    await getArticle({ id: lineItem.article.id })
  })
}
</script>
