import { APPLICATION_NAME } from '@config/environment'
import routes from './routes'

export default {
  routes: routes,
  name: APPLICATION_NAME,
  id: 'nl.dimass.scanner',
  theme: 'md',
  browserHistory: true,
  browserHistorySeparator: '',
  swipeout: {
    removeElements: false
  },
  dialog: {
    title: APPLICATION_NAME,
    keyboardActions: true,
    autofocus: true
  }
}
