import UserPanelPage from '@/views/MenuView.vue'
import authRoutes from '@framework7/routes/auth'
import deviceRoutes from '@framework7/routes/device'
import packRoutes from '@framework7/routes/pack'
import pickRoutes from '@framework7/routes/pick'
import sortingRoutes from '@framework7/routes/sorting'
import projectsRoutes from '@framework7/routes/projects'
import AppSettings from '@pages/AppSettings.vue'
import ArticleDetailPage from '@pages/article/DetailPage.vue'
import ArticleStockPage from '@pages/article/StockPage.vue'
import CountPage from '@pages/count/ArticleCountPage.vue'
import ColloDetailPage from '@pages/shipment/ColloDetailPage.vue'
import GoodsEntryArticleBatchPage from '@pages/goods-entry/EnterArticleBatchPage.vue'
import GoodsEntryItemPage from '@pages/goods-entry/EnterQuantityPage.vue'
import GoodsEntryLocationSearchPage from '@pages/goods-entry/LocationSearchPage.vue'
import GoodsEntryLocationPage from '@pages/goods-entry/EnterLocationPage.vue'
import GoodsEntryWeightPage from '@pages/goods-entry/EnterWeightPage.vue'
import GoodsEntryPage from '@pages/goods-entry/ListPage.vue'
import GoodsReceiptPage from '@pages/goods-receipt/DetailPage.vue'
import OpenGoodsReceiptsPage from '@pages/goods-receipt/OpenListPage.vue'
import HomePage from '@pages/HomePage.vue'
import LocationPage from '@pages/location/DetailPage.vue'
import PackingControlPage from '@pages/shipment/PackingControlSearchPage.vue'
import PickBatchDetailPage from '@pages/pick-batch/DetailPage.vue'
import PurchaseOrderPage from '@pages/purchase-order/DetailPage.vue'
import RelocatePage from '@pages/stock/RelocatePage.vue'
import RemovePage from '@pages/stock/RemovePage.vue'
import ScanInputPage from '@pages/ScanInputPage.vue'
import SearchPage from '@pages/SearchResultPage.vue'
import ShipmentPage from '@pages/shipment/DetailPage.vue'
import ShipmentPackingControlPage from '@pages/shipment/PackingControlPage.vue'
import { Router } from 'framework7/types'

const routes: Partial<Router.RouteParameters>[] = [
  {
    path: '/home',
    alias: '/',
    component: HomePage,
    options: {
      props: {
        q: ''
      }
    }
  },
  {
    path: '/article/:id?/',
    component: ArticleDetailPage,
    options: {
      props: {
        item: undefined
      }
    },
    routes: [
      {
        path: '/stock/',
        component: ArticleStockPage,
        options: {
          props: {
            item: undefined
          }
        }
      }
    ]
  },
  {
    path: '/location/:id?/',
    component: LocationPage,
    options: {
      props: {
        item: undefined
      }
    }
  },
  {
    path: '/shipment/:id/',
    component: ShipmentPage,
    routes: [
      {
        path: 'packing-control/',
        component: ShipmentPackingControlPage
      }
    ]
  },
  {
    path: '/collo/:id/',
    component: ColloDetailPage
  },
  {
    path: '/packing-control/',
    component: PackingControlPage
  },
  {
    path: '/scan-input/',
    component: ScanInputPage,
    options: {
      props: {
        itemsCallback: undefined,
        items: undefined,
        restricted: false,
        searchType: undefined,
        searchStrategy: undefined,
        successUrl: undefined
      }
    }
  },
  {
    path: '/search/',
    component: SearchPage,
    options: {
      props: {
        result: undefined,
        query: ''
      }
    }
  },
  {
    path: '/count/',
    component: CountPage
  },
  {
    path: '/remove/',
    component: RemovePage
  },
  {
    name: 'relocate',
    path: '/relocate/:itemIndex/',
    component: RelocatePage,
    options: {
      props: {
        location: undefined,
        article: undefined
      }
    }
  },
  {
    path: '/panel-user/',
    component: UserPanelPage
  },
  {
    path: '/purchaseorder/:id?/',
    component: PurchaseOrderPage,
    options: {
      props: {
        item: undefined
      }
    }
  },
  {
    path: '/goodsreceipt/',
    component: OpenGoodsReceiptsPage,
    routes: [
      {
        path: ':id/',
        component: GoodsReceiptPage,
        options: {
          props: {
            item: undefined
          }
        }
      }
    ]
  },
  {
    path: '/goodsentry/:id/',
    component: GoodsEntryPage
  },
  {
    path: '/goods-entry-item/:id?/',
    component: GoodsEntryItemPage,
    options: {
      props: {
        item: undefined,
        companyId: undefined,
        warehouseId: undefined
      }
    }
  },
  {
    path: '/goods-entry-weight/:id?/',
    component: GoodsEntryWeightPage,
    options: {
      props: {
        item: undefined
      }
    }
  },
  {
    path: '/goods-entry-location/:id?/',
    component: GoodsEntryLocationPage,
    options: {
      props: {
        item: undefined
      }
    }
  },
  {
    path: '/goods-entry-article-batch/:id?/',
    component: GoodsEntryArticleBatchPage,
    options: {
      props: {
        item: undefined
      }
    }
  },
  {
    path: '/goods-entry-location-search/',
    component: GoodsEntryLocationSearchPage,
    options: {
      props: {
        itemsCallback: undefined,
        items: undefined,
        restricted: false,
        searchType: undefined,
        searchStrategy: undefined,
        successUrl: undefined
      }
    }
  },
  {
    path: '/pickbatch/:id/',
    component: PickBatchDetailPage
  },
  {
    path: '/settings/',
    component: AppSettings
  },
  ...projectsRoutes,
  ...pickRoutes,
  ...authRoutes,
  ...packRoutes,
  ...deviceRoutes,
  ...sortingRoutes
]

export default routes
