import { IStockLevel } from '@graphql/article/types'
import { ILocation, LocationTypeEnum } from '@graphql/location/types'

export function getPhysicalLocations(locations: ILocation[]): ILocation[] {
  if (locations.length === 0) {
    return locations
  }

  const virtualTypes = [
    LocationTypeEnum.ADMINISTRATIVE.valueOf(),
    LocationTypeEnum.VIRTUAL.valueOf()
  ]

  return locations.filter((l) => !virtualTypes.includes(l.locationType.code))
}

export function getPhysicalTotal(stockLevel: IStockLevel): number {
  return stockLevel.physicalTotal + stockLevel.receiving
}
