import actions from '@store/modules/purchase-order/actions'
import getters from '@store/modules/purchase-order/getters'
import mutations from '@store/modules/purchase-order/mutations'
import { IPurchaseOrderState } from '@store/modules/purchase-order/types'
import { IRootState } from '@store/types'
import { Module } from 'vuex'

const namespaced = true

const state: IPurchaseOrderState = {
  activePurchaseOrder: undefined,
  openPurchaseOrders: []
}

const purchaseOrder: Module<IPurchaseOrderState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}

export default purchaseOrder
