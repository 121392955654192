import { IPurchaseOrder } from '@graphql/purchaseOrder/types'
import { IPurchaseOrderState } from '@store/modules/purchase-order/types'
import { MutationTree } from 'vuex'

const mutations: MutationTree<IPurchaseOrderState> = {
  setActivePurchaseOrder(state, payload: IPurchaseOrder) {
    state.activePurchaseOrder = payload
  },

  setOpenPurchaseOrders(state, payload: IPurchaseOrder[]) {
    state.openPurchaseOrders = payload
  }
}

export default mutations
