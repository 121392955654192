import { f7 } from 'framework7-vue'

export default class MaterialToast {
  public default(message: string, closeTimeout: number | undefined = 3000, closeButton = true) {
    return f7.toast.create({
      closeTimeout,
      closeButton,
      text: message
    })
  }
  public info(message: string, closeTimeout: number | undefined = 3000, closeButton = true) {
    return f7.toast.create({
      closeTimeout,
      closeButton,
      text: message,
      cssClass: 'bg-color-lightblue'
    })
  }

  public warning(message: string, closeTimeout: number | undefined = 3000, closeButton = true) {
    return f7.toast.create({
      closeTimeout,
      closeButton,
      text: message,
      cssClass: 'bg-color-orange'
    })
  }

  public success(message: string, closeTimeout: number | undefined = 3000, closeButton = true) {
    return f7.toast.create({
      closeTimeout,
      closeButton,
      cssClass: 'bg-color-green',
      text: message
    })
  }

  public error(message: string, closeTimeout: number | undefined = 5000, closeButton = true) {
    return f7.toast.create({
      text: message,
      closeTimeout,
      cssClass: 'bg-color-red',
      closeButton,
      closeButtonColor: 'text-color-black'
    })
  }
}

export const toast = new MaterialToast()
