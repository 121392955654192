<template>
  <f7-list-item swipeout @swipeout:opened="onOverSwipe">
    <template #title>PickBatch {{ searchResult.searchPreview }}</template>
    <template #media>
      <search-result-icon :icon="['far', 'person-carry']"></search-result-icon>
    </template>
    <template #subtitle>
      {{ searchResult.processedLines ?? 0 }} / {{ searchResult.lineCount }}<br />
      <date-time-label :value="searchResult.createdAt" />
      <br />
    </template>
    <slot name="actions"></slot>
  </f7-list-item>
</template>
<script lang="ts" setup>
import DateTimeLabel from '@components/label/DateTime.vue'
import { IPickBatch } from '@graphql/pick/types'
import SearchResultIcon from '@components/search/SearchResultIcon.vue'

defineProps<{
  searchResult: Partial<IPickBatch>
}>()

const emit = defineEmits<{
  (e: 'over-swipe', result: Partial<IPickBatch>): void
}>()

const onOverSwipe = async (result: Partial<IPickBatch>) => emit('over-swipe', result)
</script>
