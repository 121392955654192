import accessDecisionManger from '@services/security/accessDecisionManger'
import { IUser, IUsernamePasswordLoginContext } from '@store/modules/auth/types'
import { useStore } from '@store/store'
import { computed } from 'vue'
import { IAuthenticationResult, IAuthenticatorAuthenticationRequest } from '@graphql/auth/types'
import { NfcReader } from '@services/nfc'

export default function () {
  const store = useStore()

  const currentUser = computed<IUser>(() => store.getters['auth/user'])
  const authToken = computed<string | null>(() => store.getters['auth/token'])
  const isAuthenticated = computed<boolean>(() => store.getters['auth/isAuthenticated'])
  const tokenLifetime = computed<number | undefined>(() => store.getters['auth/tokenLifetime'])
  const tokenLogin = async (payload: string): Promise<IAuthenticationResult | null> =>
    store.dispatch('auth/tokenLogin', payload)

  const authenticatorLogin = async (
    request: IAuthenticatorAuthenticationRequest
  ): Promise<IAuthenticationResult | null> => store.dispatch('auth/authenticatorLogin', request)

  const usernamePasswordLogin = async (
    payload: IUsernamePasswordLoginContext
  ): Promise<IAuthenticationResult | null> => store.dispatch('auth/login', payload)

  const isGrantedAsync = async (
    attributes: string | string[],
    object?: unknown
  ): Promise<boolean> => {
    return isGranted(attributes, object)
  }

  const isGranted = (attributes: string | string[], object?: unknown): boolean => {
    if (!currentUser.value) {
      return false
    }

    return accessDecisionManger.vote(currentUser.value, attributes, object)
  }

  const logout = async () => {
    await store.dispatch('auth/logout')
  }

  async function enableNfcReader() {
    const reader = new NfcReader()

    try {
      await reader.start()

      await reader.stop('success')
    } catch (e: any) {
      await reader.stop('Error: ' + e.message)
    }
  }

  return {
    currentUser,
    authToken,
    isAuthenticated,
    tokenLifetime,
    logout,
    tokenLogin,
    isGrantedAsync,
    isGranted,
    usernamePasswordLogin,
    enableNfcReader,
    authenticatorLogin
  }
}
