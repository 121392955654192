import { IParser, ParserResult } from '@services/search/parser'
import scanner from '@config/scanner'

export default class NfcTokenParser implements IParser {
  private _indicator = scanner.authTriggerKeys

  parse(query: string): ParserResult {
    query = query.substring(1)

    return new ParserResult(query, 'NfcToken', [], false, true)
  }

  supports(query: string): boolean {
    for (let i = 0; i < this._indicator.length; i++) {
      if (query.startsWith(this._indicator[i])) {
        return true
      }
    }
    return false
  }
}
