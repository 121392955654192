import { IProjectInboundState } from '@store/modules/projects/inbound/types'
import { IRootState } from '@store/types'
import { GetterTree } from 'vuex'

const getters: GetterTree<IProjectInboundState, IRootState> = {
  isActive: (state) => state.items.length > 0,
  itemCount: (state) => state.items.length,
  count: (state) => state.items.length,
  items: (state) => state.items,
  goodsEntries: (state) => state.goodsEntries,
  activeGoodsEntry: (state) => state.activeGoodsEntry
}

export default getters
