<template>
  <f7-page>
    <navigation />
    <f7-block-title class="margin-top"> Active projects ({{ activeProjectCount }}) </f7-block-title>

    <f7-card v-if="!hasActiveProjects">
      <f7-card-content>
        <icon-with-text
          :icon="['far', 'clipboard-check']"
          title="Hoooraay, no pending tasks!"
          description="Projects are automatically created when you start specific processes like a relocate or counts."
        />
      </f7-card-content>
    </f7-card>

    <f7-list v-show="hasActiveProjects">
      <f7-list-item v-for="(project, key) in projects" :key="key" :link="project.link">
        <template #title>{{ project.title }}</template>
        <template #footer>{{ project.itemCount }} item(s) awaiting to be processed</template>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>
<script lang="ts" setup>
import IconWithText from '@components/IconWithText.vue'
import Navigation from '@components/AppNavigation.vue'
import { nextTick, onBeforeMount } from 'vue'
import { f7 } from 'framework7-vue'
import useProjects from '@composables/useProjects'

const { projects, hasActiveProjects, activeProjectCount } = useProjects()

onBeforeMount(async () => {
  if (projects.value.length === 1) {
    const project = projects.value[0]
    const projectUrl = `/projects/${project.type.toLowerCase()}/`

    await nextTick()

    f7.views.main.router.navigate(projectUrl)
  }
})
</script>
