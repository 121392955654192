<template>
  <f7-page :page-content="false">
    <f7-navbar title="">
      <f7-link popup-close>
        <f7-icon material="close" />
      </f7-link>
    </f7-navbar>
    <f7-page-content v-if="currentStockLevel">
      <f7-block-header class="margin-top">
        {{ currentArticle.code }} / {{ currentLocation.name }}
      </f7-block-header>
      <f7-card class="data-table">
        <table>
          <thead>
            <tr>
              <th class="label-cell">Total</th>
              <th class="numeric-cell">{{ physicalTotal }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="label-cell">Free</td>
              <td class="numeric-cell">{{ currentStockLevel.free }}</td>
            </tr>
            <tr>
              <td class="label-cell">Receiving</td>
              <td class="numeric-cell">{{ currentStockLevel.receiving }}</td>
            </tr>
            <tr>
              <td class="label-cell">Packing</td>
              <td class="numeric-cell">{{ currentStockLevel.packing }}</td>
            </tr>
            <tr>
              <td class="label-cell">Defect</td>
              <td class="numeric-cell">{{ currentStockLevel.defect }}</td>
            </tr>
            <tr>
              <td class="label-cell">Return</td>
              <td class="numeric-cell">{{ currentStockLevel.return }}</td>
            </tr>
            <tr>
              <td class="label-cell">Transit</td>
              <td class="numeric-cell">{{ currentStockLevel.transit }}</td>
            </tr>
          </tbody>
        </table>
      </f7-card>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import { IArticle, IStockLevel } from '@graphql/article/types'
import { ILocation } from '@graphql/location/types'
import { getPhysicalTotal } from '@store/modules/article/helper'
import { computed } from 'vue'

const props = defineProps<{
  stockLevel: Partial<IStockLevel>
  article: IArticle
  location: ILocation
}>()

const currentStockLevel = computed<Partial<IStockLevel>>(() => props.stockLevel)
const currentLocation = computed<ILocation>(() => props.location)
const currentArticle = computed<IArticle>(() => props.article)
const physicalTotal = computed<number>(() => {
  if (props.stockLevel) {
    return getPhysicalTotal(props.stockLevel as IStockLevel)
  }
  return 0
})
</script>
