import { confirmYesNo } from '@/functions/dialog'
import { isAbortedRequest, isRetryableNetworkError } from '@graphql/client'
import * as Sentry from '@sentry/vue'
import { f7 } from 'framework7-vue'
import { ref } from 'vue'

type Callback = () => void

export interface IWithTextPreloaderAndNetworkErrorHandlingOptions {
  title: string
  callback: Callback
  cancelCallback?: Callback
  timeoutText?: string
  timeout?: number
  retries?: number
  maxRetries?: number
  autoRetries?: number
  maxAutoRetries?: number
}

export default function () {
  const isLoading = ref<boolean>(false)

  const toggleLoading = () => {
    isLoading.value = !isLoading.value
  }

  const withAsyncLoading = async (callback: Callback): Promise<void> => {
    try {
      isLoading.value = true

      await callback()

      isLoading.value = false
    } catch (e) {
      isLoading.value = false
      throw e
    }
  }

  const withLoading = (callback: Callback): void => {
    try {
      isLoading.value = true
      callback()
    } finally {
      isLoading.value = false
    }
  }

  const withPreloader = (callback: Callback): void => {
    try {
      f7.preloader.show('#d6d95f')

      callback()

      f7.preloader.hide()
    } catch (e) {
      f7.preloader.hide()
      throw e
    }
  }

  const withPreloaderAsync = async (callback: Callback) => {
    try {
      f7.preloader.show('#d6d95f')

      await callback()

      f7.preloader.hide()
    } catch (e) {
      f7.preloader.hide()
      throw e
    }
  }

  const withTextPreloaderAsync = async (title: string, callback: Callback) => {
    try {
      f7.dialog.preloader(title)

      await callback()

      f7.dialog.close()
    } catch (e) {
      f7.dialog.close()
      throw e
    }
  }

  const withTextPreloaderAndNetworkErrorHandlingAsync = async (
    options: IWithTextPreloaderAndNetworkErrorHandlingOptions
  ) => {
    if (!options.retries) {
      options.retries = 0
    }

    if (!options.maxRetries) {
      options.maxRetries = 0
    }

    if (!options.timeout) {
      options.timeout = 3000
    }

    if (!options.timeoutText) {
      options.timeoutText = 'Your request takes a little longer then expected.'
    }

    if (!options.autoRetries) {
      options.autoRetries = 0
    }

    if (!options.maxAutoRetries) {
      options.maxAutoRetries = 1

      if (options.maxRetries < options.maxAutoRetries) {
        options.maxRetries = options.maxAutoRetries
      }
    }

    // @ts-ignore
    const preloader = f7.utils[`${f7.params.theme}PreloaderContent`] //see e.g. `framework7/framework7.js@mdPreloaderContent`
    const preloaderContent = `<div class="margin-top text-align-center"><div class="preloader">${preloader}</div></div>`
    let timer = undefined

    try {
      await f7.dialog
        .create({
          title: options.title,
          content: preloaderContent,
          buttons: [],
          closeByBackdropClick: false,
          backdrop: true
        })
        .open()

      if (options.timeout > 0) {
        timer = setTimeout(() => {
          f7.dialog.close()

          f7.dialog
            .create({
              title: options.title,
              content: preloaderContent,
              buttons: [],
              closeByBackdropClick: false,
              backdrop: true,
              text: options.timeoutText
            })
            .open()
        }, options.timeout)
      }

      await options.callback()

      await clearTimeout(timer)

      await f7.dialog.close()
    } catch (e) {
      Sentry.captureException(e)

      if (timer) {
        await clearTimeout(timer)
      }

      await f7.dialog.close()

      if (
        (isAbortedRequest(e) || isRetryableNetworkError(e)) &&
        options.retries < options.maxRetries
      ) {
        options.retries = options.retries ? options.retries + 1 : 1

        if (options.maxRetries > options.maxAutoRetries) {
          await confirmYesNo({
            title: 'Your action failed, do you want to retry?',
            yesButtonCallback: async () => {
              await withTextPreloaderAndNetworkErrorHandlingAsync(options)
            },
            noButtonCallback: async () => {
              if (!options.cancelCallback) {
                throw e
              }

              await options.cancelCallback()
            }
          })
        } else {
          await withTextPreloaderAndNetworkErrorHandlingAsync(options)
        }
      }

      throw e
    }
  }

  return {
    isLoading,
    toggleLoading,
    withAsyncLoading,
    withLoading,
    withPreloader,
    withPreloaderAsync,
    withTextPreloaderAsync,
    withTextPreloaderAndNetworkErrorHandlingAsync
  }
}
