import goodsReceiptApi from '@graphql/goods-receipt'
import {
  IBookReadyRequest,
  IDeleteGoodsEntryRequest,
  IGoodsEntryQueryContext,
  IGoodsEntryRequest,
  IGoodsReceiptLine
} from '@graphql/goodsReceipt/types'
import {
  IInboundProject,
  IInboundProjectItem,
  IProjectInboundState
} from '@store/modules/projects/inbound/types'
import { IRootState } from '@store/types'
import { ActionContext, ActionTree } from 'vuex'
import { ID } from '@graphql/types'

const actions: ActionTree<IProjectInboundState, IRootState> = {
  async addInboundProject(
    { commit }: ActionContext<IProjectInboundState, IRootState>,
    payload: IInboundProject
  ) {
    await commit('addInboundProject', payload)
  },

  async getGoodsEntry(context, id: ID) {
    return await goodsReceiptApi.getGoodsEntry(id)
  },

  async getGoodsEntries(
    { commit }: ActionContext<IProjectInboundState, IRootState>,
    context: IGoodsEntryQueryContext
  ) {
    const response = await goodsReceiptApi.getGoodsEntries(context)

    await commit('setGoodsEntries', response.data.data.goodsReceipt)

    return response
  },

  async createGoodsEntry(context, request: IGoodsEntryRequest) {
    return await goodsReceiptApi.createGoodsEntry(request)
  },

  async removeGoodsEntry(context, request: IDeleteGoodsEntryRequest) {
    return await goodsReceiptApi.removeGoodsEntry(request)
  },

  async markAsBookReady(
    { commit }: ActionContext<IProjectInboundState, IRootState>,
    context: IBookReadyRequest
  ) {
    const response = await goodsReceiptApi.markAsBookReady(context)

    await commit('removeInboundProject', context.id)

    return response
  },

  async removeProject({ commit }: ActionContext<IProjectInboundState, IRootState>, id: ID) {
    await commit('removeInboundProject', id)
  },

  async markItemDone(
    { commit }: ActionContext<IProjectInboundState, IRootState>,
    payload: IGoodsReceiptLine
  ) {
    await commit('markLineAsDone', payload)
  },

  async setActiveGoodsEntry(
    { commit }: ActionContext<IProjectInboundState, IRootState>,
    goodsEntry: IInboundProjectItem | undefined
  ) {
    await commit('setActiveGoodsEntry', goodsEntry)
  },

  async removeProjects({ commit }) {
    await commit('removeProjects')
  }
}

export default actions
