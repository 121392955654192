import { IGoodsReceipt, IGoodsReceiptLine } from '@graphql/goodsReceipt/types'
import {
  IInboundProject,
  IInboundProjectItem,
  IProjectInboundState
} from '@store/modules/projects/inbound/types'
import { MutationTree } from 'vuex'
import { ID } from '@graphql/types'

function findItemById(items: IInboundProject[], goodsReceiptId: number) {
  return items.filter((i) => i.id === goodsReceiptId)
}

export function generateInboundItemId(
  goodsReceipt: IGoodsReceipt,
  goodsReceiptLine: IGoodsReceiptLine
): string {
  return `${goodsReceipt.id}-${goodsReceiptLine.id}`
}

function extractGoodsReceiptIdFromProjectItem(item: IInboundProjectItem) {
  return Number(item.id.split('-')[0])
}

const mutations: MutationTree<IProjectInboundState> = {
  setGoodsEntries(state, payload: IGoodsReceipt) {
    state.goodsEntries = payload
  },

  addInboundProject(state, payload: IInboundProject) {
    const exists = state.items.find((i) => i.id === payload.id)

    if (exists) {
      return
    }

    state.items.push(payload)
  },

  addItemToInboundProject(state, payload: IInboundProjectItem) {
    const goodsReceiptId = extractGoodsReceiptIdFromProjectItem(payload)
    const projects = findItemById(state.items, goodsReceiptId)

    if (projects.length !== 1) {
      return
    }

    const project = projects[0]
    const matchingItems = project.items.filter(
      (i) => i.goodsReceiptLine.id === payload.goodsReceiptLine.id
    )

    if (matchingItems.length !== 0) {
      return // probably handle update here?
    }

    project.items.push(payload)
  },

  removeInboundProject(state, payload: ID) {
    const index = state.items.findIndex((i) => i.id == payload)

    if (index !== -1) {
      state.items.splice(index, 1)
    }
  },

  setActiveGoodsEntry(state, payload) {
    state.activeGoodsEntry = payload
  },

  removeProjects(state) {
    state.items = []
  }
}

export default mutations
