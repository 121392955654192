import { IRootState } from '@store/types'
import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { IProjectRelocateState } from './types'

const state: IProjectRelocateState = {
  count: 0,
  title: 'Relocate',
  type: 'relocate',
  isActive: false,
  itemCount: 0,
  items: []
}

const projectRelocate: Module<IProjectRelocateState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default projectRelocate
