import { isArticle, isLocation } from '@/functions/search'
import { ISearchResult, TypeName } from '@graphql/search/types'
import { SearchStrategy } from '@services/search/search'
import { SearchResults } from '@store/modules/search/types'
import { f7 } from 'framework7-vue'
import AbstractSearchStrategy from './abstract-strategy'

export default class CountSearchStrategy extends AbstractSearchStrategy {
  /**
   * @inheritdoc
   */
  public async handleSingularResult(result: ISearchResult): Promise<void> {
    let searchType: string | undefined

    if (isArticle(result)) {
      searchType = TypeName.Location
    } else if (isLocation(result)) {
      searchType = TypeName.Article
    }

    if (!searchType) {
      throw Error('Invalid operation')
    }

    await f7.views.main.router.navigate('/scan-input/', {
      props: {
        items: [],
        searchType,
        searchStrategy: SearchStrategy.ScanInput,
        successUrl: '/count/'
      }
    })
  }

  /**
   * @inheritdoc
   */
  public async handlePluralResult(results: SearchResults): Promise<void> {
    await f7.views.main.router.navigate('/search/', {
      props: {
        items: results,
        searchStrategy: SearchStrategy.Count
      }
    })
  }
}
