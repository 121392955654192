import Base from '@graphql/base'
import { IGraphQLResponse } from '@graphql/types'
import { IGetLocationByIdContext, ILocationSuggestionContext } from '@store/modules/location/types'
import { AxiosInstance, AxiosResponse } from 'axios'
import GRAPHQL_API_CLIENT from './client'

class Location extends Base {
  constructor(axios: AxiosInstance) {
    super(axios, 'location')
  }

  public getSuggestions(
    context: ILocationSuggestionContext
  ): Promise<AxiosResponse<IGraphQLResponse>> {
    return this.makeRequest('LocationSuggestionQuery', context)
  }

  public getById(context: IGetLocationByIdContext): Promise<AxiosResponse<IGraphQLResponse>> {
    return this.makeRequest('GetLocationByIdQuery', context)
  }
}

export default new Location(GRAPHQL_API_CLIENT)
