import LoginPage from '@pages/LoginPage.vue'
import { Router } from 'framework7/types'
import { beforeEnter } from '@framework7/routes/auth/logoutMiddleware'

const routes: Partial<Router.RouteParameters>[] = [
  {
    path: '/login/',
    component: LoginPage
  },
  {
    path: '/logout/',
    beforeEnter
  }
]

export default routes
