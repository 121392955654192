<template>
  <f7-sheet
    swipe-to-close
    backdrop
    :opened="sheetOpen"
    class="sheet bg-color-white"
    @sheet:closed="onClosed"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-title>Stock</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onClosed">
            <f7-icon material="close" />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <StockPage
        :item="article"
        :show-navigation="false"
        @update:model-value="onPopupOpened"
      ></StockPage>
    </f7-page>
  </f7-sheet>
</template>
<script lang="ts" setup>
import { IArticle } from '@graphql/article/types'
import StockPage from '@pages/article/StockPage.vue'

const sheetOpen = defineModel<boolean>()

defineProps<{
  article: IArticle
}>()

const onClosed = () => {
  sheetOpen.value = false
}

const onPopupOpened = (isOpen: boolean) => {
  sheetOpen.value = !isOpen
}
</script>

<style scoped>
.sheet {
  height: 85vh;
}
</style>
