<template>
  <f7-page>
    <navigation />
    <f7-block-title class="margin-top"> Remove from location </f7-block-title>
    <f7-card :class="{ 'skeleton-text': isLoading }">
      <f7-card-content>
        <f7-list media-list>
          <f7-list-item class="bg-color-yellow no-margin" media-list v-if="inTransitItem">
            You're already relocating this item, you have
            <strong>{{ inTransitItem.qty }}</strong> in transit, update your quantity here.
          </f7-list-item>
          <f7-list-item header="Article">
            {{ article?.name }} ({{ article?.company.code }})
          </f7-list-item>
          <f7-list-item header="Location">
            <string-label :value="activeArticleLocation.location.name" />
          </f7-list-item>
          <f7-list-item v-if="showAmountField" header="Quantity">
            <form-input-number
              :min-value="1"
              :value="inTransitItem ? inTransitItem.qty : qty"
              @change="onQtyChange"
              :auto-focus="true"
            />
          </f7-list-item>
          <f7-list-item
            checkbox
            value="moveAll"
            title="Remove all"
            @change="toggleRemoveAll"
            :checked="removeAll"
          />
        </f7-list>
      </f7-card-content>
    </f7-card>

    <f7-block>
      <f7-button
        large
        fill-md
        color="text-secondary"
        :disabled="!isSubmitButtonEnabled"
        @click="removeFromLocation"
      >
        <f7-preloader v-if="isLoading" />
        <span v-else>Remove from location</span>
      </f7-button>
    </f7-block>

    <f7-block-title> Additional article information </f7-block-title>
    <f7-list inset>
      <f7-list-item header="Code" :title="article.code" />
      <f7-list-item header="Primary barcode" :title="article.primaryBarcode" />
      <f7-list-item header="Company" :title="article.company.name" />
      <f7-list-item
        v-if="activeArticleLocation"
        header="Quantity on location"
        :title="activeArticleLocation.stockLevel.free"
      />
    </f7-list>
  </f7-page>
</template>
<script lang="ts" setup>
import FormInputNumber from '@components/form/InputNumber.vue'
import StringLabel from '@components/label/StringLabel.vue'
import Navigation from '@components/AppNavigation.vue'
import useArticle from '@composables/useArticle'
import useAuthentication from '@composables/useAuthentication'
import useLoading from '@composables/useLoading'
import useLocation from '@composables/useLocation'
import useProjectRelocate from '@composables/useProjectRelocate'
import { IArticleLocation, ILocation } from '@graphql/location/types'
import { notification } from '@services/notification'
import { toast } from '@services/toast'
import { IRelocateItem } from '@store/modules/projects/relocate/types'
import { f7 } from 'framework7-vue'
import { computed, nextTick, ref } from 'vue'
import { IArticle } from '@graphql/article/types'

const qty = ref<number | string>('')
const removeAll = ref<boolean>(false)

const { isLoading } = useLoading()
const { location } = useLocation()
const { activeArticle: article } = useArticle()
const { items, relocateFromTransitStock, relocateToTransitStock } = useProjectRelocate()
const { currentUser } = useAuthentication()

const inTransitItem = computed<IRelocateItem | null>(() => {
  return (
    items.value.find(
      (p: IRelocateItem) =>
        article.value &&
        p.article.id === article.value.id &&
        location.value &&
        p.location.id === location.value.id
    ) ?? null
  )
})

const showAmountField = computed<boolean>(() => !removeAll.value)

const activeArticleLocation = computed<IArticleLocation>(() => {
  return (article.value as IArticle).articleLocation.filter(
    (al: IArticleLocation) => location.value && al.location.id === location.value.id
  )[0]
})

const isSubmitButtonEnabled = computed<boolean>(() => {
  if (isLoading.value) {
    return false
  }

  return Number(qty.value) > 0 || removeAll.value
})

const toggleRemoveAll = () => {
  removeAll.value = !removeAll.value
}

const onQtyChange = (value: number) => {
  qty.value = value
}

const removeFromLocation = async () => {
  if (qty.value <= 0 && !removeAll.value) {
    await toast.error(`Cannot remove quantity of ${qty.value}!`, 3000).open()
    return
  }

  if (removeAll.value) {
    qty.value = activeArticleLocation.value.stockLevel.free
  }

  const articleToRemove = article.value as IArticle
  const locationToRemoveFrom = location.value as ILocation

  if (
    articleToRemove.articleLocation.filter(
      (articleLocation: IArticleLocation) => articleLocation.location.id === locationToRemoveFrom.id
    ).length === 0
  ) {
    await toast.error(`Location ${locationToRemoveFrom.name} is not valid`, 3000).open()

    return
  }

  try {
    isLoading.value = true

    const relocateItem = {
      article: articleToRemove,
      articleID: articleToRemove.id,
      articleName: articleToRemove.name,
      locationID: locationToRemoveFrom.id,
      location: locationToRemoveFrom,
      moveAll: removeAll.value,
      qty: Number(qty.value),
      addedBy: currentUser.value,
      addedOn: new Date()
    }

    if (inTransitItem.value) {
      const updateRelocateItem = getUpdateRelocateItem(inTransitItem.value, relocateItem)
      await executeRelocate(updateRelocateItem)
      await notification
        .success({
          message: `Updated quantity to ${qty.value} for ${articleToRemove.name} from location: ${locationToRemoveFrom.name}`
        })
        .open()
      // eslint-disable-next-line vue/valid-next-tick
      await nextTick(() =>
        f7.views.main.router.navigate('/projects/relocate/', { reloadAll: true })
      )
      return
    }

    if (removeAll.value) {
      relocateItem.moveAll = removeAll.value
    }

    await executeRelocate(relocateItem)

    await notification
      .success({
        message: `Removed ${articleToRemove.name} from location: ${locationToRemoveFrom.name}`
      })
      .open()
    // eslint-disable-next-line vue/valid-next-tick
    await nextTick(() => f7.views.main.router.navigate('/projects/relocate/', { reloadAll: true }))
  } catch (error: any) {
    await toast.error(`<strong>${error}</strong>`, 10000, true).open()
    throw error
  } finally {
    isLoading.value = false
  }
}

const executeRelocate = async (relocateItem: IRelocateItem) => {
  if (relocateItem.qty > 0) {
    await relocateToTransitStock({ item: relocateItem, locationID: 0 })
  } else if (relocateItem.qty < 0) {
    relocateItem.qty = -relocateItem.qty
    await relocateFromTransitStock({
      item: relocateItem,
      locationID: (relocateItem.location as ILocation).id
    })
  }
}

const getUpdateRelocateItem = (
  currentTransitRelocateItem: IRelocateItem,
  relocateItem: IRelocateItem
) => {
  const mergedItem = Object.assign({}, relocateItem)
  mergedItem.qty = relocateItem.qty - currentTransitRelocateItem.qty

  return mergedItem
}
</script>
