import Base from '@graphql/base'
import { AxiosInstance } from 'axios'
import GRAPHQL_API_CLIENT from './client'
import {
  IGetLoadCarrierByReferenceRequest,
  IGetLoadCarrierByReferenceResponse,
  IGetPickBatchSortStateRequest,
  IGetPickBatchSortStateResponse,
  IGetSortResultRequest,
  IGetSortResultResponse,
  IMarkPickBatchSortedRequest,
  IMarkPickBatchSortedResponse,
  IMarkSortLoadCarrierSortedRequest,
  IMarkSortLoadCarrierSortedResponse,
  IPutItemInCompartmentRequest,
  IPutItemInCompartmentResponse,
  IWantsNewCompartmentRequest,
  IWantsNewCompartmentResponse
} from '@graphql/sort/types'

export class Sort extends Base {
  constructor(axios: AxiosInstance) {
    super(axios, 'sort')
  }

  async putItemInCompartment(request: IPutItemInCompartmentRequest) {
    return await this.makeRequest<IPutItemInCompartmentResponse>(
      'putItemInCompartmentMutation',
      request
    )
  }

  async wantsNewCompartment(request: IWantsNewCompartmentRequest) {
    return await this.makeRequest<IWantsNewCompartmentResponse>(
      'wantsNewCompartmentMutation',
      request
    )
  }

  public async getSortResult(request: IGetSortResultRequest) {
    return await this.makeRequest<IGetSortResultResponse>('getSortResultQuery', request)
  }

  async getLoadCarrierByReference(request: IGetLoadCarrierByReferenceRequest) {
    return await this.makeRequest<IGetLoadCarrierByReferenceResponse>(
      'getLoadCarrierByReferenceQuery',
      request
    )
  }

  async getPickBatchSortState(request: IGetPickBatchSortStateRequest) {
    return await this.makeRequest<IGetPickBatchSortStateResponse>(
      'getPickBatchSortStateQuery',
      request
    )
  }

  async markSortLoadCarrierSorted(request: IMarkSortLoadCarrierSortedRequest) {
    return await this.makeRequest<IMarkSortLoadCarrierSortedResponse>(
      'markSortLoadCarrierSortedMutation',
      request
    )
  }

  async markPickBatchSorted(request: IMarkPickBatchSortedRequest) {
    return await this.makeRequest<IMarkPickBatchSortedResponse>(
      'markPickBatchSortedMutation',
      request
    )
  }
}

export default new Sort(GRAPHQL_API_CLIENT)
