import { ISearch, ISearchResult, TypeName } from '@graphql/search/types'
import { PluralResultCallback, SingleResultCallback } from '@services/search/search'
import AbstractSearchStrategy from '@services/search/strategy/abstract-strategy'
import { SearchResults } from '@store/modules/search/types'

export default class PickLineSearchStrategy extends AbstractSearchStrategy {
  public async parseSearchResults(searchResults: ISearch) {
    const validTypes = [TypeName.Location.toString(), TypeName.Article.toString()]

    return searchResults.search.filter((result) => validTypes.includes(result.__typename))
  }

  public async handleSingularResult(result: ISearchResult, callback?: SingleResultCallback) {
    if (callback) {
      callback(result)
    }
  }

  public async handlePluralResult(results: SearchResults, callback?: PluralResultCallback) {
    if (callback) {
      callback(results)
    }
  }
}
