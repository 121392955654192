import { Module } from 'vuex'
import { IRootState } from '../../types'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { IShipmentState } from './types'

const state: IShipmentState = {
  shipment: null
}

const search: Module<IShipmentState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default search
