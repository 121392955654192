<template>
  <f7-list-item header="Location" swipeout @swipeout:opened="onOverSwipe">
    <template #title>
      {{ result.name }}
    </template>
    <slot name="actions" />
  </f7-list-item>
</template>
<script lang="ts" setup>
defineProps<{
  result: object
}>()

const emit = defineEmits<{
  (e: 'over-swipe', payload: object)
}>()

const onOverSwipe = async (result: object) => {
  await emit('over-swipe', result)
}
</script>
