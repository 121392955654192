import { IRegisterContext } from '@graphql/device/types'
import { useStore } from '@store/store'

export default () => {
  const store = useStore()

  const registerDevice = async (context: IRegisterContext): Promise<boolean> =>
    store.dispatch('device/registerDevice', context)
  const deregisterDevice = async (): Promise<boolean> => store.dispatch('device/deregisterDevice')

  return {
    registerDevice,
    deregisterDevice
  }
}
