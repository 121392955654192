import goodsReceiptApi from '@graphql/goods-receipt'
import {
  IGoodsReceipt,
  IGoodsReceiptLine,
  IGoodsReceiptQueryContext,
  IStartGoodsEntryRequest
} from '@graphql/goodsReceipt/types'
import { IGoodsReceiptState } from '@store/modules/goods-receipt/types'
import { IRootState } from '@store/types'
import { ActionContext, ActionTree } from 'vuex'
import { ID } from '@graphql/types'

const actions: ActionTree<IGoodsReceiptState, IRootState> = {
  async getGoodsReceiptsTillState({ commit }: ActionContext<IGoodsReceiptState, IRootState>) {
    const response = await goodsReceiptApi.getGoodsReceiptsTillState()

    await commit('setOpenGoodsReceipts', response.data.data.goodsReceiptsTillState)

    return response
  },

  async getGoodsReceipt(
    { commit }: ActionContext<IGoodsReceiptState, IRootState>,
    context: IGoodsReceiptQueryContext
  ): Promise<IGoodsReceipt> {
    const response = await goodsReceiptApi.getGoodsReceipt(context)

    await commit('setActiveGoodsReceipt', response.data.data.goodsReceipt)

    return response.data.data.goodsReceipt
  },

  async startGoodsEntry(context, request: IStartGoodsEntryRequest) {
    return await goodsReceiptApi.startGoodsEntry(request)
  },

  async getGoodsReceiptLine({ commit }, id: ID): Promise<IGoodsReceiptLine> {
    const response = await goodsReceiptApi.getGoodsEntry(id)

    await commit('setActiveGoodsReceiptLine', response.data.data.goodsReceiptLine)

    return response.data.data.goodsReceiptLine as IGoodsReceiptLine
  }
}

export default actions
