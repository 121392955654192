<template>
  <f7-page :page-content="false">
    <navigation v-slot="{ searchId }">
      <search-bar
        :search-id="searchId"
        search-strategy="default-without-routing"
        :singular-result-callback="onResult"
        :plural-result-callback="onResults"
        search-type="LoadCarrier"
        @no-result="onNoResult"
        expandable
      />
    </navigation>
    <f7-page-content>
      <f7-card :class="{ 'skeleton-text': isLoading }">
        <f7-card-content>
          <icon-with-text
            :icon="['far', 'box-full']"
            title="Scan load carrier barcode to start"
            description="To start packing you are required to scan a valid load carrier barcode."
          />
        </f7-card-content>
      </f7-card>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import IconWithText from '@components/IconWithText.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import useLoading from '@composables/useLoading'
import useSearch from '@composables/useSearch'
import { perceptibleToast } from '@services/perceptibleToast'
import usePack from '@composables/usePack'

const { onResult } = usePack()
const { onNoResult } = useSearch()
const { isLoading } = useLoading()

const onResults = async () => {
  await perceptibleToast.error(
    'Multiple results found for the scanned item, did you scan a load carrier barcode?'
  )
}
</script>
