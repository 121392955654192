import {
  IAuthenticationResult,
  IAuthenticatorAuthenticationRequest,
  IAuthenticatorAuthenticationResponse,
  ITokenAuthenticationResponse,
  IUsernamePasswordAuthenticationResponse
} from '@graphql/auth/types'
import { AxiosInstance } from 'axios'
import Base from './base'
import GRAPHQL_API_CLIENT from './client'

class Auth extends Base {
  constructor(protected axios: AxiosInstance) {
    super(axios, 'auth')
  }

  public async login(username: string, password: string): Promise<IAuthenticationResult | null> {
    const response = await this.makeRequest<IUsernamePasswordAuthenticationResponse>(
      'usernamePasswordAuthentication',
      {
        username,
        password
      },
      false
    )

    return response.data.data.usernamePasswordAuthentication
  }

  public async tokenLogin(token: string): Promise<IAuthenticationResult> {
    const response = await this.makeRequest<ITokenAuthenticationResponse>(
      'tokenAuthentication',
      {
        token
      },
      false
    )

    return response.data.data.tokenAuthentication
  }

  public async authenticatorLogin(
    request: IAuthenticatorAuthenticationRequest
  ): Promise<IAuthenticationResult> {
    const response = await this.makeRequest<IAuthenticatorAuthenticationResponse>(
      'authenticatorAuthentication',
      request,
      false
    )

    return response.data.data.authenticatorAuthentication
  }
}

export default new Auth(GRAPHQL_API_CLIENT)
