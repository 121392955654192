import {
  IAddLoadCarrierRequest,
  IAddLoadCarrierResponse,
  IGetLoadCarrierByIdRequest,
  ILoadCarrier
} from '@graphql/pick/types'
import { useStore } from '@store/store'

export default () => {
  const store = useStore()

  const addLoadCarrier = async (
    request: IAddLoadCarrierRequest
  ): Promise<IAddLoadCarrierResponse> =>
    await store.dispatch('projects/pick/addLoadCarrier', request)

  const getLoadCarrierById = async (request: IGetLoadCarrierByIdRequest): Promise<ILoadCarrier> =>
    await store.dispatch('projects/pick/getLoadCarrierById', request)

  return {
    addLoadCarrier,
    getLoadCarrierById
  }
}
