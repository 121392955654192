<template>
  <f7-page>
    <navigation />

    <f7-block-title class="margin-top"> Count this article: </f7-block-title>
    <f7-card>
      <f7-card-content>
        <f7-list media-list no-hairlines-md>
          <f7-list-item header="Article">
            <string-label :value="activeArticle.name" />
          </f7-list-item>
          <f7-list-item header="Location">
            <string-label :value="location.name" />
          </f7-list-item>
          <f7-list-item header="Count">
            <form-input-number
              :value="articleCount"
              @change="onCountChange"
              :auto-focus="true"
              @submit="onConfirm"
            />
          </f7-list-item>
        </f7-list>
      </f7-card-content>
    </f7-card>

    <f7-block>
      <f7-button
        large
        color="text-secondary"
        :disabled="articleCount < 0"
        fill-md
        @click.prevent="onConfirm"
      >
        Confirm
      </f7-button>
    </f7-block>

    <f7-block-header> Additional information: </f7-block-header>
    <f7-card>
      <f7-card-content>
        <f7-list media-list>
          <f7-list-item header="Code">
            <string-label :value="activeArticle.code" />
          </f7-list-item>
          <f7-list-item header="Primary barcode">
            <string-label :value="activeArticle.primaryBarcode" />
          </f7-list-item>
        </f7-list>
      </f7-card-content>
    </f7-card>
  </f7-page>
</template>
<script lang="ts" setup>
import StringLabel from '@components/label/StringLabel.vue'
import Navigation from '@components/AppNavigation.vue'
import useArticle from '@composables/useArticle'
import useAuthentication from '@composables/useAuthentication'
import useLocation from '@composables/useLocation'
import FormInputNumber from '@components/form/InputNumber.vue'
import useProjectCount from '@composables/useProjectCount'
import { notification } from '@services/notification'
import { ICountItem } from '@store/modules/projects/count/types'
import { f7 } from 'framework7-vue'
import { ref } from 'vue'
import { ILocation } from '@graphql/location/types'
import { DateTime } from 'luxon'

const articleCount = ref<number | string>('')
const { activeArticle } = useArticle()
const { itemCount, add } = useProjectCount()
const { location } = useLocation()
const { currentUser } = useAuthentication()

const onConfirm = async () => {
  if (!activeArticle.value) {
    return
  }

  const payload: ICountItem = {
    articleID: activeArticle.value.id,
    articleName: activeArticle.value.name,
    article: activeArticle.value,
    locationID: (location.value as ILocation).id,
    location: location,
    qty: Number(articleCount.value),
    countedAt: DateTime.now(),
    addedBy: currentUser.value,
    addedOn: DateTime.now()
  }

  await add(payload)

  await notification
    .success({ message: `Article "${activeArticle.value.name}" added to count project!` })
    .open()

  if (itemCount.value > 0) {
    f7.views.main.router.navigate('/projects/count/', { clearPreviousHistory: true })
  }
}

const onCountChange = (e: any): void => {
  if (e instanceof Event) {
    // @ts-ignore
    e = e.target.value
  }
  if (!e) {
    return
  }

  articleCount.value = e < 0 ? 0 : e
}
</script>
