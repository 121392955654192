import device from '@store/modules/device'
import article from '@store/modules/article'
import auth from '@store/modules/auth/index'
import event from '@store/modules/event/index'
import goodsReceipt from '@store/modules/goods-receipt'
import location from '@store/modules/location'
import pickBatch from '@store/modules/pick-batch'
import projectCount from '@store/modules/projects/count'
import projectInbound from '@store/modules/projects/inbound'
import projectPick from '@store/modules/projects/pick'
import projectRelocate from '@store/modules/projects/relocate'
import purchaseOrder from '@store/modules/purchase-order'
import search from '@store/modules/search/index'
import settings from '@store/modules/settings/index'
import shipment from '@store/modules/shipment/index'
import stock from '@store/modules/stock/index'
import task from '@store/modules/task/index'
import { IRootState } from '@store/types'
import { InjectionKey } from '@vue/runtime-core/dist/runtime-core'
import { createStore, Store, useStore as useBaseStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import projectSorting from '@store/modules/projects/sorting'
import pack from '@store/modules/pack/index'
import sort from '@store/modules/sort/index'
import document from '@store/modules/document/index'
import warehouse from '@store/modules/warehouse'

export const key: InjectionKey<Store<IRootState>> = Symbol('')

export const store = createStore<IRootState>({
  modules: {
    article,
    stock,
    search,
    auth,
    purchaseOrder,
    goodsReceipt,
    location,
    shipment,
    warehouse,
    'projects/relocate': projectRelocate,
    'projects/count': projectCount,
    'projects/inbound': projectInbound,
    'projects/pick': projectPick,
    'projects/sorting': projectSorting,
    event,
    pickBatch,
    settings,
    device,
    task,
    pack,
    sort,
    document
  },
  plugins: [
    createPersistedState({
      key: `nl.dimass.scanner${import.meta.env.PROD ? '' : '-dev'}`,
      storage: window.localStorage
    }),
  ]
})

export function useStore(): Store<IRootState> {
  return useBaseStore(key)
}
