import { IEdge } from '@graphql/types'

export class Collection<T> {
  private items: T[] = []

  public constructor(items: T[] = []) {
    this.items = items
  }

  public all(): T[] {
    return this.items
  }

  public count(): number {
    return this.items.length
  }

  public isEmpty(): boolean {
    return this.count() === 0
  }

  public isNotEmpty(): boolean {
    return this.count() > 0
  }

  public add(item: T): void {
    this.items.push(item)
  }

  public set(items: T[]): void {
    this.items = items
  }

  public remove(item: T, equalsFunction?: IEqualsFunction<T>): void {
    const index = this.indexOf(item, equalsFunction)

    if (index === -1) {
      return
    }

    this.removeAtIndex(index)
  }

  public removeAtIndex(index: number) {
    this.items.splice(index, 1)
  }

  public replace(index: number, item: T): void {
    this.items[index] = item
  }

  public indexOf(item: T, equalsFunction?: IEqualsFunction<T>): number {
    const equals = equalsFunction || this.defaultEquals
    const length = this.items.length

    for (let i = 0; i < length; i++) {
      if (equals(this.items[i], item)) {
        return i
      }
    }

    return -1
  }

  public lastIndexOf(item: T, equalsFunction?: IEqualsFunction<T>): number {
    const equals = equalsFunction || this.defaultEquals
    const length = this.items.length

    for (let i = length; i >= 0; i--) {
      if (equals(this.items[i], item)) {
        return i
      }
    }

    return -1
  }

  public contains(item: T, equalsFunction?: IEqualsFunction<T>): boolean {
    return this.indexOf(item, equalsFunction) !== -1
  }

  public clear(): void {
    this.items = []
  }

  private defaultEquals(a: T, b: T): boolean {
    return a === b
  }
}

export type IEqualsFunction<T> = (a: T, b: T) => boolean

export function cursorEquals<T extends IEdge>(a: T, b: T): boolean {
  return a.cursor === b.cursor
}

export function nodeIdEquals<T extends IEdge>(a: T, b: T): boolean {
  return a.node.id === b.node.id
}

export default Collection
