import { IUser } from '@store/modules/auth/types'
import { ID, IGraphQLResponse, INode } from '@graphql/types'

export interface ITask extends INode {
  id: ID
  state: string
  createdAt: Date
  updateAt: Date
  startedAt: Date
  finishedAt: Date
  executions: number
  user: IUser
}

export interface IGetByIdRequest {
  id: ID
}

export interface IGetByIdResponse extends IGraphQLResponse {
  data: {
    getById: ITask
  }
}

export const enum TaskState {
  CREATED = 'created',
  SCHEDULED = 'scheduled',
  RUNNING = 'running',
  FAILED = 'failed',
  FINISHED = 'finished'
}
