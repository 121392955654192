import { soundBoard } from '@services/sound'
import { notification } from '@services/notification'

export class PerceptibleNotification {
  public async success(message: string, closeTime = 3000, button = true) {
    await soundBoard.playSuccessSound()

    await notification.success({ message, closeTime, button }).open()
  }

  public async finishedTask(message: string, closeTime = 3000, button = true) {
    await soundBoard.playTaskCompleteSound()

    await notification.success({ message, closeTime, button }).open()
  }

  public async error(message: string, closeTime = 5000, button = true) {
    await soundBoard.playFailedSound()

    await notification.error({ message, closeTime, button }).open()
  }
}

export const perceptibleNotification = new PerceptibleNotification()
