import Base from '@graphql/base'
import GRAPHQL_API_CLIENT from '@graphql/client'
import {
  IBulkPrintDocumentResponse,
  IBulkPrintDocumentsRequest,
  IFinalizePackingControlRequest,
  IGetColloByIdRequest,
  IGetColloByIdResponse,
  IGetShipmentByIdRequest,
  IPrintDocumentsRequest,
  IPrintDocumentsResponse,
  IShipmentResponse
} from '@graphql/shipment/types'
import { AxiosInstance } from 'axios'

class Shipment extends Base {
  constructor(axios: AxiosInstance) {
    super(axios, 'shipment')
  }

  public async getShipmentById(request: IGetShipmentByIdRequest) {
    return this.makeRequest<IShipmentResponse>('getShipmentByIdQuery', request)
  }

  public async finalizePackingControl(request: IFinalizePackingControlRequest) {
    return this.makeRequest('finalizePackingControlMutation', request)
  }

  public async printDocuments(request: IPrintDocumentsRequest) {
    return this.makeRequest<IPrintDocumentsResponse>('printDocumentsQuery', request)
  }

  public async bulkPrintDocuments(request: IBulkPrintDocumentsRequest) {
    return this.makeRequest<IBulkPrintDocumentResponse>('bulkPrintDocumentsQuery', request)
  }

  async getColloById(request: IGetColloByIdRequest) {
    return this.makeRequest<IGetColloByIdResponse>('getColloByIdQuery', request)
  }
}

export default new Shipment(GRAPHQL_API_CLIENT)
