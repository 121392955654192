import { IArticle } from '@graphql/article/types'
import { ICompany, ISearchResult } from '@graphql/search/types'
import { IConnection, ID, IEdge, IGraphQLResponse, INode } from '@graphql/types'
import { IUser } from '@store/modules/auth/types'
import { IPrintContext, IPrintedDocumentsResult } from '@graphql/document/types'

export interface ISite extends INode {
  id: ID
  name: string
  code: string
  type: boolean
  isDefault: boolean
  purchaseOrder: boolean
  goodsReturn: boolean
  internalOrder: boolean
  createdAt: Date
  updatedAt: Date
  deletedAt: Date | null
  address: IAddress
}

export interface ICourier extends INode {
  id: ID
  code: string
  palletType: string | null
  name: string
  active: boolean
  groupCode: string | null
  isExpress: boolean
  exportInfoRequired: boolean
  askForColli: boolean
  colliNumFromShipment: boolean
  isDelegator: boolean
  isVirtual: boolean
  colliContentDefinerEnabled: boolean
  defaultCutoffTime: Date | null
}

export interface IAddress extends INode {
  id: ID
  firstname: string | null
  lastname: string | null
  company: string | null
  street: string
  number: string | null
  zipcode: string
  city: string
  country: string
  locale: string | null
  phone: string | null
}

export interface IOrder extends INode {
  id: ID
  orderNr: string
  createdAt: Date
  updatedAt: Date
  deletedAt: Date | null
  company: ICompany
  companyOrigin: ICompany
  site: ISite
  address: IAddress
  invoiceAddress: IAddress
  courierAuto: boolean
  courier: ICourier
}

export interface IShipment extends ISearchResult {
  order: IOrder
  state: number | null
  colli: number
  weight: number | null
  subState: number | null
  humanState: string
  preNotificationData: string | null
  stateDetail: string | null
  flatCountry: string | null
  flatPartner: string | null
  flatCourier: string | null
  attributesComplete: boolean
  shippedAt: Date | null
  latestShippingDate: Date | null
  deliveryDate: Date | null
  createdAt: Date
  updatedAt: Date
  deletedAt: Date | null
  partner: ICompany
  site: ISite
  shipmentLine: IShipmentLineConnection
  shipmentColli: IShipmentColli[]
  courier: ICourier
  packingControlledAt: Date | null
  packingControlledBy: IUser | null
}

export interface IShipmentLine extends INode {
  id: ID
  amount: number
  originalAmount?: number
  backorder: number
  delivered: number
  handlingCosts: number
  sortOrder: number
  shipment: IShipment
  article: IArticle
}

export interface IShipmentLineColli extends INode {
  id: ID
  amount: number
  shipmentLine: IShipmentLine
}

export interface ICollo extends ISearchResult {
  shipmentColli: IShipmentColli
  courier: ICourier
  site: ISite
  partner: IPartner
  trackingUrl: string | null
  internationalTrackingUrl: string | null
  reference: string | null
  internationalReference: string | null
  state: number
  stateDetail: string
  sequence: number
  createdAt: Date
  updatedAt: Date
  deletedAt: Date | null
  packReference: string | null
}

export interface IPackingAttachment extends INode {
  id: ID
}

export interface IShipmentColli extends INode {
  id: ID
  state: number
  stateDetail: string
  createdAt: Date
  updatedAt: Date
  deletedAt: Date | null
  shipment: IShipment
  shipmentLineColli: IShipmentLineColli[]
  packingAttachments: IPackingAttachment[]
  collo: ICollo
}

export interface IGetShipmentByIdRequest {
  id: ID
  linesPerPage?: number
  linesNextPageCursor?: string | null
}

export interface IPrintDocumentsRequest {
  context: IPrintContext
  packStationId: ID
}

export interface IBulkPrintDocumentsRequest {
  loadCarrierId: ID
  packStationId: ID
  barcode: string
}

export interface IBulkPrintDocumentResponse extends IGraphQLResponse {
  data: {
    bulkPrintDocuments: boolean
  }
}

export interface IPrintDocumentsResponse extends IGraphQLResponse {
  data: {
    printDocuments: IPrintedDocumentsResult
  }
}

export interface IFinalizePackingControlRequest {
  shipmentId: ID
}

export interface IShipmentQueryResult {
  shipment: Partial<IShipment>
}

export type IShipmentEdge = IEdge<IShipment>
export type IShipmentLineEdge = IEdge<IShipmentLine>

export type IShipmentLineConnection = IConnection<IShipmentLine>

export interface IShipmentResponse extends IGraphQLResponse {
  data: IShipmentQueryResult
}

export type IShipmentConnection = IConnection<Partial<IShipment>>

export type IColloEdge = IEdge<ICollo>

export type IColliConnection = IConnection<Partial<ICollo>>

export interface IGetColloByIdRequest {
  id: ID
}

export interface IGetColloByIdResponse extends IGraphQLResponse {
  data: {
    collo: ICollo
  }
}

export const enum StateEnum {
  CREATED = 0,
  PROCESSING = 5,
  PACKING_CONTROL = 7,
  PRE_NOTIFICATION = 8,
  PRE_NOTIFICATION_PACKED = 810,
  PRE_NOTIFICATION_INFORMATION_REQUIRED = 820,
  PRE_NOTIFICATION_INFORMATION_RECEIVED = 830,
  PRE_NOTIFICATION_INFORMATION_PROCESSING = 840,
  READY_FOR_SENDING = 10,
  AWAITING_COURIER_FEEDBACK = 12,
  SENT = 15,
  SENT_ACCEPTED = 1499,
  SENT_TRANSIT = 1500,
  SENT_FAILURE = 1510,
  SENT_RETURNED = 1520,
  SENT_DELIVERED = 1530
}
