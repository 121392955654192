import { ISettingState } from '@store/modules/settings/types'
import { IRootState } from '@store/types'
import { GetterTree } from 'vuex'

const getters: GetterTree<ISettingState, IRootState> = {
  packStation: (state) => state.packStation,
  deviceId: (state) => state.deviceId
}

export default getters
