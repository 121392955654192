<template>
  <f7-page :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar
        :search-id="searchId"
        :search-type="TypeName.GoodsReceipt"
        :restricted="true"
        expandable
        @result="onSearchResult"
      ></search-bar>
    </navigation>
    <f7-page-content>
      <data-table-card :items="goodsReceipts" :is-loading="isLoading">
        <template #no-items>No receipts available!</template>
        <data-table>
          <template #header>
            <table-row>
              <label-cell>GR # / Supplier</label-cell>
              <label-cell>Status / Delivered at</label-cell>
            </table-row>
          </template>
          <table-row
            v-for="goodsReceipt in goodsReceipts"
            :key="goodsReceipt.id"
            @click="onGoodsReceiptClick(goodsReceipt)"
          >
            <label-cell>
              <string-label :value="goodsReceipt.searchPreview" />
              <br />
              <string-label :value="goodsReceipt.purchaseOrder.supplier.name" />
            </label-cell>
            <label-cell>
              <string-label :value="goodsReceipt.stateString" />
              <br />
              <date-time-label :value="goodsReceipt.deliveredAt" />
            </label-cell>
          </table-row>
        </data-table>
      </data-table-card>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import DateTimeLabel from '@components/label/DateTime.vue'
import StringLabel from '@components/label/StringLabel.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import useGoodsReceipt from '@composables/useGoodsReceipt'
import useLoading from '@composables/useLoading'
import { IGoodsReceipt } from '@graphql/goodsReceipt/types'
import { ISearchResult, TypeName } from '@graphql/search/types'
import { f7 } from 'framework7-vue'
import { onMounted } from 'vue'
import DataTableCard from '@components/data-table/DataTableCard.vue'
import LabelCell from '@components/data-table/LabelCell.vue'
import TableRow from '@components/data-table/TableRow.vue'
import DataTable from '@components/data-table/DataTable.vue'

const { openGoodsReceipts: goodsReceipts, getGoodsReceiptsTillState } = useGoodsReceipt()
const { isLoading, withAsyncLoading } = useLoading()

onMounted(async () => {
  await withAsyncLoading(async () => {
    await getGoodsReceiptsTillState()
  })
})

const onGoodsReceiptClick = async (goodsReceipt: IGoodsReceipt) => {
  await f7.views.main.router.navigate(`/goodsReceipt/${goodsReceipt.id}/`)
}

const onSearchResult = async (result: ISearchResult) => {
  if (goodsReceipts.value.findIndex((p: IGoodsReceipt) => p.id === result.id) !== -1) {
    await f7.views.main.router.navigate(`/goodsReceipt/${result.id}/`)
  }
}
</script>
