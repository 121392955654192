import { MutationTree } from 'vuex'
import { IProjectSortingState, ISortingProject } from '@store/modules/projects/sorting/types'
import { ID } from '@graphql/types'

const findIndex = (state: IProjectSortingState, project: ISortingProject) => {
  return state.items.findIndex((item) => item.pickBatchId === project.pickBatchId)
}

const mutations: MutationTree<IProjectSortingState> = {
  clearProjects(state) {
    state.items = []
  },
  add(state, project: ISortingProject) {
    const index = findIndex(state, project)

    if (index !== -1) {
      return
    }

    state.items.push(project)
  },
  update(state, project: ISortingProject) {
    const index = findIndex(state, project)

    if (index === -1) {
      return
    }

    state.items[index] = project
  },
  remove(state, pickBatchId: ID) {
    const index = state.items.findIndex((item) => item.pickBatchId === pickBatchId)

    if (index === -1) {
      return
    }

    state.items.splice(index, 1)
  }
}

export default mutations
