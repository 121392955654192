import shipmentApi from '@graphql/shipment'
import {
  IBulkPrintDocumentsRequest,
  IFinalizePackingControlRequest,
  IGetColloByIdRequest,
  IGetShipmentByIdRequest,
  IPrintDocumentsRequest,
  IShipment,
  IShipmentResponse
} from '@graphql/shipment/types'
import { AxiosResponse } from 'axios'
import { ActionTree } from 'vuex'
import { IRootState } from '../../types'
import { IShipmentState } from './types'

const actions: ActionTree<IShipmentState, IRootState> = {
  async getShipmentById(
    { commit },
    request: IGetShipmentByIdRequest
  ): Promise<AxiosResponse<IShipmentResponse>> {
    const response = await shipmentApi.getShipmentById(request)

    await commit('setActiveShipment', response.data.data.shipment)

    return response
  },
  async setShipment({ commit }, shipment: Partial<IShipment>) {
    await commit('setShipment', shipment)

    return shipment
  },
  async finalizePackingControl(
    { commit },
    request: IFinalizePackingControlRequest
  ): Promise<AxiosResponse<any>> {
    const response = await shipmentApi.finalizePackingControl(request)

    await commit('setShipment', null)

    return response
  },
  async printDocuments(context, request: IPrintDocumentsRequest) {
    return await shipmentApi.printDocuments(request)
  },
  async bulkPrintDocuments(context, request: IBulkPrintDocumentsRequest) {
    return await shipmentApi.bulkPrintDocuments(request)
  },
  async getColloById(context, request: IGetColloByIdRequest) {
    const response = await shipmentApi.getColloById(request)

    return response.data.data.collo
  }
}

export default actions
