import { ID, IGraphQLResponse } from '@graphql/types'
import { TypeName } from '@graphql/search/types'

export const enum DocumentKind {
  SHIPPING_LABELS = 'shipping_labels',
  PRE_PACK_LABELS = 'pre_pack_labels',
  PACKING_ATTACHMENTS = 'packing_attachments',
  SORT_LABELS = 'sort_labels',
  PICK_LABELS = 'pick_labels'
}

export class PrintContext implements IPrintContext {
  constructor(
    public readonly identifier: ID,
    public readonly type: TypeName,
    public readonly documentKind: DocumentKind | null = null,
    public readonly printerCode: string | null = null
  ) {}
}

export interface IPrintContext {
  identifier: ID
  type: string
  documentKind: DocumentKind | null
  printerCode: string | null
}

export interface IPrintedDocumentsResult {
  total: number
  labels: number
  attachments: number
}

export interface IPrintSubjectDocumentsRequest {
  context: IPrintContext
  packStationId: ID
}

export interface IPrintSubjectDocumentsResponse extends IGraphQLResponse {
  data: {
    printSubjectDocuments: IPrintedDocumentsResult
  }
}
