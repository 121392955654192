import { Module } from 'vuex'
import { IRootState } from '@store/types'
import { IDocumentState } from '@store/modules/document/types'
import { IPrintSubjectDocumentsRequest } from '@graphql/document/types'

import api from '@graphql/document'

const document: Module<IDocumentState, IRootState> = {
  namespaced: true,
  actions: {
    async printSubjectDocuments(context, request: IPrintSubjectDocumentsRequest) {
      if (!request.packStationId) {
        request.packStationId = null
      }

      const response = await api.printSubjectDocuments(request)

      return response.data.data.printSubjectDocuments
    }
  }
}

export default document
