<template>
  <f7-page :page-content="false">
    <navigation />
    <f7-page-content>
      <f7-card :class="{ 'skeleton-text': !goodsReceiptLine }" v-if="goodsReceiptLine">
        <f7-card-content>
          <f7-list media-list>
            <f7-list-item header="Delivered quantity">
              <input-number
                :min-value="minValue"
                :value="count"
                @change="onCountChange"
                :auto-focus="true"
              />
            </f7-list-item>
          </f7-list>
        </f7-card-content>
      </f7-card>
      <f7-block-title class="margin-top" v-if="article">
        ({{ article.code }}) {{ article.name }}
      </f7-block-title>
      <f7-list media-list inset :class="{ 'skeleton-text': !article }">
        <f7-list-item header="Expected delivered quantity">
          {{ expectedQuantity }}
        </f7-list-item>
        <f7-list-item header="Primary barcode">
          <string-label :value="article.primaryBarcode" />
        </f7-list-item>
        <f7-list-item header="Weight (gr.)">
          <template #title>
            <string-label :value="article.weight" />
          </template>
        </f7-list-item>
        <f7-list-item
          header="Classifications"
          v-if="article.classifications && article.classifications.length > 0"
        >
          <template #title>
            <classifications-label :items="article.classifications" />
          </template>
        </f7-list-item>
        <f7-list-button @click="onArticleDetailOpen">Show details</f7-list-button>
      </f7-list>

      <f7-block-header class="margin-top" v-if="hasGoodsReceiptLineLocations">
        Already placed on:
      </f7-block-header>
      <f7-card
        :class="{ 'skeleton-text': !hasGoodsReceiptLineLocations }"
        v-if="hasGoodsReceiptLineLocations"
      >
        <f7-card-content>
          <f7-list media-list>
            <f7-list-item
              v-for="goodsReceiptLineLocation in goodsReceiptLineLocations"
              :key="goodsReceiptLineLocation.id"
              swipeout
            >
              <template #media>
                <f7-badge>
                  {{ goodsReceiptLineLocation.amount }}
                </f7-badge>
              </template>
              <template #title v-if="goodsReceiptLineLocation.location">
                <string-label :value="goodsReceiptLineLocation.location.name" />
              </template>
              <f7-swipeout-actions left>
                <f7-swipeout-button
                  color="dimass"
                  overswipe
                  close
                  @click="onLocationClick(goodsReceiptLine, goodsReceiptLineLocation)"
                >
                  <f7-icon material="edit" class="text-color-white" />
                </f7-swipeout-button>
              </f7-swipeout-actions>
              <f7-swipeout-actions right>
                <f7-swipeout-button
                  color="red"
                  overswipe
                  close
                  @click="onRemoveLocationClick(goodsReceiptLine, goodsReceiptLineLocation)"
                >
                  <f7-icon material="delete" class="text-color-white" />
                </f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </f7-card-content>
      </f7-card>
      <f7-fab v-if="goodsReceiptLine" position="center-bottom" text="Confirm" @click="onConfirm">
        <f7-icon material="done" />
      </f7-fab>
    </f7-page-content>
  </f7-page>
  <detail-popup
    v-if="article && article.articleBarcodes"
    :article="article"
    :is-loading="isLoading"
    show-stock-card
    :popup-opened="articleDetailPopupOpened"
    @popup-closed="onArticleDetailClosed"
  />
</template>
<script lang="ts" setup>
import InputNumber from '@components/form/InputNumber.vue'
import ClassificationsLabel from '@components/label/ClassificationsLabel.vue'
import StringLabel from '@components/label/StringLabel.vue'
import Navigation from '@components/AppNavigation.vue'
import useArticle from '@composables/useArticle'
import useGoodsReceipt from '@composables/useGoodsReceipt'
import useProjectInbound from '@composables/useProjectInbound'
import { IArticle } from '@graphql/article/types'
import {
  IGoodsReceipt,
  IGoodsReceiptLine,
  IGoodsReceiptLineLocation
} from '@graphql/goodsReceipt/types'
import { IArticleLocation, ILocation } from '@graphql/location/types'
import { TypeName } from '@graphql/search/types'
import { SearchStrategy } from '@services/search/search'
import { toast } from '@services/toast'
import { generateInboundItemId } from '@store/modules/projects/inbound/mutations'
import { IInboundProjectItem } from '@store/modules/projects/inbound/types'
import { f7 } from 'framework7-vue'
import { computed, onBeforeMount, ref } from 'vue'
import DetailPopup from '@components/Article/DetailPopup.vue'
import useLoading from '@composables/useLoading'

defineProps<{
  item: IGoodsReceiptLine
  companyId: ID
  warehouseId: ID
}>()

const count = ref<number | string>('')
const minValue = ref<number>(1)
const article = ref<IArticle | undefined>()
const articleDetailPopupOpened = ref(false)

const { goodsEntries, activeGoodsEntry, removeGoodsEntry, setActiveGoodsEntry } =
  useProjectInbound()
const { goodsReceiptLine, goodsEnteredLocations, goodsReceiptLineLocations } = useGoodsReceipt()
const { getAllArticleLocations, getArticle } = useArticle()
const { isLoading, withAsyncLoading } = useLoading()

const expectedQuantity = computed<number>(() => {
  if (!goodsReceiptLine.value) {
    return 0
  }
  return goodsReceiptLine.value.amountExpected
})

const hasGoodsReceiptLineLocations = computed<boolean>(
  () => goodsReceiptLineLocations.value.length > 0
)

onBeforeMount(async () => {
  if (activeGoodsEntry.value && activeGoodsEntry.value.quantity) {
    onCountChange(activeGoodsEntry.value.quantity)
  }
  if (goodsReceiptLine.value) {
    article.value = goodsReceiptLine.value?.purchaseOrderLine.article
  }
})

const onConfirm = async () => {
  if (count.value < minValue.value) {
    await toast.error(`Value should be greater or equal than ${minValue.value}`).open()
    return
  }

  const inboundProject = await createInboundProjectItem()
  const articleLocations = (article.value as IArticle).articleLocation.map(
    (al: IArticleLocation) => al.location
  )
  const locations = articleLocations.concat(goodsEnteredLocations.value)

  return handleAction(article.value as IArticle, inboundProject, locations)
}

const onLocationClick = (
  goodsReceiptLine: IGoodsReceiptLine,
  goodsReceiptLineLocation: IGoodsReceiptLineLocation
) => {
  f7.dialog.confirm(
    'Are you sure you want to change the location and/or quantity for the selected item?',
    () => onLocationConfirm(goodsReceiptLine, goodsReceiptLineLocation)
  )
}

const onLocationConfirm = async (
  goodsReceiptLine: IGoodsReceiptLine,
  goodsReceiptLineLocation: IGoodsReceiptLineLocation
) => {
  if (count.value < minValue.value) {
    await toast.error(`Value should be greater or equal than ${minValue.value}`).open()
    return
  }

  const scanInput = [goodsReceiptLineLocation.location]
  const inboundProject = await createInboundProjectItem()
  inboundProject.originalLocation = goodsReceiptLineLocation.location

  await handleAction(goodsReceiptLine.purchaseOrderLine.article, inboundProject, scanInput)
}

const onRemoveLocationClick = async (
  goodsReceiptLine: IGoodsReceiptLine,
  goodsReceiptLineLocation: IGoodsReceiptLineLocation
) => {
  f7.dialog.confirm(
    'Are you sure that you want to remove this goods entry?',
    async () => await onRemoveLocationConfirm(goodsReceiptLine, goodsReceiptLineLocation)
  )
}

const onRemoveLocationConfirm = async (
  goodsReceiptLine: IGoodsReceiptLine,
  goodsReceiptLineLocation: IGoodsReceiptLineLocation
) => {
  try {
    await removeGoodsEntry({
      goodsReceiptLineId: goodsReceiptLine.id,
      goodsReceiptLineLocationId: goodsReceiptLineLocation.id
    })

    goodsReceiptLine.goodsReceiptLineLocation.slice(0).forEach((l: IGoodsReceiptLineLocation) => {
      if (l.id === goodsReceiptLineLocation.id) {
        const index = goodsReceiptLine.goodsReceiptLineLocation.indexOf(goodsReceiptLineLocation)
        goodsReceiptLine.goodsReceiptLineLocation.splice(index, 1)
      }
    })

    toast.success(`Goods entry for ${goodsReceiptLineLocation.location.name} deleted`).open()
  } catch (e: any) {
    toast.error(e.message).open()
  }
}

const handleAction = (
  article: IArticle,
  inboundProject: IInboundProjectItem,
  locations: ILocation[]
) => {
  if (article.batchRegistration) {
    return f7.views.main.router.navigate('/goods-entry-article-batch/', {
      props: {
        item: inboundProject
      }
    })
  }

  requestScanInput(inboundProject, locations, () => getAllArticleLocations(article))
}
// eslint-disable-next-line @typescript-eslint/ban-types
const requestScanInput = (
  inboundProject: IInboundProjectItem,
  locations: ILocation[],
  callback: Function | undefined
) => {
  f7.views.main.router.navigate('/goods-entry-location-search/', {
    props: {
      items: locations,
      searchType: TypeName.Location,
      searchStrategy: SearchStrategy.ScanInput,
      successUrl: '/goods-entry-location/',
      successProps: { item: inboundProject },
      itemsCallback: callback
    }
  })
}

const createInboundProjectItem = async () => {
  const goodsEntry: IInboundProjectItem = {
    id: generateInboundItemId(
      goodsEntries.value as IGoodsReceipt,
      goodsReceiptLine.value as IGoodsReceiptLine
    ),
    goodsReceiptLine: goodsReceiptLine.value as IGoodsReceiptLine,
    quantity: Number(count.value),
    location: null
  }

  if (activeGoodsEntry.value && activeGoodsEntry.value.weight) {
    goodsEntry.weight = activeGoodsEntry.value.weight
  }

  await setActiveGoodsEntry(goodsEntry)

  return goodsEntry
}

const onCountChange = (value: number | Event) => {
  if (value instanceof Event) {
    //@ts-ignore
    value = Number(value.target.value)
  }
  count.value = value
}

const onArticleDetailOpen = async () => {
  await withAsyncLoading(async () => {
    article.value = await getArticle({ id: article.value.id })
    articleDetailPopupOpened.value = true
  })
}

const onArticleDetailClosed = async () => {
  articleDetailPopupOpened.value = false
}
</script>
<style scoped lang="less">
.fab-location-suggest i {
  color: white !important;
}
</style>
