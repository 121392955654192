import purchaseOrderApi from '@graphql/purchase-order'
import { IPurchaseOrderState } from '@store/modules/purchase-order/types'
import { IRootState } from '@store/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<IPurchaseOrderState, IRootState> = {
  async getPurchaseOrderById(
    { commit }: ActionContext<IPurchaseOrderState, IRootState>,
    id: number
  ) {
    const response = await purchaseOrderApi.getPurchaseOrder(id)

    commit('setActivePurchaseOrder', response.data.data.purchaseOrder)

    return response
  },

  async getOpenPurchaseOrders({ commit }: ActionContext<IPurchaseOrderState, IRootState>) {
    const response = await purchaseOrderApi.getPurchaseOrdersToHandle()

    commit('setOpenPurchaseOrders', response.data.data.purchaseOrdersTillState)
  }
}

export default actions
