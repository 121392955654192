import actions from '@store/modules/settings/actions'
import getters from '@store/modules/settings/getters'
import mutations from '@store/modules/settings/mutations'
import { ISettingState } from '@store/modules/settings/types'
import { IRootState } from '@store/types'
import { Module } from 'vuex'

const state: ISettingState = {
  deviceId: null
}

const settings: Module<ISettingState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default settings
