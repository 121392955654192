<template>
  <f7-card :class="{ 'skeleton-text': isLoading }">
    <f7-card-content>
      <f7-list>
        <f7-list-item
          v-for="sortCompartment in sortCompartments"
          :key="sortCompartment.compartmentReference.value"
          @click="onShowDetail(sortCompartment)"
        >
          <template #title>
            {{ sortCompartment.compartmentReference.value }}
          </template>
          <template #after-title>
            <f7-badge>{{ sortedCount(sortCompartment) }}/{{ itemCount(sortCompartment) }}</f7-badge>
          </template>
        </f7-list-item>
      </f7-list>
    </f7-card-content>
  </f7-card>
  <f7-popup :opened="popupOpened" @popup:closed="onPopupClosed" tablet-fullscreen>
    <default-popup-header
      v-if="activeSortCompartment"
      :title="activeSortCompartment.compartmentReference.value"
    />
    <template v-if="activeSortCompartment">
      <sort-items-tab :is-loading="false" :items="activeSortCompartment.sortableItems" />
    </template>
  </f7-popup>
</template>
<script lang="ts" setup>
import { ISortCompartment } from '@graphql/sort/types'
import { computed, ref } from 'vue'
import DefaultPopupHeader from '@components/DefaultPopupHeader.vue'
import SortItemsTab from '@components/sorting/SortItemsTab.vue'
import useLoading from '@composables/useLoading'

defineProps<{
  sortCompartments: ISortCompartment[]
}>()

const activeSortCompartment = ref<ISortCompartment | undefined>()
const sortedCount = (sortableCompartment: ISortCompartment) => {
  return sortableCompartment.sortableItems
    .map((sortableItem) => sortableItem.quantitySorted)
    .reduce((total, current) => total + current)
}

const itemCount = (sortableCompartment: ISortCompartment) => {
  return sortableCompartment.sortableItems
    .map((sortableItem) => sortableItem.quantity)
    .reduce((total, current) => total + current)
}

const popupOpened = computed<boolean>(() => !!activeSortCompartment.value)

const { isLoading } = useLoading()

const onShowDetail = async (sortCompartment: ISortCompartment) => {
  activeSortCompartment.value = sortCompartment
}

const onPopupClosed = async () => {
  activeSortCompartment.value = undefined
}
</script>
