<template>
  <f7-page :page-content="false">
    <navigation />
    <f7-page-content>
      <f7-card>
        <f7-card-content>
          <f7-list media-list>
            <f7-list-item class="bg-color-yellow no-margin" media-list>
              This article requires the registration of the THT batch.
            </f7-list-item>
            <f7-list-input
              type="text"
              required
              label="Batch reference"
              :value="reference"
              @input="reference = $event.target.value"
            />
            <f7-list-input
              type="datepicker"
              :calendar-params="{ minDate: minDate, dateFormat: 'dd/mm/yyyy', closeOnSelect: true }"
              label="Expiration date (DD/MM/YYYY)"
              :value="expireDate"
              @calendar:change="setExpireDate"
            />
          </f7-list>
        </f7-card-content>
      </f7-card>
      <f7-block-title>Article details</f7-block-title>
      <f7-list media-list inset>
        <f7-list-item header="Description">
          <string-label :value="lineArticle.name" />
        </f7-list-item>
        <f7-list-item header="Article code">
          <string-label :value="lineArticle.code" />
        </f7-list-item>
        <f7-list-item header="Primary barcode">
          <string-label :value="lineArticle.primaryBarcode" />
        </f7-list-item>
      </f7-list>
      <f7-fab position="center-bottom" @click="onConfirm">
        <f7-icon material="done" />
      </f7-fab>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import StringLabel from '@components/label/StringLabel.vue'
import Navigation from '@components/AppNavigation.vue'
import useArticle from '@composables/useArticle'
import useProjectInbound from '@composables/useProjectInbound'
import { IArticle } from '@graphql/article/types'
import { IArticleLocation, ILocation } from '@graphql/location/types'
import { TypeName } from '@graphql/search/types'
import { SearchStrategy } from '@services/search/search'
import { toast } from '@services/toast'
import { IInboundProjectItem } from '@store/modules/projects/inbound/types'
import { f7 } from 'framework7-vue'
import { computed, onBeforeMount, ref } from 'vue'

const props = defineProps<{
  item: IInboundProjectItem
}>()

const reference = ref<string | null>(null)
const expireDate = ref<Date[]>([])
const minDate = ref<Date>(new Date())

const { activeGoodsEntry, setActiveGoodsEntry } = useProjectInbound()
const { getAllArticleLocations } = useArticle()

const goodsEntry = computed<IInboundProjectItem | undefined>(() =>
  props.item ? props.item : activeGoodsEntry.value
)
const lineArticle = computed<IArticle>(
  () => (goodsEntry.value as IInboundProjectItem).goodsReceiptLine.purchaseOrderLine.article
)

const setExpireDate = (value: any) => {
  expireDate.value = value
}

const onConfirm = async () => {
  if (!reference.value) {
    toast.error('Reference is required').open()
    return
  }

  if (lineArticle.value.perishableType && expireDate.value === null) {
    toast.error('Expire date is required').open()
    return
  }

  const locations = lineArticle.value.articleLocation.map((al: IArticleLocation) => al.location)
  if (!goodsEntry.value) {
    return
  }

  const inboundProject: IInboundProjectItem = {
    id: (goodsEntry.value as IInboundProjectItem).id,
    goodsReceiptLine: goodsEntry.value.goodsReceiptLine,
    location: null,
    quantity: goodsEntry.value.quantity,
    articleBatch: {
      reference: reference.value,
      expireDate: expireDate.value
    }
  }

  if (activeGoodsEntry.value && activeGoodsEntry.value.weight) {
    inboundProject.weight = activeGoodsEntry.value.weight
  }

  await setActiveGoodsEntry(inboundProject)

  await requestScanInput(inboundProject, locations, () => getAllArticleLocations(lineArticle.value))
}
// eslint-disable-next-line @typescript-eslint/ban-types
const requestScanInput = (
  inboundProject: IInboundProjectItem,
  locations: ILocation[],
  callback: Function
) => {
  f7.views.main.router.navigate('/goods-entry-location-search/', {
    props: {
      items: locations,
      searchType: TypeName.Location,
      searchStrategy: SearchStrategy.ScanInput,
      successUrl: '/goods-entry-location/',
      successProps: { item: inboundProject },
      itemsCallback: callback
    }
  })
}

onBeforeMount(async () => {
  if (goodsEntry.value && goodsEntry.value.articleBatch) {
    reference.value = goodsEntry.value.articleBatch.reference
    expireDate.value = goodsEntry.value.articleBatch.expireDate
  }
})
</script>
