import { IGoodsReceiptState } from '@store/modules/goods-receipt/types'
import { IRootState } from '@store/types'
import { GetterTree } from 'vuex'

const getters: GetterTree<IGoodsReceiptState, IRootState> = {
  activeGoodsReceipt: (state) => state.activeGoodsReceipt,
  openGoodsReceipts: (state) => state.openGoodsReceipts,
  activeGoodsReceiptLine: (state) => state.activeGoodsReceiptLine
}

export default getters
