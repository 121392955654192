import { IVoter, VoterResult } from '@services/security/types'
import { PackAccessVoter } from '@services/security/voter/packAccessVoter'
import { PermissionVoter } from '@services/security/voter/permissionVoter'
import { IUser } from '@store/modules/auth/types'

export class AccessDecisionManger {
  private static _instance: AccessDecisionManger | undefined

  private voters: IVoter[] = []

  public addVoter(voter: IVoter): void {
    this.voters.push(voter)
  }

  public vote(user: IUser, attributes: string | string[], subject?: unknown): boolean {
    let denies = 0

    const voterAttributes = this.ensureIsArray(attributes)

    if (!user.permissions) {
      user.permissions = []
    }

    for (let index = 0; index < this.voters.length; index++) {
      if (!this.voters[index].supports(voterAttributes, subject)) {
        continue
      }

      const result = this.voters[index].vote(user, voterAttributes, subject)

      if (result === VoterResult.ACCESS_GRANTED) {
        return true
      }

      if (result === VoterResult.ACCESS_DENIED) {
        ++denies
      }
    }

    return denies === 0
  }

  private ensureIsArray(attributes: string | string[]): string[] {
    if (!Array.isArray(attributes)) {
      return new Array(attributes)
    }

    return attributes
  }

  public static getInstance(): AccessDecisionManger {
    if (!this._instance) {
      this._instance = new AccessDecisionManger()
    }

    return this._instance
  }
}

const accessDecisionManager = AccessDecisionManger.getInstance()
accessDecisionManager.addVoter(new PackAccessVoter())
accessDecisionManager.addVoter(new PermissionVoter())

export default accessDecisionManager
