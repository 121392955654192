import scannerConfig from '@config/scanner'
import { cleanScanInput } from '@/utilities/string'

export default class ScanInput {
  private static instance: ScanInput | null = null
  private enabled = true
  private capturing = false
  private buffer: string[] = []
  private query = ''
  private inputCallback?: (query: string) => void = undefined

  public enable() {
    this.enabled = true
  }

  public disable() {
    this.enabled = false
  }

  constructor(
    private triggerKeys: string[],
    private submitKeys: string[],
    private promptKeys: string[],
    private authTriggerKeys: string[],
    private timeout: number | undefined
  ) {}

  public static getInstance(
    triggerKeys: string[],
    submitKeys: string[],
    promptKeys: string[],
    authTriggerKeys: string[],
    timeout: number | undefined
  ) {
    if (!this.instance) {
      this.instance = new ScanInput(
        [...triggerKeys, ...authTriggerKeys],
        submitKeys,
        promptKeys,
        authTriggerKeys,
        timeout
      )
    }

    return this.instance
  }

  public onKeyboardEvent(event: KeyboardEvent) {
    if (!this.enabled) {
      return
    }

    const isTriggerKey = this.triggerKeys.includes(event.key)
    const isAuthTriggerKey = this.authTriggerKeys.includes(event.key)
    let isSubmitKey = this.submitKeys.includes(event.key)

    if (isTriggerKey) {
      clearTimeout(this.timeout)
      this.capturing = true
    }

    if (this.promptKeys.includes(event.key)) {
      const value = prompt('Query?')
      if (value) {
        this.capturing = true
        this.buffer = value.split('')
        isSubmitKey = true
      }
    }

    if (this.capturing) {
      if (isAuthTriggerKey || (!isTriggerKey && !isSubmitKey)) {
        this.buffer.push(event.key)
      }

      if (isSubmitKey) {
        const query = cleanScanInput(this.buffer.join(''), event.key)

        if (this.inputCallback) {
          this.inputCallback(query)
        }

        this.reset()
      }

      this.timeout = setTimeout(() => {
        this.reset()
      }, scannerConfig.timeout)
    }
  }

  onInput(callback: (query: string) => void) {
    this.inputCallback = callback
  }

  reset() {
    this.buffer = []
    this.capturing = false
    this.query = ''
  }
}

export const scanInput = ScanInput.getInstance(
  scannerConfig.triggerKeys,
  scannerConfig.submitKeys,
  scannerConfig.promptKeys,
  scannerConfig.authTriggerKeys,
  scannerConfig.timeout
)

export type SyncEventCallback = (event: IEvent) => void

export interface IEvent {
  readonly name: string
  readonly payload?: unknown

  stopPropagation(): void
  isStopPropagation(): boolean
}

export class Event implements IEvent {
  private _stopPropagation = false

  constructor(
    public readonly name: string,
    public readonly payload?: unknown
  ) {}

  stopPropagation(): void {
    this._stopPropagation = true
  }

  isStopPropagation(): boolean {
    return this._stopPropagation
  }
}

export class SynchronousEventBus {
  private _subscribers: Map<string, Array<SyncEventCallback>> = new Map()

  private static _instance?: SynchronousEventBus = undefined

  public emit(event: IEvent) {
    const eventSubscribers = this.getSubscribers(event.name)

    for (let i = 0; i < eventSubscribers.length; i++) {
      try {
        eventSubscribers[i](event)
        if (event.isStopPropagation()) {
          break
        }
      } catch (e) {
        break
      }
    }
  }

  public on(event: string, callback: SyncEventCallback, prepend = false) {
    const subscribers = this.getSubscribers(event)
    if (subscribers.indexOf(callback) < 0) {
      if (prepend) {
        subscribers.unshift(callback)
      } else {
        subscribers.push(callback)
      }
    }

    this._subscribers.set(event, subscribers)

    return () => {
      const index = this.getSubscribers(event).indexOf(callback)
      if (index > -1) {
        this.getSubscribers(event).splice(index, 1)
      }
    }
  }

  private getSubscribers(event: string) {
    return this._subscribers.get(event) ?? []
  }

  public off(event: string) {
    this._subscribers.set(event, [])
  }

  public static instance() {
    if (!this._instance) {
      this._instance = new SynchronousEventBus()
    }

    return this._instance
  }
}

export const eventBus = SynchronousEventBus.instance()
