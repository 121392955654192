import authApi from '@graphql/auth'
import { IAuthenticationResult, IAuthenticatorAuthenticationRequest } from '@graphql/auth/types'
import { ActionContext, ActionTree } from 'vuex'
import { IRootState } from '../../types'
import { IAuthState, IUsernamePasswordLoginContext } from './types'

const actions: ActionTree<IAuthState, IRootState> = {
  async login(
    { commit }: ActionContext<IAuthState, IRootState>,
    payload: IUsernamePasswordLoginContext
  ): Promise<boolean> {
    const result = await authApi.login(payload.username, payload.password)

    if (result === null || result.authToken === null) {
      return false
    }

    await commit('updateToken', result.authToken)
    await commit('updateUser', result.user)

    return true
  },

  async tokenLogin(
    { commit }: ActionContext<IAuthState, IRootState>,
    payload: string
  ): Promise<IAuthenticationResult | null> {
    const result = await authApi.tokenLogin(payload)

    if (result && result.authToken) {
      await commit('updateToken', result.authToken)
      await commit('updateUser', result.user)
    }

    return result
  },

  async authenticatorLogin(
    { commit }: ActionContext<IAuthState, IRootState>,
    request: IAuthenticatorAuthenticationRequest
  ): Promise<IAuthenticationResult | null> {
    const result = await authApi.authenticatorLogin(request)

    if (result && result.authToken) {
      await commit('updateToken', result.authToken)
      await commit('updateUser', result.user)
    }

    return result
  },

  async logout({ commit }: ActionContext<IAuthState, IRootState>) {
    await commit('removeUser')
    await commit('removeToken')
  }
}

export default actions
