<template>
  <f7-page :page-content="false">
    <navigation />
    <f7-page-content>
      <f7-block-title :class="{ 'skeleton-text': isLoading }" class="margin-top">
        ({{ goodsEntry?.goodsReceiptLine.purchaseOrderLine.article.code }})
        {{ goodsEntry?.goodsReceiptLine.purchaseOrderLine.article.name }}
      </f7-block-title>
      <f7-card :class="{ 'skeleton-text': isLoading }">
        <f7-card-content>
          <f7-list media-list>
            <f7-list-item
              v-if="HasExistingGoodsEntriesCurrentLocation"
              class="bg-color-yellow no-margin"
              media-list
            >
              <div v-html="getExistingLocationQuantityMessage(goodsEntry, location)"></div>
            </f7-list-item>
            <f7-list-item
              v-if="isExpectedQuantityExceeded"
              class="bg-color-yellow no-margin"
              media-list
            >
              <div v-html="getExceededQuantityMessage(goodsEntry, location)"></div>
            </f7-list-item>
            <f7-list-item header="Quantity">
              <template #title>
                <string-label :value="goodsEntry.quantity" fallback-value="0" />
              </template>
            </f7-list-item>
            <f7-list-item header="Location">
              <template #title>
                <string-label :value="location.name" />
              </template>
            </f7-list-item>
            <f7-list-item v-if="goodsEntry.weight" header="Weight (gr.)">
              <template #title>
                <string-label :value="goodsEntry.weight" />
              </template>
            </f7-list-item>
          </f7-list>
        </f7-card-content>
      </f7-card>
      <f7-block-title v-if="articleBatch" :class="{ 'skeleton-text': isLoading }">
        Article Batch
      </f7-block-title>
      <f7-card v-if="articleBatch" :class="{ 'skeleton-text': isLoading }">
        <f7-card-content>
          <f7-list media-list>
            <f7-list-item header="Reference">
              <string-label :value="articleBatch.reference" />
            </f7-list-item>
            <f7-list-item v-if="articleBatch.expireDate" header="Expire date">
              <date-time-label :value="articleBatch.expireDate[0]" format="dd-MM-yyyy" />
            </f7-list-item>
          </f7-list>
        </f7-card-content>
      </f7-card>
      <f7-fab position="center-bottom" v-if="isLoading">
        <f7-preloader />
      </f7-fab>
      <f7-fab position="center-bottom" v-else @click="onConfirm">
        <f7-icon material="done" />
      </f7-fab>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import DateTimeLabel from '@components/label/DateTime.vue'
import StringLabel from '@components/label/StringLabel.vue'
import Navigation from '@components/AppNavigation.vue'
import useLoading from '@composables/useLoading'
import useLocation from '@composables/useLocation'
import useProjectInbound from '@composables/useProjectInbound'
import { IGoodsEntryRequest, IGoodsReceiptLineLocation } from '@graphql/goodsReceipt/types'
import { ILocation } from '@graphql/location/types'
import { notification } from '@services/notification'
import { toast } from '@services/toast'
import { IInboundProjectItem } from '@store/modules/projects/inbound/types'
import { f7 } from 'framework7-vue'
import { DateTime } from 'luxon'
import { computed, onMounted } from 'vue'
import { randomString } from '@/utilities/string'

const props = defineProps<{
  item: IInboundProjectItem
}>()

const signature = randomString(32)

const { activeGoodsEntry, createGoodsEntry, setActiveGoodsEntry } = useProjectInbound()
const { location } = useLocation()
const { isLoading } = useLoading()

const goodsEntry = computed<IInboundProjectItem | undefined>(() =>
  props.item ? props.item : activeGoodsEntry.value
)
const articleBatch = computed(() => goodsEntry.value?.articleBatch)
const HasExistingGoodsEntriesCurrentLocation = computed<boolean>(() => {
  if (!goodsEntry.value || !location.value) {
    return false
  }
  return hasGoodsEntry(goodsEntry.value, location.value) && !goodsEntry.value.originalLocation
})

const goodsReceiptLineLocations = computed<IGoodsReceiptLineLocation[]>(() => {
  if (!goodsEntry.value) {
    return []
  }
  return goodsEntry.value.goodsReceiptLine.goodsReceiptLineLocation
})
const hasExistingGoodsEntries = computed<boolean>(() => goodsReceiptLineLocations.value.length > 0)

const handledQuantity = computed<number>(() => {
  if (goodsReceiptLineLocations.value.length === 0) {
    return 0
  }

  const amountMap = goodsReceiptLineLocations.value.map((grll: IGoodsReceiptLineLocation) => {
    if (originalLocation.value && originalLocation.value.id === grll.location.id) {
      return 0
    }

    return Number(grll.amount)
  })

  return amountMap.reduce((total: number, current: number) => total + current)
})

const isExpectedQuantityExceeded = computed<boolean>(() => {
  if (!goodsEntry.value) {
    return false
  }

  return totalQuantity.value > expectedQuantity.value
})

const expectedQuantity = computed<number>(() => {
  if (!goodsEntry.value) {
    return 0
  }

  return Number(goodsEntry.value.goodsReceiptLine.amountExpected)
})

const currentQuantity = computed<number>(() => {
  if (!goodsEntry.value) {
    return 0
  }

  return Number(goodsEntry.value.quantity)
})

const totalQuantity = computed<number>(() => {
  return Number(handledQuantity.value + currentQuantity.value)
})

const originalLocation = computed<ILocation | undefined>(() => {
  if (!goodsEntry.value) {
    return
  }

  return goodsEntry.value.originalLocation
})

onMounted(() => {
  isLoading.value = false
})

const getExistingGoodsEntry = (
  goodsEntry: IInboundProjectItem,
  location: ILocation
): IGoodsReceiptLineLocation[] => {
  let existingGoodsEntry = goodsEntry.goodsReceiptLine.goodsReceiptLineLocation.filter(
    (lineLocation) => lineLocation.location && lineLocation.location.id === location.id
  )

  const article = goodsEntry.goodsReceiptLine.purchaseOrderLine.article

  if (article.batchRegistration) {
    existingGoodsEntry = existingGoodsEntry.filter(
      (grll) => grll.articleBatch.reference === goodsEntry.articleBatch?.reference
    )

    if (article.perishableType) {
      existingGoodsEntry = existingGoodsEntry.filter((grll) => {
        const a = DateTime.fromFormat(grll.articleBatch.expireDate, 'yyyy-MM-dd HH:mm:ss').startOf(
          'day'
        )
        const b = DateTime.fromJSDate(goodsEntry.articleBatch?.expireDate[0])

        return a.diff(b, ['days']).days === 0
      })
    }
  }

  return existingGoodsEntry
}

const getExistingGoodsEntryAmount = (goodsEntry: IInboundProjectItem, location: ILocation) => {
  if (!hasGoodsEntry(goodsEntry, location)) {
    return 0
  }

  return Number(getExistingGoodsEntry(goodsEntry, location)[0].amount)
}

const hasGoodsEntry = (goodsEntry: IInboundProjectItem, location: ILocation) => {
  return getExistingGoodsEntry(goodsEntry, location).length > 0
}

const getExistingLocationQuantityMessage = (
  goodsEntry: IInboundProjectItem,
  location: ILocation
) => {
  const existingAmount = getExistingGoodsEntryAmount(goodsEntry, location)
  const currentQuantity = Number(goodsEntry.quantity)
  const exceededAmount = currentQuantity + existingAmount

  return `There's already a goods entry for this location with quantity of (${existingAmount})
            <u>proceeding will result in a total quantity of (${exceededAmount}) for this location.</u>`
}

const getExceededQuantityMessage = () => {
  let msg = `The current entry (${currentQuantity.value}) `

  if (handledQuantity.value > 0 && hasExistingGoodsEntries.value) {
    msg += `+ previous entries for this article (${handledQuantity.value}) (total = ${totalQuantity.value}) `
  }

  msg += `exceeds the expected quantity (${expectedQuantity.value}) `

  return msg
}

const onConfirm = async () => {
  if (HasExistingGoodsEntriesCurrentLocation.value) {
    // @ts-ignore
    if (!confirm(getExistingLocationQuantityMessage(goodsEntry.value, location.value))) {
      return
    }
  }

  if (isExpectedQuantityExceeded.value) {
    if (!confirm(getExceededQuantityMessage())) {
      return
    }
  }

  const goodsEntryRequest: IGoodsEntryRequest = {
    signature,
    goodsReceiptLineId: goodsEntry.value.goodsReceiptLine.id,
    locationId: location.value.id,
    quantity: currentQuantity.value
  }

  if (goodsEntry.value && goodsEntry.value.originalLocation) {
    goodsEntryRequest.originalLocationId = goodsEntry.value.originalLocation.id
  }

  if (articleBatch.value) {
    goodsEntryRequest.articleBatchReference = articleBatch.value.reference
    goodsEntryRequest.articleBatchExpireDate = articleBatch.value.expireDate
      ? DateTime.fromJSDate(articleBatch.value.expireDate[0]).toISO()
      : null
  }

  if (goodsEntry.value && goodsEntry.value.weight) {
    goodsEntryRequest.weight = Number(goodsEntry.value.weight)
  }

  try {
    isLoading.value = true
    await createGoodsEntry(goodsEntryRequest)

    notification.success({ message: 'Goods entry saved.', closeTime: 5000 }).open()

    await setActiveGoodsEntry(undefined)
    await f7.views.main.router.navigate(
      `/goodsentry/${props.item.goodsReceiptLine.goodsReceipt.id}/`,
      {
        clearPreviousHistory: true
      }
    )
  } catch (e: any) {
    await toast.error(e.message).open()
  } finally {
    isLoading.value = false
  }
}
</script>
