<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="$emit('popup-closed', true)"
    :tablet-fullscreen="true"
    class="goods-entry-search-results-popup"
  >
    <f7-page>
      <default-popup-header title="Results" :popup-close="false" />
      <search-result-list
        :items="searchResults"
        @result="onSearchResult"
        key="goods-entry-search-result-list"
      />
    </f7-page>
  </f7-popup>
</template>
<script setup lang="ts">
import SearchResultList from '@components/search/ResultList.vue'
import DefaultPopupHeader from '@components/DefaultPopupHeader.vue'
import { UnionTypeSearchResult } from '@store/modules/search/types'

withDefaults(
  defineProps<{
    popupOpened: boolean
    searchResults: UnionTypeSearchResult[]
  }>(),
  {
    popupOpened: false,
    searchResults: () => []
  }
)

const emit = defineEmits<{
  (e: 'popup-closed', result: boolean)
  (e: 'search-result', result: UnionTypeSearchResult)
}>()

const onSearchResult = async (result: UnionTypeSearchResult) => {
  await emit('search-result', result)
}
</script>
