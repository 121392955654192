import { IParser, ParserResult } from '@services/search/parser'

export class DefaultParser implements IParser {
  parse(query: string): ParserResult {
    return new ParserResult(query, 'DEFAULT')
  }

  supports(): boolean {
    return true
  }
}
