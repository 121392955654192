import { ISearch } from '@graphql/search/types'
import CountSearchStrategy from '@services/search/strategy/count-strategy'
import DefaultSearchStrategy from '@services/search/strategy/default-strategy'
import DefaultWithoutRoutingSearchStrategy from '@services/search/strategy/default-without-routing-search-strategy'
import PackingControlStrategy from '@services/search/strategy/packing-control'
import PickLineSearchStrategy from '@services/search/strategy/pick-item-strategy'
import ScanInputStrategy from '@services/search/strategy/scan-input-strategy'
import PackingControlShipmentLineSearchStrategy from '@services/search/strategy/shipment-line-strategy'
import { SearchResults, UnionTypeSearchResult } from '@store/modules/search/types'

export interface ISearchFilter {
  key: string
  operator: string
  value: string
}

export type SingleResultCallback = (item: UnionTypeSearchResult) => void
export type PluralResultCallback = (items: UnionTypeSearchResult[]) => void
export type NoResultCallback = (query: string) => void

export interface SearchOptions {
  singularCallback?: SingleResultCallback
  pluralCallback?: PluralResultCallback
  noResultCallback?: NoResultCallback
}

/**
 * @interface
 */
export interface ISearchStrategy {
  search(
    query: string,
    exact: boolean,
    type: string | null,
    filters: ISearchFilter[]
  ): Promise<ISearch>

  parseSearchResults(format: ISearch, searchType?: string): Promise<SearchResults>

  handleResults(query: string, results: SearchResults, options?: SearchOptions): any

  handleSingularResult(result: UnionTypeSearchResult, callback?: SingleResultCallback): any

  handlePluralResult(results: SearchResults, callback?: PluralResultCallback): any
}

export const enum SearchStrategy {
  Count = 'count',
  ScanInput = 'scan-input',
  Default = 'default',
  PackingControl = 'packing-control',
  PackingControlShipmentLine = 'packing-control-shipment-line',
  PickLine = 'pick-line',
  DefaultWithoutRouting = 'default-without-routing'
}

class SearchStrategyFactory {
  private static instance: SearchStrategyFactory
  private strategies: Map<string, ISearchStrategy> = new Map()

  /**
   * SearchStrategyFactory Singleton Instance
   */
  public static get getInstance(): SearchStrategyFactory {
    if (!this.instance) {
      this.instance = new this()
    }

    return this.instance
  }

  /**
   * Add a strategy.
   * @param name string
   * @param strategy ISearchStrategy
   * @return void
   */
  public addStrategy(name: string, strategy: ISearchStrategy): void {
    this.strategies.set(name, strategy)
  }

  /**
   * Get strategy by name
   * @param name string
   * @returns ISearchStrategy
   */
  public getStrategy(name: string = SearchStrategy.Default): ISearchStrategy {
    const strategy = this.strategies.get(name)

    if (!strategy) {
      throw new Error(`Search strategy "${name}" does not exist or is not registered!`)
    }

    return strategy
  }

  /**
   * Get all registered strategies
   * @returns IterableIterator<ISearchStrategy>
   */
  public getAll(): IterableIterator<ISearchStrategy> {
    return this.strategies.values()
  }

  /**
   * Remove a strategy
   * @param name string
   */
  public removeStrategy(name: string) {
    if (!this.strategies.has(name)) {
      throw new Error(`Search strategy "${name}" does not exist or is not registered!`)
    }

    return this.strategies.delete(name)
  }
}

const search = SearchStrategyFactory.getInstance
search.addStrategy(SearchStrategy.Default, new DefaultSearchStrategy())
search.addStrategy(SearchStrategy.Count, new CountSearchStrategy())
search.addStrategy(SearchStrategy.ScanInput, new ScanInputStrategy())
search.addStrategy(SearchStrategy.PackingControl, new PackingControlStrategy())
search.addStrategy(
  SearchStrategy.PackingControlShipmentLine,
  new PackingControlShipmentLineSearchStrategy()
)
search.addStrategy(SearchStrategy.PickLine, new PickLineSearchStrategy())
search.addStrategy(SearchStrategy.DefaultWithoutRouting, new DefaultWithoutRoutingSearchStrategy())

export default search
