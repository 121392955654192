import stockApi from '@graphql/stock'
import { IRootState } from '@store/types'
import { ActionContext, ActionTree } from 'vuex'
import { ICountItem, IProjectCountState } from './types'

const actions: ActionTree<IProjectCountState, IRootState> = {
  confirm({ rootGetters }: ActionContext<IProjectCountState, IRootState>) {
    const countItems = rootGetters['projects/count/items']
    const stockItems = countItems.map((item: ICountItem) => {
      return {
        articleId: item.articleID,
        locationId: item.locationID,
        qty: item.qty,
        countedAt: item.countedAt.toJSON()
      }
    })

    return stockApi.count(stockItems)
  }
}

export default actions
