import { IArticleBatch } from '@graphql/article/types'
import { ILocation, IWarehouse } from '@graphql/location/types'
import { IPurchaseOrder, IPurchaseOrderLine } from '@graphql/purchaseOrder/types'
import { IConnection, ID, IGraphQLResponse, INode } from '@graphql/types'
import { ISearchResult } from '@graphql/search/types'

export interface IGoodsReceipt extends ISearchResult {
  state: number
  stateString: string | null
  deliveredAt: number
  bookedAt: Date | null
  numColli: number
  numPallet: number
  numBlockPallet: number
  numContainer40Ft: number
  numContainer20Ft: number
  numContainer40FtHighCube: number
  numSkus: number
  numUniqueSkus: number
  externalId: string
  completionForced: boolean
  waybillNumber: string
  note: string
  createdAt: Date
  updatedAt: Date
  purchaseOrder: IPurchaseOrder
  goodsReceiptLine: IGoodsReceiptLineConnection
  goodsReceiptLineTodo: IGoodsReceiptLineConnection
  goodsReceiptLineDone: IGoodsReceiptLineConnection
  warehouse: IWarehouse
}

export const enum StateEnum {
  CREATED = 0,
  ASN_RECEIVED = 3,
  GOODS_RECEPTION = 4,
  GOODS_ENTRY = 10,
  READY_FOR_BOOKING = 15,
  BOOKED = 20
}

export interface IGoodsReceiptLine extends ISearchResult {
  amount: number
  amountExpected: number
  cancelled: number
  articleWeight: number
  goodsReceipt: IGoodsReceipt
  goodsReceiptLineLocation: IGoodsReceiptLineLocation[]
  purchaseOrderLine: IPurchaseOrderLine
  cancellationReason: ICancellationReason
}

export interface IGoodsReceiptLineLocation extends INode {
  id: ID
  amount: number
  location: ILocation
  goodsReceiptLine: IGoodsReceiptLine
  articleBatch: IArticleBatch
}

export interface ICancellationReason extends INode {
  id: ID
}

export interface IGoodsReceiptQueryContext {
  id: ID
  nextPageCursor?: number
  perPage?: number
  completed: boolean
}

export interface IGoodsEntryQueryContext {
  id: ID
  todoNextPageCursor?: number
  doneNextPageCursor?: number
  perPage?: number
  completed: boolean
}

export interface IGoodsEntryRequest {
  signature: string
  goodsReceiptLineId: ID
  locationId: ID
  originalLocationId?: ID
  quantity: number
  weight?: number
  articleBatchReference?: string
  articleBatchExpireDate?: Date | string | null
}

export interface IDeleteGoodsEntryRequest {
  goodsReceiptLineId: ID
  goodsReceiptLineLocationId: ID
}

export interface IBookReadyRequest {
  id: ID
}

export interface IStartGoodsEntryRequest {
  goodsReceiptId: ID
}

export type IGoodsReceiptLineConnection = IConnection<IGoodsReceiptLine>

export interface IGoodsReceiptResponse extends IGraphQLResponse {
  data: {
    goodsReceiptLine: Partial<IGoodsReceiptLine>
  }
}
