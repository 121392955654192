import { f7 } from 'framework7-vue'

export function browserNotification(
  body: string,
  title: string,
  badge = 1,
  closeButton = true
): void {
  const options: NotificationOptions = {
    body,
    tag: 'support_plaza_scan',
    icon: '/assets/img/logo.png',
    badge: badge.toString(),
    requireInteraction: closeButton
  }

  const notification = new NativeNotification(title, options)

  if (!notification.needsPermission) {
    notification.show()
  }

  NativeNotification.requestPermission().then((response: NotificationPermission) => {
    if (response === 'granted') {
      notification.show()
    }
  })
}

export interface INotificationOptions {
  icon: string
  message: string
  title: string
  button: boolean
  closeTime: number
}

export default class MaterialNotification {
  public info(options: Partial<INotificationOptions>): any {
    return f7.notification.create({
      icon: options.icon ?? '<i class="icon material-icons color-blue">info_outline</i>',
      title: options.title ?? 'Info',
      text: options.message,
      closeTimeout: options.closeTime ?? 3000,
      closeButton: options.button ?? false
    })
  }

  public success(options: Partial<INotificationOptions>): any {
    return f7.notification.create({
      icon: options.icon ?? '<i class="icon material-icons color-green">check_circle</i>',
      title: options.title ?? 'Success',
      text: options.message,
      closeTimeout: options.closeTime ?? 3000,
      closeButton: options.button ?? false
    })
  }

  public warning(options: Partial<INotificationOptions>): any {
    return f7.notification.create({
      icon: options.icon ?? '<i class="icon material-icons color-orange">warning</i>',
      title: options.title ?? 'Warning',
      text: options.message,
      closeTimeout: options.closeTime ?? 5000,
      closeButton: options.button ?? false
    })
  }

  public error(options: Partial<INotificationOptions>): any {
    return f7.notification.create({
      icon: options.icon ?? '<i class="icon material-icons color-red">error_outline</i>',
      title: options.title ?? 'Error!',
      text: options.message,
      closeTimeout: options.closeTime ?? 10000,
      closeButton: options.button ?? false
    })
  }
}

export class NativeNotification {
  public static isSupported(permission: string | null = null): boolean {
    if (!Notification || !Notification.requestPermission) {
      return false
    }

    if (permission === 'granted' || Notification.permission === 'granted') {
      throw new Error('You must call this before requesting permission')
    }

    try {
      // eslint-disable-next-line no-new
      new Notification('')
    } catch (e: any) {
      if (e.name === 'TypeError') {
        return false
      }
    }

    return true
  }

  public static requestPermission(): Promise<NotificationPermission> {
    return Notification.requestPermission()
  }

  public needsPermission: boolean = Notification.permission === 'granted'
  private readonly _title: string
  private readonly _options: NotificationOptions
  private _timeout = 4000

  public constructor(title: string, options: NotificationOptions) {
    if (typeof title !== 'string') {
      throw new Error('WebNotification(): argument title must be a string!')
    }

    if (typeof options !== 'object') {
      throw new Error('WebNotification(): argument options must be an object!')
    }

    this._title = title
    this._options = options
  }

  public setTimeout(timeout: number): void {
    this._timeout = timeout
  }

  public show(): Notification {
    const notification = new Notification(this._title, this._options)

    if (!this._options.requireInteraction) {
      setTimeout(notification.close.bind(notification), this._timeout)
    }

    return notification
  }
}

export const notification = new MaterialNotification()
