import deviceApi from '@graphql/device'
import { IDeregisterContext, IRegisterContext } from '@graphql/device/types'
import { IRootState } from '@store/types'
import { Module } from 'vuex'

const device: Module<null, IRootState> = {
  namespaced: true,
  actions: {
    async registerDevice(context, payload: IRegisterContext): Promise<boolean> {
      const result = await deviceApi.register(payload)

      return result.data.data.register
    },
    async deregisterDevice({ rootGetters }): Promise<boolean> {
      const payload: IDeregisterContext = {
        deviceId: rootGetters['settings/deviceId']
      }

      const result = await deviceApi.deregister(payload)

      return result.data.data.deregister
    }
  }
}

export default device
