import actions from '@store/modules/location/actions'
import getters from '@store/modules/location/getters'
import mutations from '@store/modules/location/mutations'
import { ILocationState } from '@store/modules/location/types'
import { IRootState } from '@store/types'
import { Module } from 'vuex'

const state: ILocationState = {
  activeLocation: undefined,
  suggestions: []
}

const namespaced = true

const location: Module<ILocationState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}

export default location
