<template>
  <f7-page :page-content="false">
    <navigation />
    <f7-page-content ptr :ptr-distance="50" ptr-mousewheel @ptr:refresh="onPullToRefresh">
      <pull-to-refresh-preloader />
      <f7-block-title v-if="pickBatch" class="margin-top" :class="{ 'skeleton-text': isLoading }">
        Pick batch #{{ pickBatch?.id }}
      </f7-block-title>
      <f7-card v-else :class="{ 'skeleton-text': isLoading }">
        <f7-card-content>
          <icon-with-text
            :icon="['far', 'laugh-beam']"
            description="There is no pending pick task that has been assigned to you."
            title="Hooray, all done!"
          />
        </f7-card-content>
      </f7-card>

      <f7-card v-if="pickBatch" :class="{ 'skeleton-text': isLoading }">
        <f7-card-content v-if="pickBatch">
          <f7-list media-list>
            <f7-list-item header="Locations to visit">
              <StringLabel :value="pickBatch?.lineCount" />
            </f7-list-item>
            <f7-list-item header="Progress">
              {{ pickBatch?.processedLines ?? 0 }} /
              {{ pickBatch?.lineCount }}
              <f7-progressbar id="demo-inline-progressbar" :progress="progressBarProgression" />
            </f7-list-item>
            <f7-list-item header="Created at">
              <DateTimeLabel :value="pickBatch?.createdAt" />
            </f7-list-item>
          </f7-list>
        </f7-card-content>
      </f7-card>

      <f7-block-header v-if="pickBatch && pickBatch.note" :class="{ 'skeleton-text': isLoading }">
        Note
      </f7-block-header>
      <f7-card
        v-if="pickBatch && pickBatch.note"
        :class="{ 'skeleton-text': isLoading }"
        @click="notePopupOpened = true"
      >
        <f7-card-content>
          <string-label :value="pickBatch?.note ?? ''" html :limit="600" excerpt="....." />
        </f7-card-content>
      </f7-card>

      <f7-block v-if="pickBatch" :class="{ 'skeleton-text': isLoading }">
        <f7-button
          v-if="pickBatch.state === StateEnum.CREATED"
          large
          color="text-secondary"
          fill-md
          @click="onStart(pickBatch)"
          :disabled="isLoading"
        >
          Start pick
        </f7-button>
        <f7-button
          v-else
          large
          color="text-secondary"
          fill-md
          @click="onContinue(pickBatch)"
          :disabled="isLoading"
        >
          Continue
        </f7-button>
      </f7-block>
    </f7-page-content>
    <f7-popup
      v-if="pickBatch"
      :opened="notePopupOpened"
      swipe-to-close
      @popup:close="notePopupOpened = false"
      class="pick-batch-note-popup"
    >
      <f7-page>
        <f7-navbar title="Note">
          <f7-nav-right>
            <f7-link popup-close> Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <string-label :value="pickBatch?.note ?? ''" html />
        </f7-block>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<script lang="ts" setup>
import useLoading from '@composables/useLoading'
import useProjectPick from '@composables/useProjectPick'
import { IPickBatch, StateEnum } from '@graphql/pick/types'
import { toast } from '@services/toast'
import { f7 } from 'framework7-vue'
import { computed, ref } from 'vue'
import StringLabel from '@components/label/StringLabel.vue'
import DateTimeLabel from '@components/label/DateTime.vue'
import IconWithText from '@components/IconWithText.vue'
import Navigation from '@components/AppNavigation.vue'
import PullToRefreshPreloader from '@components/PullToRefreshPreloader.vue'

const props = withDefaults(
  defineProps<{
    processablePickBatch: IPickBatch | null
  }>(),
  {
    processablePickBatch: null
  }
)

const { isLoading, withAsyncLoading } = useLoading()
const { startPickProcess, addProject, getProcessablePickBatch, updateProject } = useProjectPick()

const notePopupOpened = ref<boolean>(false)
const pickBatch = ref<IPickBatch | null>(props.processablePickBatch)

const onStart = async (pickBatch: IPickBatch) => {
  await withAsyncLoading(async () => {
    await startPickProcess({ pickBatchId: pickBatch.id })

    await toast.success(`Started pick process for pick batch ${pickBatch.id}!`).open()

    return f7.views.main.router.navigate(`/pick/line/${pickBatch.id}/`)
  })
}

const onContinue = async (project: Partial<IPickBatch>) => {
  await withAsyncLoading(async () => {
    await addProject(project)

    return f7.views.main.router.navigate(`/pick/line/${project.id}/`)
  })
}

const progressBarProgression = computed(() => {
  if (pickBatch.value) {
    return (pickBatch.value.processedLines / pickBatch.value.lineCount) * 100
  }
  return 0
})

const onPullToRefresh = async (done: () => void) => {
  const processablePickBatch = await getProcessablePickBatch()

  if (processablePickBatch) {
    await updateProject(processablePickBatch)
  }

  pickBatch.value = processablePickBatch as IPickBatch

  await done()
}
</script>
