<template>
  <f7-navbar>
    <f7-nav-title>
      {{ title }}
    </f7-nav-title>
    <f7-nav-right>
      <f7-link :popup-close="popupClose" @click="onPopupClose">
        <f7-icon material="close" />
      </f7-link>
    </f7-nav-right>
  </f7-navbar>
</template>
<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    title?: string
    popupClose?: boolean
    closeCallback?: () => void
  }>(),
  {
    title: '',
    popupClose: true,
    closeCallback: undefined
  }
)

const onPopupClose = async () => {
  if (props.popupClose) {
    return
  }

  if (props.closeCallback) {
    await props.closeCallback()
  }
}
</script>
