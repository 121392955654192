import packApi from '@graphql/pack'
import { IGetPackStationsResponse, IPackStation } from '@graphql/pack/types'
import { ISettingState } from '@store/modules/settings/types'
import { IRootState } from '@store/types'
import { ActionTree } from 'vuex'
import { ID } from '@graphql/types'

const actions: ActionTree<ISettingState, IRootState> = {
  async getPackStations(): Promise<IGetPackStationsResponse> {
    const response = await packApi.getPackStations()

    return response.data
  },
  async setPackStation({ commit }, payload: Partial<IPackStation> | IPackStation | undefined) {
    await commit('setPackStation', payload)
  },
  async setDeviceId({ commit }, payload: ID | null) {
    await commit('setDeviceId', payload)
  }
}

export default actions
