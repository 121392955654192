import { IArticle } from '@graphql/article/types'
import { IGoodsReceipt } from '@graphql/goodsReceipt/types'
import { ICompany, ISearchResult } from '@graphql/search/types'
import { IUser } from '@store/modules/auth/types'
import { ID, INode } from '@graphql/types'

export interface IPurchaseOrder extends ISearchResult {
  deliveredAt: Date | null
  reference: string
  grading: boolean
  uniqueId: string | null
  state: number
  stateString: string
  note: string
  createdAt: Date
  updatedAt: Date
  supplier: ICompany
  supplierCreditor: ISupplierCreditor
  purchaseOrderLine: IPurchaseOrderLine[]
  purchaseOrderState: IPurchaseOrderState[]
  goodsReceipt: IGoodsReceipt[]
  site: ISite
}

export const enum StateEnum {
  CREATED = 0,
  GOODS_BACKORDER = 5,
  ASN_RECEIVED = 7,
  RECEIVING_GOODS = 10,
  DELIVERED = 15,
  CANCELLED = 25
}

export interface IPurchaseOrderState extends INode {
  id: ID
  state: number
  changedOn: Date
  user: IUser
  purchaseOrder: IPurchaseOrder
}

export interface ISite {
  id: ID
  name: string
}

export interface IPurchaseOrderLine extends INode {
  id: ID
  amount: number
  qtyDelivered: number
  allowMerge: boolean
  lineNumber: boolean
  externalLineNumber: boolean
  article: IArticle
  purchaseOrder: IPurchaseOrder
}

export interface ISupplierCreditor extends INode {
  id: ID
  email: string
  addressId: number
  importId: number
  externalId: number
  supplier: ICompany
}
