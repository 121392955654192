import Base from '@graphql/base'
import {
  IGetPickBatchByIdResponse,
  IGetProcessablePickBatchResponse,
  IAddLoadCarrierMutationResponse,
  IAddLoadCarrierRequest,
  IGetLoadCarrierByIdQueryResponse,
  IGetLoadCarrierByIdRequest,
  IGetLocationToVisitQueryResponse,
  ISavePickBatchLineStateMutationResponse,
  ISavePickBatchStateRequest,
  IStartPickProcessContext,
  IStartPickProcessResponse
} from '@graphql/pick/types'
import { IMarkCompleteRequest, IMarkPickedResponse } from '@store/modules/projects/pick/types'
import { AxiosInstance } from 'axios'
import GRAPHQL_API_CLIENT from './client'

export class Pick extends Base {
  constructor(axios: AxiosInstance) {
    super(axios, 'pick')
  }

  public async getProcessablePickBatch() {
    return await this.makeRequest<IGetProcessablePickBatchResponse>('getProcessablePickBatchQuery')
  }

  public async startPickProcess(context: IStartPickProcessContext) {
    return await this.makeRequest<IStartPickProcessResponse>('startPickProcessMutation', context)
  }

  public async savePickBatchLineState(context: ISavePickBatchStateRequest) {
    return await this.makeRequest<ISavePickBatchLineStateMutationResponse>(
      'savePickBatchLineStateMutation',
      context
    )
  }

  public async markPicked(context: IMarkCompleteRequest) {
    return await this.makeRequest<IMarkPickedResponse>('markPickedMutation', context)
  }

  public async getById(pickBatchId: ID) {
    return await this.makeRequest<IGetPickBatchByIdResponse>('getPickBatchById', { pickBatchId })
  }

  public async getLocationToVisit(pickBatchId: ID) {
    return await this.makeRequest<IGetLocationToVisitQueryResponse>('getLocationToVisitQuery', {
      pickBatchId
    })
  }

  public async addLoadCarrier(request: IAddLoadCarrierRequest) {
    return await this.makeRequest<IAddLoadCarrierMutationResponse>(
      'addLoadCarrierMutation',
      request
    )
  }

  public async getLoadCarrierById(request: IGetLoadCarrierByIdRequest) {
    return await this.makeRequest<IGetLoadCarrierByIdQueryResponse>(
      'getLoadCarrierByIdQuery',
      request
    )
  }
}

export default new Pick(GRAPHQL_API_CLIENT)
