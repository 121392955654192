import {
  NoResultCallback,
  PluralResultCallback,
  SingleResultCallback
} from '@services/search/search'
import { soundBoard } from '@services/sound'
import { toast } from '@services/toast'
import { SearchResults, UnionTypeSearchResult } from '@store/modules/search/types'
import { useStore } from '@store/store'
import { ComponentInternalInstance, computed, getCurrentInstance } from 'vue'

export function onNoResult(query: string) {
  toast.error(`No results found for "${query}"`).open() //TODO: check behavior, if there is a possibility to streamline this with overrides.
}

export default function () {
  const store = useStore()
  const context = getCurrentInstance() as ComponentInternalInstance

  const searchResults = computed<SearchResults>(() => store.getters['search/searchResults'])

  const search = async (results: SearchResults) => store.dispatch('search/search', results)
  const setSearchResults = async (results: SearchResults) =>
    store.dispatch('search/setSearchResults', results)
  const clearSearchResults = async () => store.dispatch('search/clearSearchResults')

  const handleSingularSearchResult: SingleResultCallback = async (item: UnionTypeSearchResult) => {
    await soundBoard.playSearchSingleHit()

    await context.emit('result', item)
  }

  const handlePluralSearchResult: PluralResultCallback = async (items: UnionTypeSearchResult[]) => {
    await soundBoard.playSearchMultiHitSound()

    await context.emit('results', items)
  }

  const handleNoResults: NoResultCallback = async (query: string) => {
    await soundBoard.playSearchNoHitsSound()

    await context.emit('no-result', query)
  }

  const handleSuccessResult: SingleResultCallback = async (item: UnionTypeSearchResult) => {
    await soundBoard.playSuccessSound()

    await context.emit('result', item)
  }

  const handleFailedResult: NoResultCallback = async (query: string) => {
    await soundBoard.playFailedSound()

    await context.emit('no-result', query)
  }

  return {
    searchResults,
    search,
    setSearchResults,
    clearSearchResults,
    onNoResult,
    handleSingularSearchResult,
    handlePluralSearchResult,
    handleNoResults,
    handleSuccessResult,
    handleFailedResult
  }
}
