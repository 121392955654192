import {
  IAddLoadCarrierRequest,
  IAddLoadCarrierResponse,
  IPickBatch,
  IPickBatchLine,
  ISavePickBatchStateRequest,
  ISavePickBatchStateResponse,
  IStartPickProcessContext
} from '@graphql/pick/types'
import { IMarkCompleteRequest, IMarkPickedResult } from '@store/modules/projects/pick/types'
import { computed } from 'vue'
import { useStore } from '@store/store'
import { ID } from '@graphql/types'
import { f7 } from 'framework7-vue'
import useLoading from '@composables/useLoading'
import { perceptibleNotification } from '@services/perceptibleNotification'
import { perceptibleToast } from '@services/perceptibleToast'

export default function () {
  const store = useStore()
  const { withAsyncLoading, isLoading } = useLoading()

  const isActive = computed<boolean>(() => store.getters['projects/pick/isActive'])

  const itemCount = computed<number>(() => store.getters['projects/pick/itemCount'])

  const items = computed<IPickBatch[]>(() => store.getters['projects/pick/items'])

  const firstPickBatch = computed<IPickBatch | null>(() =>
    items.value.length > 0 ? items.value[0] : null
  )

  const assignedPickBatch = computed<IPickBatch>(
    () => store.getters['projects/pick/assignedPickBatch']
  )

  const getProcessablePickBatch = async (): Promise<Partial<IPickBatch> | null> =>
    await store.dispatch('projects/pick/getProcessablePickBatch')

  const updateProject = async (payload: Partial<IPickBatch> | undefined) =>
    store.dispatch('projects/pick/updateProject', payload)

  const startPickProcess = async (context: IStartPickProcessContext) =>
    await store.dispatch('projects/pick/startPickProject', context)

  const updateProcessedLines = async () =>
    await store.dispatch('projects/pick/updateProcessedLines')

  const savePickBatchLineState = async (
    context: ISavePickBatchStateRequest
  ): Promise<ISavePickBatchStateResponse> =>
    await store.dispatch('projects/pick/savePickBatchLineState', context)

  const removeProject = async (pickBatchId: ID) =>
    await store.dispatch('projects/pick/removeProject', pickBatchId)

  const addProject = async (payload: Partial<IPickBatch>): Promise<Partial<IPickBatch> | null> =>
    await store.dispatch('projects/pick/addProject', payload)

  const markComplete = async (request: IMarkCompleteRequest): Promise<IMarkPickedResult> =>
    await store.dispatch('projects/pick/markPicked', request)

  const getLocationToVisit = async (pickBatchId: ID) =>
    await store.dispatch('projects/pick/getLocationToVisit', pickBatchId)

  const addLoadCarrier = async (
    request: IAddLoadCarrierRequest
  ): Promise<IAddLoadCarrierResponse> =>
    await store.dispatch('projects/pick/addLoadCarrier', request)

  const removeProjects = async () => {
    await store.dispatch('projects/pick/removeProjects')
  }
  const refreshProject = async () => {
    const response = await getProcessablePickBatch()

    if (response) {
      await updateProject(response)
    }
  }

  const isComplete = (
    pickBatch: IPickBatch | null,
    donePickBatchLines: IPickBatchLine[] = []
  ): boolean => {
    if (!pickBatch) {
      return false
    }

    if (pickBatch.lineCount === pickBatch.processedLines) {
      return true
    }

    return pickBatch.lineCount === donePickBatchLines.length
  }

  async function onPickCompleted(pickBatchId: ID) {
    await removeProject(pickBatchId)

    await perceptibleNotification.finishedTask(`Pick batch #"${pickBatchId}" completed!`)

    await f7.views.main.router.navigate('/home/', {
      clearPreviousHistory: true
    })
  }

  async function onLoadCarrierFinalized(pickBatchId: string | number) {
    await perceptibleToast.success(
      `Load carrier successfully finalized for pick batch #${pickBatchId}!`
    )

    await f7.views.main.router.navigate(`/pick/line/${pickBatchId}/`)
  }

  const verifyBarcode = async (verificationCode: string, pickBatchId: ID): Promise<boolean> => {
    return await withAsyncLoading(async (): Promise<boolean> => {
      try {
        const result = await markComplete({ pickBatchId, verificationCode })

        if (result.pickCompleted) {
          await onPickCompleted(pickBatchId)

          return true
        }

        await onLoadCarrierFinalized(pickBatchId)

        return true
      } catch (e) {
        await perceptibleToast.error(e.message)
        isLoading.value = false
        throw e
      }
    })
  }

  return {
    isActive,
    itemCount,
    items,
    firstPickBatch,
    assignedPickBatch,
    getProcessablePickBatch,
    updateProject,
    startPickProcess,
    savePickBatchLineState,
    removeProject,
    addProject,
    markPicked: markComplete,
    refreshProject,
    isComplete,
    getLocationToVisit,
    updateProcessedLines,
    addLoadCarrier,
    removeProjects,
    verifyBarcode
  }
}
