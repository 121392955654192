import { IGoodsReceipt, IGoodsReceiptLine } from '@graphql/goodsReceipt/types'
import { IGoodsReceiptState } from '@store/modules/goods-receipt/types'
import { MutationTree } from 'vuex'

const mutations: MutationTree<IGoodsReceiptState> = {
  setActiveGoodsReceipt(state, payload: IGoodsReceipt) {
    state.activeGoodsReceipt = payload
  },

  setOpenGoodsReceipts(state, payload: IGoodsReceipt[]) {
    state.openGoodsReceipts = payload
  },

  setActiveGoodsReceiptLine(state, payload: IGoodsReceiptLine) {
    state.activeGoodsReceiptLine = payload
  }
}

export default mutations
