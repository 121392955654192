import { useStore } from '@store/store'
import { IGetByIdRequest } from '@graphql/task/types'

export default () => {
  const store = useStore()
  const getById = async (request: IGetByIdRequest) => store.dispatch('task/getById', request)

  return {
    getById
  }
}
