<template>
  <f7-searchbar
    :expandable="expandable"
    disable-link-text="Cancel"
    search-container="#search-list"
    :class="searchId"
    placeholder="Search..."
    :value="searchQuery"
    :disable-button="true"
    :backdrop="false"
    :custom-search="true"
    @searchbar:search="onTyping"
  >
  </f7-searchbar>
</template>
<script lang="ts" setup>
import { eventBus } from '@/utilities/scanInput'
import useSearch from '@composables/useSearch'
import scannerConfig from '@config/scanner'
import search, {
  ISearchFilter,
  NoResultCallback,
  PluralResultCallback,
  SearchStrategy,
  SingleResultCallback
} from '@services/search/search'
import { toast } from '@services/toast'
import { f7, f7ready } from 'framework7-vue'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { removeAutoFocus } from '@/utilities/generic'
import { UnionTypeSearchResult } from '@store/modules/search/types'

const props = withDefaults(
  defineProps<{
    searchStrategy?: string
    searchType?: string
    expandable?: boolean
    searchId?: string
    searchFilters?: ISearchFilter[]
    singularResultCallback?: SingleResultCallback
    pluralResultCallback?: PluralResultCallback
    noResultCallback?: NoResultCallback
  }>(),
  {
    searchStrategy: SearchStrategy.Default,
    searchType: undefined,
    expandable: false,
    searchId: 'global-search-bar',
    searchFilters: () => [],
    singularResultCallback: undefined,
    pluralResultCallback: undefined,
    noResultCallback: undefined
  }
)

defineEmits<{
  (e: 'result', payload: UnionTypeSearchResult): void
  (e: 'results', payload: UnionTypeSearchResult[]): void
  (e: 'no-result', payload?: string): void
}>()

const { setSearchResults, handleSingularSearchResult, handlePluralSearchResult, handleNoResults } =
  useSearch()

const query = ref<string>('')
const isScanning = ref<boolean>(false)
const captureEvents = ref<boolean>(true)
const searchQuery = computed<string>(() => query.value)

const onTyping = async (searchBar: any, query: string) => {
  searchBar.el.onkeydown = async (e: KeyboardEvent) => {
    const key = e.code || e.key

    if (scannerConfig.triggerKeys.includes(key)) {
      isScanning.value = true
    }

    if (!isScanning.value && scannerConfig.submitKeys.includes(key)) {
      await onSearch(query, false, props.searchType, props.searchFilters)

      await removeAutoFocus()
    }
  }
}

const onSearch = async (
  query: string,
  exact = false,
  type: string | null = null,
  filters: ISearchFilter[] = []
) => {
  f7.preloader.show('#d6d95f')

  try {
    const strategy = await search.getStrategy(props.searchStrategy)
    const searchResults = await strategy.search(query, exact, type, filters)

    let singularCallback = handleSingularSearchResult
    if (props.singularResultCallback) {
      singularCallback = props.singularResultCallback
    }

    let pluralCallback = handlePluralSearchResult
    if (props.pluralResultCallback) {
      pluralCallback = props.pluralResultCallback
    }

    let noResultCallback = handleNoResults
    if (props.noResultCallback) {
      noResultCallback = props.noResultCallback
    }

    if (searchResults && searchResults.search) {
      await setSearchResults([])
      const parsedResults = await strategy.parseSearchResults(searchResults, props.searchType)
      await setSearchResults(parsedResults)
      await strategy.handleResults(query, parsedResults, {
        singularCallback,
        pluralCallback,
        noResultCallback
      })
    } else {
      await setSearchResults([])
    }
  } catch (e: any) {
    toast.error(e.message).open()
  } finally {
    f7.preloader.hide()
  }
}

const enableCapturingOfScanInputEvents = (e) => {
  captureEvents.value = true
}

const disableCapturingOfScanInputEvents = (e) => {
  captureEvents.value = false
}

const onScanEvent = async (value: string) => {
  query.value = value

  if (query.value) {
    await onSearch(query.value, true, props.searchType, props.searchFilters)
  }

  isScanning.value = false
}

const unsubscribeStore = eventBus.on('scanInput', async (event) => {
  if (captureEvents.value === false) {
    return
  }

  await onScanEvent(String(event.payload))
})

onMounted(async () => {
  await f7ready((f7) => {
    f7.on('pageInit', enableCapturingOfScanInputEvents)
    f7.once('pageReinit', disableCapturingOfScanInputEvents)
    f7.on('pageBeforeOut', disableCapturingOfScanInputEvents)
    f7.once('pageBeforeRemove', disableCapturingOfScanInputEvents)
  })

  enableCapturingOfScanInputEvents()
})

onBeforeUnmount(() => {
  unsubscribeStore()
})
</script>
