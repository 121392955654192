import { IRootState } from '@store/types'
import { Module } from 'vuex'
import { IGetByIdRequest } from '@graphql/task/types'
import taskApi from '@graphql/task'

const task: Module<null, IRootState> = {
  namespaced: true,
  actions: {
    async getById(context, request: IGetByIdRequest) {
      const response = await taskApi.getById(request)

      return response.data.data.getById
    }
  }
}

export default task
