<template>
  <f7-page>
    <navigation />
    <search-result-list
      :items="items"
      :search-strategy="searchStrategy"
      @result="onResult"
      @no-result="onNoResult"
      class="no-margin"
    />
  </f7-page>
</template>
<script lang="ts" setup>
import { isShipment } from '@/functions/search'
import Navigation from '@components/AppNavigation.vue'
import SearchResultList from '@components/search/ResultList.vue'
import useSearch from '@composables/useSearch'
import search, { SearchStrategy, SingleResultCallback } from '@services/search/search'
import { SearchResults, UnionTypeSearchResult } from '@store/modules/search/types'
import useShipment from '@composables/useShipment'

const { onNoResult } = useSearch()
const { setShipment } = useShipment()

const props = withDefaults(
  defineProps<{
    items?: SearchResults
    searchStrategy?: string
  }>(),
  {
    items: () => [],
    searchStrategy: SearchStrategy.Default
  }
)

defineEmits<{
  (e: 'result', result: UnionTypeSearchResult): void
  (e: 'results', result: SearchResults): void
  (e: 'noResult', query: string): void
}>()

const onResult: SingleResultCallback = (result) => {
  const searchStrategy = search.getStrategy(props.searchStrategy)

  if (isShipment(result)) {
    setShipment(result) //TODO check if this is still necessary?
  }

  searchStrategy.handleSingularResult(result)
}
</script>
