import actions from '@store/modules/projects/pick/actions'
import getters from '@store/modules/projects/pick/getters'
import mutations from '@store/modules/projects/pick/mutations'
import { IProjectPickState } from '@store/modules/projects/pick/types'
import { IRootState } from '@store/types'
import { Module } from 'vuex'

const state: IProjectPickState = {
  count: 0,
  itemCount: 0,
  title: 'Pick',
  type: 'pick',
  items: [],
  assignedPickBatch: undefined,
  isActive: false
}

const projectPick: Module<IProjectPickState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default projectPick
