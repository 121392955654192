import { IGetArticleByIdResponse } from '@graphql/article/types'
import Base from '@graphql/base'
import { IArticleRequestContext } from '@store/modules/article/types'
import { AxiosInstance, AxiosResponse } from 'axios'
import GRAPHQL_API_CLIENT from './client'

class Article extends Base {
  constructor(axios: AxiosInstance) {
    super(axios, 'article')
  }

  public async getArticle(
    context: IArticleRequestContext
  ): Promise<AxiosResponse<IGetArticleByIdResponse>> {
    return await this.makeRequest('articleQuery', context)
  }
}

export default new Article(GRAPHQL_API_CLIENT)
