<template>
  <f7-card :class="{ 'skeleton-text': isLoading }" v-if="lastPackedCollo">
    <f7-card-content>
      <f7-list media-list>
        <f7-list-item
          header="Last packed collo"
          link="#"
          @click="onReprintDocumentsFor(lastPackedCollo.node.id, lastPackedCollo.node.__typename)"
        >
          #{{ renderColloTitle(lastPackedCollo.node) }}
        </f7-list-item>
      </f7-list>
    </f7-card-content>
  </f7-card>

  <f7-block-title v-if="packedColliCount > 1">Other packed colli</f7-block-title>
  <f7-card v-if="packedColliCount > 1" :class="{ 'skeleton-text': isLoading }">
    <f7-card-content>
      <f7-list>
        <f7-list-item
          v-for="packedCollo in packedColliWithoutLastPacked"
          :key="packedCollo.node.id"
          swipeout
          @swipeout:opened="onReprintDocumentsFor(packedCollo.node.id, packedCollo.node.__typename)"
        >
          <template #title>#{{ renderColloTitle(packedCollo.node) }}</template>
          <f7-swipeout-actions right>
            <f7-swipeout-button color="dimass" overswipe close>
              <font-awesome-icon :icon="['far', 'print']" class="text-color-white" />
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list>
    </f7-card-content>
  </f7-card>

  <f7-card v-if="!packedColliRef || packedColliCount === 0" :class="{ 'skeleton-text': isLoading }">
    <f7-card-content>
      <icon-with-text :icon="['far', 'hand-paper']" title="No colli packed yet..." />
    </f7-card-content>
  </f7-card>
</template>
<script lang="ts" setup>
import IconWithText from '@components/IconWithText.vue'
import usePack from '@composables/usePack'
import { IPackStation } from '@graphql/pack/types'
import { IPickBatch } from '@graphql/pick/types'
import { computed } from 'vue'
import { TypeName } from '@graphql/search/types'
import { ID } from '@graphql/types'
import { ICollo, IColloEdge } from '@graphql/shipment/types'
import { arrayToString } from '@/utilities/string'

const props = withDefaults(
  defineProps<{
    pickBatch: IPickBatch
    packStation: IPackStation
    isLoading?: boolean
  }>(),
  {
    isLoading: false
  }
)

const { reprintDocumentFor } = usePack()

const pickBatchRef = computed(() => props.pickBatch)
const packedColliRef = computed(() => pickBatchRef.value.packedColli)

const lastPackedCollo = computed<IColloEdge | undefined>(() => {
  if (packedColloEdges.value.length > 0) {
    return packedColloEdges.value[packedColloEdges.value.length - 1]
  }

  return undefined
})

const packedColliCount = computed<number>(() => {
  let count = 0
  if (packedColliRef.value) {
    count = packedColliRef.value.totalCount ?? 0
  }

  if (lastPackedCollo.value) {
    count++
  }

  return count
})

const packedColloEdges = computed<IColloEdge[]>(() => {
  if (packedColliRef.value) {
    return packedColliRef.value.edges
  }

  return []
})

const packedColliWithoutLastPacked = computed<IColloEdge[]>(() => {
  if (lastPackedCollo.value) {
    return packedColloEdges.value.filter((edge) => edge.node.id !== lastPackedCollo.value?.node.id)
  }

  return packedColloEdges.value
})

const onReprintDocumentsFor = async (identifier: ID, type: string = TypeName.Collo) => {
  return await reprintDocumentFor(
    {
      identifier,
      type,
      documentKind: null
    },
    true
  )
}

const renderColloTitle = (collo: ICollo) => {
  return arrayToString([collo.id, collo.packReference])
}
</script>
