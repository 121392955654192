import stockApi from '@graphql/stock'
import { IRelocateTransitItem } from '@store/modules/stock/types'
import { IRootState } from '@store/types'
import { ActionContext, ActionTree } from 'vuex'
import { IProjectRelocateState, IRelocateActionItem } from './types'

const actions: ActionTree<IProjectRelocateState, IRootState> = {
  async relocateToLocation(
    { commit }: ActionContext<IProjectRelocateState, IRootState>,
    relocateItem: IRelocateActionItem
  ) {
    const { item, locationID } = relocateItem

    const response = await stockApi.relocate({
      articleId: item.articleID,
      fromLocationId: item.locationID,
      toLocationId: locationID,
      moveAll: item.moveAll,
      qty: item.qty,
      highPriorityLocation: item.highPriorityLocation
    })

    if (response.data.data.relocateStock) {
      await commit('update', relocateItem.item)
    }

    return response
  },

  async relocateToTransitStock(
    { commit }: ActionContext<IProjectRelocateState, IRootState>,
    relocateItem: IRelocateActionItem
  ) {
    const payload = transformRelocateItemForTransit(relocateItem)
    const response = await stockApi.relocateToTransitStock(payload)

    if (response.data.data.relocateToTransitStock) {
      await commit('add', relocateItem.item)
    }

    return response
  },

  async relocateFromTransitStock(
    { commit }: ActionContext<IProjectRelocateState, IRootState>,
    relocateItem: IRelocateActionItem
  ) {
    const { locationID } = relocateItem

    const payload = transformRelocateItemForTransit(relocateItem)
    payload.locationId = locationID

    const response = await stockApi.relocateFromTransitStock(payload)

    if (response.data.data.relocateFromTransitStock) {
      await commit('updateTransit', relocateItem.item)
    }

    return response
  }
}

function transformRelocateItemForTransit(relocateItem: IRelocateActionItem): IRelocateTransitItem {
  return {
    articleId: relocateItem.item.articleID,
    locationId: relocateItem.item.locationID,
    moveAll: relocateItem.item.moveAll,
    qty: relocateItem.item.qty,
    highPriorityLocation: relocateItem.item.highPriorityLocation
  }
}

export default actions
