<template>
  <f7-page :page-content="false">
    <scan-input
      :items="items"
      :search-type="TypeName.Shipment"
      :restricted="false"
      :search-strategy="SearchStrategy.PackingControl"
      key="packing-control-search"
    />
  </f7-page>
</template>
<script lang="ts" setup>
import ScanInput from '@components/ScanInput.vue'
import { TypeName } from '@graphql/search/types'
import { IShipment } from '@graphql/shipment/types'
import { SearchStrategy } from '@services/search/search'
import { ref } from 'vue'

const items = ref<Array<Partial<IShipment>>>([])
</script>
