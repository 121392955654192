<template>
  <span>
    <font-awesome-icon
      v-for="classification in currentClassifications"
      :key="classification.id"
      :icon="getIcon(classification)"
      :style="getStyle(classification)"
      size="2x"
      class="padding-half"
    />
  </span>
</template>
<script lang="ts" setup>
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBiohazard,
  faBomb,
  faBox,
  faBoxFragile,
  faBurn,
  faDroplet,
  faFill,
  faFire,
  faFireAlt,
  faFlame,
  faHandHoldingBox,
  faLeaf,
  faQuestion,
  faRadiation,
  faRulerHorizontal,
  faSkull,
  faSkullCrossbones,
  faSnowflake,
  faTruck,
  faVial
} from '@fortawesome/pro-regular-svg-icons'
import { IClassification } from '@graphql/article/types'
import { computed } from 'vue'

library.add(
  faBiohazard,
  faBomb,
  faBox,
  faBoxFragile,
  faBurn,
  faDroplet,
  faFill,
  faFire,
  faFireAlt,
  faFlame,
  faHandHoldingBox,
  faLeaf,
  faQuestion,
  faRadiation,
  faRulerHorizontal,
  faSkull,
  faSkullCrossbones,
  faSnowflake,
  faTruck,
  faVial
)

const props = withDefaults(
  defineProps<{
    items: IClassification[]
  }>(),
  {
    items: () => []
  }
)

const currentClassifications = computed(() => props.items)

const getIcon = (item: IClassification) => {
  if (!item.icon) {
    item.icon = 'question'
  }

  // @ts-ignore
  if (library.definitions['far'] && library.definitions['far'][item.icon]) {
    return ['far', item.icon]
  }

  return ['far', 'question']
}

const getStyle = (item: IClassification) => {
  if (!item.colorHex) {
    return
  }

  return { color: item.colorHex }
}
</script>
