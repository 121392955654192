<template>
  <icon-with-text
    title="Hold the NFC-tag near the reader."
    :icon="['far', 'nfc-signal']"
    style="color: #d6d95f"
  />
  <f7-block>
    <f7-button
      fill
      color="text-secondary"
      @click.prevent="onEnableNfcLogin"
      v-if="!isNfcPermissionGranted"
    >
      <f7-preloader :size="30" v-if="isLoading" />
      <span v-else>Enable NFC</span>
    </f7-button>
    <f7-button
      class="login-link no-ripple margin-top"
      small
      @click="onLoginWithUsername"
      data-testid="enable-token-login"
    >
      Login with username and password
    </f7-button>
  </f7-block>
</template>
<script setup lang="ts">
import IconWithText from '@components/IconWithText.vue'
import useAuthentication from '@composables/useAuthentication'
import useLoading from '@composables/useLoading'
import { toast } from '@services/toast'
import { computed } from 'vue'

const props = defineProps<{
  nfcPermissionString: string | null
}>()

const emits = defineEmits<{
  (e: 'login-with-username'): void
}>()

const { enableNfcReader } = useAuthentication()
const { isLoading, withAsyncLoading } = useLoading()

const isNfcPermissionGranted = computed<boolean>(() => props.nfcPermissionString === 'granted')

const onLoginWithUsername = async () => {
  emits('login-with-username')
}

const onEnableNfcLogin = async () => {
  if (props.nfcPermissionString && props.nfcPermissionString === 'denied') {
    await toast
      .error(
        'NFC permission have been denied by the user. Please enable (allow) NFC in the site settings!',
        0,
        true
      )
      .open()
    return
  }

  await withAsyncLoading(async () => {
    await enableNfcReader()
  })
}
</script>
