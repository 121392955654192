<template>
  <f7-list-item swipeout @swipeout:opened="onOverSwipe">
    <template #header>Goods receipt Line</template>
    <template #media>
      <search-result-icon :icon="['far', 'barcode']" />
    </template>
    <template #title>{{ searchResult.searchPreview }}</template>
    <template #subtitle>
      <string-label :value="searchResult.purchaseOrderLine.article.code" /><br />
      <string-label :value="searchResult.purchaseOrderLine.article.primaryBarcode" />
    </template>
    <slot name="actions" />
  </f7-list-item>
</template>
<script lang="ts" setup>
import StringLabel from '@components/label/StringLabel.vue'
import { IGoodsReceiptLine } from '@graphql/goodsReceipt/types'
import SearchResultIcon from '@components/search/SearchResultIcon.vue'

defineProps<{
  searchResult: Partial<IGoodsReceiptLine>
}>()

const emit = defineEmits<{
  (e: 'over-swipe', result: Partial<IGoodsReceiptLine>): void
}>()

const onOverSwipe = async (result: Partial<IGoodsReceiptLine>) => emit('over-swipe', result)
</script>
