import RegisterPage from '@pages/device/RegisterPage.vue'
import { Router } from 'framework7/types'

const routes: Partial<Router.RouteParameters>[] = [
  {
    path: '/device/register/',
    component: RegisterPage
  }
]

export default routes
