import { Router } from 'framework7/types'
import { store } from '@store/store'
import { computed } from 'vue'
import { ISortingProject } from '@store/modules/projects/sorting/types'
import { IGetPickBatchSortStateRequest, IPickBatchSortState } from '@graphql/sort/types'
import { perceptibleNotification } from '@services/perceptibleNotification'
import { captureException } from '@sentry/vue'

export const findRedirectUrlForProject = async (
  project: ISortingProject,
  pickBatchSortState: IPickBatchSortState
) => {
  if (pickBatchSortState.pickLoadCarriersSorted && !pickBatchSortState.sortLoadCarriersSorted) {
    return `/sorting/${project.pickBatchId}/print-documents/`
  }

  const lastSortedPickLoadCarrierId = pickBatchSortState.lastSortReference.pickLoadCarrierId
  if (lastSortedPickLoadCarrierId) {
    const activePickLoadCarrier = pickBatchSortState.pickLoadCarrierReferences.find(
      (reference) => reference.identifier === lastSortedPickLoadCarrierId && !reference.sorted
    )

    if (activePickLoadCarrier) {
      if (pickBatchSortState.lastSortReference.sortLoadCarrierId) {
        return `/sorting/${lastSortedPickLoadCarrierId}/process/${pickBatchSortState.lastSortReference.sortLoadCarrierId}/`
      }

      return `/sorting/${lastSortedPickLoadCarrierId}/scan-sort-load-carrier/`
    }
  }

  if (pickBatchSortState.sortLoadCarriersSorted) {
    return `/sorting/${project.pickBatchId}/print-documents/`
  }

  return `/sorting/${project.pickBatchId}/scan-pick-load-carrier/`
}

export const redirectMiddleware = async ({ resolve }: Router.RouteCallbackCtx) => {
  const getPickBatchSortState = async (
    request: IGetPickBatchSortStateRequest
  ): Promise<IPickBatchSortState> => store.dispatch('sort/getPickBatchSortState', request)

  const activeItem = computed<ISortingProject[]>(() => store.getters['projects/sorting/activeItem'])

  if (activeItem.value) {
    try {
      const pickBatchSortState = await getPickBatchSortState({
        pickBatchId: activeItem.value.pickBatchId
      })

      if (pickBatchSortState.sortLoadCarriersSorted) {
        await perceptibleNotification.finishedTask(
          `Pick batch #${activeItem.value?.pickBatchId} is already sorted or completed elsewhere!`
        )
        await store.dispatch('projects/sorting/removeProject', activeItem.value?.pickBatchId)
        await resolve('/sorting/')
        return
      }

      const redirectUrl = await findRedirectUrlForProject(activeItem.value, pickBatchSortState)

      await resolve({ url: redirectUrl })
      return
    } catch (e) {
      await perceptibleNotification.finishedTask(
        `An error occurred while retrieving the pick batch sort state: ${e.message}`
      )
      await store.dispatch('projects/sorting/removeProject', activeItem.value?.pickBatchId)
      captureException(e)
      await resolve('/sorting/')
    }
    return
  }

  await resolve('/sorting/')
}
