import actions from '@store/modules/article/actions'
import getters from '@store/modules/article/getter'
import mutations from '@store/modules/article/mutations'
import { IArticleState } from '@store/modules/article/types'
import { IRootState } from '@store/types'
import { Module } from 'vuex'

const namespaced = true

const state: IArticleState = {
  activeArticle: undefined
}

const article: Module<IArticleState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}

export default article
