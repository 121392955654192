<template>
  <f7-page :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar :search-id="searchId" expandable />
    </navigation>

    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#shipment-detail" tab-link-active>Detail</f7-link>
      <f7-link tab-link="#shipment-articles">Articles</f7-link>
      <f7-link tab-link="#shipment-colli">Colli ({{ colli.length }})</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="shipment-detail" class="page-content" tab-active>
        <f7-block-title v-if="shipment" class="margin-top" :class="{ 'skeleton-text': isLoading }">
          Shipment #{{ shipment.id }}
        </f7-block-title>

        <f7-card media-list :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list v-if="shipment" media-list>
              <f7-list-item header="Order number" v-if="shipment.order"
                >{{ shipment.order.orderNr }}
              </f7-list-item>
              <f7-list-item header="Partner">{{ shipment.flatPartner }}</f7-list-item>
              <f7-list-item header="Courier">{{ shipment.flatCourier }}</f7-list-item>
              <f7-list-item header="Colli" v-if="shipment.colli">{{ shipment.colli }}</f7-list-item>
              <f7-list-item header="State">{{ shipment.humanState }}</f7-list-item>
              <f7-list-item header="Created at">
                <date-time-label :value="shipment.createdAt" />
              </f7-list-item>
              <f7-list-item header="Site" v-if="shipment.site">
                {{ shipment.site.name }}
              </f7-list-item>
              <f7-list-item header="Weight (in gram)" v-if="shipment.weight">
                {{ shipment.weight }}
              </f7-list-item>
              <f7-list-item header="Latest shipping date">
                <date-time-label :value="shipment.latestShippingDate" />
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-tab>
      <f7-tab v-if='shipmentLines' id="shipment-articles" class="page-content">
        <data-table-card :items="shipmentLines.edges" :is-loading="isLoading">
          <template #no-items>No shipment lines available.</template>
          <data-table>
            <template #header>
              <table-row>
                <numeric-cell element="th">Amount</numeric-cell>
                <label-cell element="th">Article</label-cell>
              </table-row>
            </template>
            <table-row
              v-for="shipmentLineEdge in shipmentLines.edges"
              :key="shipmentLineEdge.cursor"
            >
              <numeric-cell>{{ shipmentLineEdge.node.amount }}</numeric-cell>
              <label-cell>{{ shipmentLineEdge.node.article.name }}</label-cell>
            </table-row>
          </data-table>
        </data-table-card>
      </f7-tab>
      <f7-tab id="shipment-colli" class="page-content">
        <data-table-card :items="colli" :is-loading="isLoading">
          <data-table>
            <template #header>
              <table-row>
                <numeric-cell element="th">Qty</numeric-cell>
                <label-cell element="th">Reference</label-cell>
              </table-row>
            </template>
            <table-row v-for="collo in colli" :key="collo.id" @click="onNavigateToCollo(collo)">
              <numeric-cell>{{ collo.sequence }}</numeric-cell>
              <label-cell>{{ collo.reference }}</label-cell>
            </table-row>
          </data-table>
        </data-table-card>
      </f7-tab>
    </f7-tabs>
    <app-actions
      v-if="actions.length > 0"
      :actions="actions"
      :fixed="true"
      @action-clicked="onActionClick"
    />
  </f7-page>
</template>
<script lang="ts" setup>
import { isPackingControllable } from '@/functions/shipment'
import { IAction } from '@components/actions'
import AppActions from '@components/AppActions.vue'
import DateTimeLabel from '@components/label/DateTime.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import useAction from '@composables/useAction'
import useLoading from '@composables/useLoading'
import useShipment from '@composables/useShipment'
import { IShipmentLineConnection } from '@graphql/shipment/types'
import { computed, onMounted, ref, watch } from 'vue'
import { ID } from '@graphql/types'
import LabelCell from '@components/data-table/LabelCell.vue'
import NumericCell from '@components/data-table/NumericCell.vue'
import DataTable from '@components/data-table/DataTable.vue'
import TableRow from '@components/data-table/TableRow.vue'
import DataTableCard from '@components/data-table/DataTableCard.vue'

const props = defineProps<{
  id: ID
}>()

const actions = ref<IAction[]>([])

const { isLoading, withAsyncLoading } = useLoading()
const { shipment, getShipmentById, onNavigateToCollo } = useShipment()
const { onActionClick } = useAction()

const shipmentLines = computed<IShipmentLineConnection | null>(() => {
  if (shipment.value && shipment.value.shipmentLine) {
    return shipment.value.shipmentLine
  }
  return null
})

const colli = computed<IColli[]>(() => {
  if (!shipment.value || !shipment.value.shipmentColli) {
    return []
  }
  return shipment.value.shipmentColli.map((shipmentColli) => shipmentColli.collo)
})

watch(
  shipment,
  (value) => {
    if (!value) {
      return
    }

    if (isPackingControllable(value)) {
      actions.value = []
      actions.value.push({
        name: 'Packing control',
        href: `/shipment/${value.id}/packing-control/`,
        props: {
          item: value
        }
      })
    }
  },
  {
    immediate: false
  }
)

const getShipment = async () => {
  await withAsyncLoading(async () => {
    await getShipmentById({ id: props.id })
  })
}

onMounted(async () => {
  await getShipment()
})
</script>
<style scoped lang="less">
.actions-header {
  margin: 0 0 5px 0;
}
</style>
