export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const randomNumberBetween = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min

export const calculateBackoffJitter = (miliseconds) =>
  miliseconds / 2 + randomNumberBetween(0, miliseconds / 2)

// https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/
export const sleepWithBackoffFullJitter = (miliseconds: number) =>
  sleep(calculateBackoffJitter(miliseconds))

export const removeAutoFocus = () => (document.activeElement as HTMLElement).blur()
