import { IArticle } from '@graphql/article/types'
import { IArticleState } from '@store/modules/article/types'
import { MutationTree } from 'vuex'

const mutations: MutationTree<IArticleState> = {
  setActiveArticle(state, payload: IArticle) {
    state.activeArticle = payload
  }
}

export default mutations
