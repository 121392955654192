import Base from '@graphql/base'
import {
  IBulkMarkPrePackedRequest,
  IBulkMarkPrePackedResponse,
  IGetPackableItemsByLoadCarrierIdRequest,
  IGetPackableItemsByLoadCarrierIdResponse,
  IGetPackingListByContextRequest,
  IGetPackingListByContextResponse,
  IGetPackStationsResponse,
  IMarkPackedRequest,
  IMarkPackedResponse,
  IPrePackColloRequest,
  IPrePackColloResponse
} from '@graphql/pack/types'
import { AxiosInstance } from 'axios'
import GRAPHQL_API_CLIENT from './client'

class Pack extends Base {
  constructor(axios: AxiosInstance) {
    super(axios, 'pack')
  }

  public async getPackStations() {
    return this.makeRequest<IGetPackStationsResponse>('getPackStationsQuery')
  }

  public async getPackableItemsByLoadCarrierId(request: IGetPackableItemsByLoadCarrierIdRequest) {
    return this.makeRequest<IGetPackableItemsByLoadCarrierIdResponse>(
      'getPackableItemsByLoadCarrierIdQuery',
      request
    )
  }

  public async getPackingListByContext(request: IGetPackingListByContextRequest) {
    return this.makeRequest<IGetPackingListByContextResponse>('getPackingListByContextQuery', {
      input: request
    })
  }

  public async markPacked(request: IMarkPackedRequest) {
    return this.makeRequest<IMarkPackedResponse>('markPackedMutation', request)
  }

  public async bulkMarkPrePacked(request: IBulkMarkPrePackedRequest) {
    return this.makeRequest<IBulkMarkPrePackedResponse>('bulkMarkPrePackedMutation', request)
  }

  public async prePackCollo(request: IPrePackColloRequest) {
    return this.makeRequest<IPrePackColloResponse>('prePackColloMutation', request)
  }
}

export default new Pack(GRAPHQL_API_CLIENT)
