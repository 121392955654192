import Base from '@graphql/base'
import {
  IManageStockCountResponse,
  IRelocateFromTransitStockResponse,
  IRelocateStockResponse,
  IRelocateToTransitStockResponse,
  IGetStockLevelRequest,
  IGetStockLevelResponse
} from '@graphql/stock/types'
import { IRelocateItem, IRelocateTransitItem, IStockItem } from '@store/modules/stock/types'
import { AxiosInstance } from 'axios'
import GRAPHQL_API_CLIENT from './client'

class Stock extends Base {
  constructor(protected axios: AxiosInstance) {
    super(axios, 'stock')
  }

  public async count(stockItem: IStockItem) {
    return this.makeRequest<IManageStockCountResponse>('manageStockCount', {
      stockCount: {
        items: Array.isArray(stockItem) ? stockItem : [stockItem]
      }
    })
  }

  public async relocate(relocateItem: IRelocateItem) {
    return this.makeRequest<IRelocateStockResponse>('relocateStock', relocateItem)
  }

  public async relocateToTransitStock(relocateItem: IRelocateTransitItem) {
    return this.makeRequest<IRelocateToTransitStockResponse>('relocateToTransitStock', relocateItem)
  }

  public async relocateFromTransitStock(relocateItem: IRelocateTransitItem) {
    return this.makeRequest<IRelocateFromTransitStockResponse>(
      'relocateFromTransitStock',
      relocateItem
    )
  }

  public async getStockLevels(request: IGetStockLevelRequest) {
    return this.makeRequest<IGetStockLevelResponse>('stockLevelQuery', request)
  }
}

export default new Stock(GRAPHQL_API_CLIENT)
