import { IPurchaseOrderState } from '@store/modules/purchase-order/types'
import { IRootState } from '@store/types'
import { GetterTree } from 'vuex'

const getters: GetterTree<IPurchaseOrderState, IRootState> = {
  activePurchaseOrder: (state) => state.activePurchaseOrder,
  openPurchaseOrders: (state) => state.openPurchaseOrders
}

export default getters
