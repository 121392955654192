import { IShipment } from '@graphql/shipment/types'
import { IShipmentState } from '@store/modules/shipment/types'
import { MutationTree } from 'vuex'

const mutations: MutationTree<IShipmentState> = {
  setShipment(state, payload: Partial<IShipment>): void {
    state.shipment = payload
  },
  setActiveShipment(state, payload: Partial<IShipment>): void {
    state.shipment = payload
  }
}

export default mutations
