import { ISearchResult } from '@graphql/search/types'
import { PluralResultCallback, SingleResultCallback } from '@services/search/search'
import AbstractSearchStrategy from '@services/search/strategy/abstract-strategy'
import { SearchResults } from '@store/modules/search/types'

export default class DefaultWithoutRoutingSearchStrategy extends AbstractSearchStrategy {
  public async handleSingularResult(result: ISearchResult, callback?: SingleResultCallback) {
    if (callback) {
      callback(result)
    }
  }

  public async handlePluralResult(results: SearchResults, callback?: PluralResultCallback) {
    if (callback) {
      callback(results)
    }
  }
}
