import { IRootState } from '@store/types'
import { GetterTree } from 'vuex'
import { ICountItem, IProjectCountState } from './types'

const getters: GetterTree<IProjectCountState, IRootState> = {
  isActive(state): boolean {
    return state.items.length > 0
  },

  items(state): ICountItem[] {
    return state.items
  },

  itemCount(state): number {
    return state.items.length
  },

  count(state): number {
    return state.isActive ? 1 : 0
  }
}

export default getters
