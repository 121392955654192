<template>
  <table>
    <thead v-if="slots.header">
      <slot name="header" />
    </thead>
    <tbody>
      <slot />
    </tbody>
    <tfoot v-if="slots.footer">
      <slot name="footer" />
    </tfoot>
  </table>
</template>
<script lang="ts" setup>
import { useSlots } from 'vue'

const slots = useSlots()
</script>
