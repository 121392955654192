import pick from '@graphql/pick'
import {
  IAddLoadCarrierRequest,
  IAddLoadCarrierResponse,
  IGetLoadCarrierByIdRequest,
  IGetLocationToVisitResponse,
  ILoadCarrier,
  IPickBatch,
  ISavePickBatchStateRequest,
  ISavePickBatchStateResponse,
  IStartPickProcessContext
} from '@graphql/pick/types'
import {
  IMarkCompleteRequest,
  IMarkPickedResult,
  IProjectPickState,
  IUpdatePickBatchLineContext
} from '@store/modules/projects/pick/types'
import { IRootState } from '@store/types'
import { ActionTree } from 'vuex'
import { ID } from '@graphql/types'

const actions: ActionTree<IProjectPickState, IRootState> = {
  async addLoadCarrier(context, request: IAddLoadCarrierRequest): Promise<IAddLoadCarrierResponse> {
    const response = await pick.addLoadCarrier(request)

    return response.data.data
  },
  async getLoadCarrierById(
    context,
    request: IGetLoadCarrierByIdRequest
  ): Promise<Partial<ILoadCarrier>> {
    const response = await pick.getLoadCarrierById(request)

    return response.data.data.getLoadCarrierById
  },
  async addProject({ commit }, payload: Partial<IPickBatch>) {
    await commit('addProject', payload)
  },
  async getLocationToVisit(context, pickBatchId: number): Promise<IGetLocationToVisitResponse> {
    const response = await pick.getLocationToVisit(pickBatchId)

    return response.data.data
  },
  async getProcessablePickBatch(): Promise<Partial<IPickBatch | null>> {
    const response = await pick.getProcessablePickBatch()

    return response.data.data.getProcessablePickBatch
  },
  async markPicked(context, request: IMarkCompleteRequest): Promise<IMarkPickedResult> {
    const response = await pick.markPicked(request)

    return response.data.data.markPicked
  },
  async removeProject({ commit }, pickBatchId: ID) {
    await commit('remove', pickBatchId)
  },
  async savePickBatchLineState(
    context,
    request: ISavePickBatchStateRequest
  ): Promise<ISavePickBatchStateResponse> {
    const response = await pick.savePickBatchLineState(request)

    return response.data.data
  },
  async startPickProject({ dispatch }, request: IStartPickProcessContext) {
    const response = await pick.startPickProcess(request)

    await dispatch('addProject', response.data.data.startPickProcess)
  },
  async updatePickBatchLine({ commit }, context: IUpdatePickBatchLineContext) {
    await commit('updatePickBatchLine', context)
  },
  async updateProcessedLines({ commit }) {
    await commit('updateProcessedLines')
  },
  async updateProject({ commit }, payload: Partial<IPickBatch>) {
    await commit('updateProject', payload)
  },
  async removeProjects({ commit }) {
    await commit('removeProjects')
  }
}

export default actions
