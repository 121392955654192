import { ILocationState } from '@store/modules/location/types'
import { IRootState } from '@store/types'
import { GetterTree } from 'vuex'

const getters: GetterTree<ILocationState, IRootState> = {
  activeLocation: (state) => state.activeLocation,
  suggestions: (state) => state.suggestions
}

export default getters
