import { ISearchResult } from '@graphql/search/types'
import { PluralResultCallback, SingleResultCallback } from '@services/search/search'
import { SearchResults } from '@store/modules/search/types'
import AbstractSearchStrategy from './abstract-strategy'

export default class ScanInputStrategy extends AbstractSearchStrategy {
  /**
   * @inheritdoc
   */
  public async handleSingularResult(
    result: ISearchResult,
    callback?: SingleResultCallback
  ): Promise<void> {
    if (callback) {
      callback(result)
    }
  }

  /**
   * @inheritdoc
   */
  public async handlePluralResult(
    results: SearchResults,
    callback?: PluralResultCallback
  ): Promise<void> {
    if (callback) {
      callback(results)
    }
  }
}
