import {
  IBookReadyRequest,
  IDeleteGoodsEntryRequest,
  IGoodsEntryQueryContext,
  IGoodsEntryRequest,
  IGoodsReceipt,
  IGoodsReceiptLine
} from '@graphql/goodsReceipt/types'
import { IInboundProject, IInboundProjectItem } from '@store/modules/projects/inbound/types'
import { useStore } from '@store/store'
import { computed } from 'vue'
import { ID } from '@graphql/types'

export default () => {
  const store = useStore()

  const isActive = computed<boolean>(() => store.getters['projects/inbound/isActive'])
  const itemCount = computed<number>(() => store.getters['projects/inbound/itemCount'])
  const items = computed<IInboundProjectItem[]>(() => store.getters['projects/inbound/items'])
  const goodsEntries = computed<IGoodsReceipt | undefined>(
    () => store.getters['projects/inbound/goodsEntries']
  )
  const activeGoodsEntry = computed<IInboundProjectItem | undefined>(
    () => store.getters['projects/inbound/activeGoodsEntry']
  )

  const add = async (context: IInboundProject) =>
    store.dispatch('projects/inbound/addInboundProject', context)
  const setActiveGoodsEntry = async (context: IInboundProjectItem | undefined) =>
    store.dispatch('projects/inbound/setActiveGoodsEntry', context)
  const createGoodsEntry = async (context: IGoodsEntryRequest) =>
    store.dispatch('projects/inbound/createGoodsEntry', context)
  const getGoodsEntries = async (context: IGoodsEntryQueryContext) =>
    store.dispatch('projects/inbound/getGoodsEntries', context)
  const getGoodsEntry = async (id: ID) => store.dispatch('projects/inbound/getGoodsEntry', id)
  const markItemAsDone = async (context: IGoodsReceiptLine) =>
    store.dispatch('projects/inbound/markItemDone', context)
  const markAsBookReady = async (context: IBookReadyRequest) =>
    store.dispatch('projects/inbound/markAsBookReady', context)
  const removeProject = async (id: ID) => store.dispatch('projects/inbound/removeProject', id)
  const removeGoodsEntry = async (context: IDeleteGoodsEntryRequest) =>
    store.dispatch('projects/inbound/removeGoodsEntry', context)
  const removeProjects = async () => store.dispatch('projects/inbound/removeProjects')

  return {
    isActive,
    itemCount,
    goodsEntries,
    items,
    activeGoodsEntry,
    add,
    setActiveGoodsEntry,
    createGoodsEntry,
    getGoodsEntries,
    getGoodsEntry,
    markItemAsDone,
    markAsBookReady,
    removeProject,
    removeProjects,
    removeGoodsEntry
  }
}
