import { IPurchaseOrder } from '@graphql/purchaseOrder/types'
import { useStore } from '@store/store'
import { computed } from 'vue'
import { ID } from '@graphql/types'

export default () => {
  const store = useStore()

  const purchaseOrder = computed<IPurchaseOrder>(
    () => store.getters['purchaseOrder/activePurchaseOrder']
  )
  const openPurchaseOrders = computed<IPurchaseOrder[]>(
    () => store.getters['purchaseOrder/openPurchaseOrders']
  )

  const getPurchaseOrderById = async (id: ID) =>
    store.dispatch('purchaseOrder/getPurchaseOrderById', id)

  return {
    purchaseOrder,
    openPurchaseOrders,
    getPurchaseOrderById
  }
}
