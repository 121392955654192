import { GetterTree } from 'vuex'
import { IRootState } from '../../types'
import { IAuthState, IUser } from './types'

const getters: GetterTree<IAuthState, IRootState> = {
  isAuthenticated(state): boolean {
    return state.token !== undefined
  },

  user(state): IUser | undefined {
    return state.user
  },

  token(state): string | undefined {
    return state.token
  },

  tokenLifetime(state): number | undefined {
    return state.tokenLifetime
  }
}

export default getters
