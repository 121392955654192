import { isPackingControllable } from '@/functions/shipment'
import { ISearch, TypeName } from '@graphql/search/types'
import { IShipment } from '@graphql/shipment/types'
import AbstractSearchStrategy from '@services/search/strategy/abstract-strategy'
import { SearchResults, UnionTypeSearchResult } from '@store/modules/search/types'
import { f7 } from 'framework7-vue'

export default class PackingControlStrategy extends AbstractSearchStrategy {
  public async parseSearchResults(searchResults: ISearch): Promise<SearchResults> {
    let results = await super.parseSearchResults(searchResults, TypeName.Shipment)

    results = results
      .filter((s) => {
        if (!s.partner) {
          return
        }
        return s.partner.packingControl
      })
      .filter((shipment) => isPackingControllable(shipment as IShipment))

    return results
  }

  public async handleSingularResult(item: UnionTypeSearchResult): Promise<void> {
    const url = `/shipment/${item.id}/packing-control/`

    await f7.views.main.router.navigate(url, {
      props: { item },
      reloadCurrent: f7.views.main.router.currentRoute.url === url
    })
  }
}
