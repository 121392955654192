import {
  IPrintContext,
  IPrintedDocumentsResult,
  IPrintSubjectDocumentsRequest
} from '@graphql/document/types'
import { useStore } from '@store/store'
import { confirmYesNo } from '@/functions/dialog'
import { f7 } from 'framework7-vue'
import { toast } from '@services/toast'
import useSettings from '@composables/useSettings'
import useLoading from '@composables/useLoading'

export default () => {
  const store = useStore()
  const { packStation } = useSettings()
  const { withTextPreloaderAndNetworkErrorHandlingAsync } = useLoading()

  const printSubjectDocuments = async (
    payload: IPrintSubjectDocumentsRequest
  ): Promise<IPrintedDocumentsResult> => store.dispatch('document/printSubjectDocuments', payload)

  const printDocumentsFor = async (
    context: IPrintContext,
    yesCallback?: () => void,
    noCallback?: () => void,
    showErrorsAsToast = true,
    requiresConfirmation: false
  ) => {
    try {
      let result: IPrintedDocumentsResult | null = await printSubjectDocuments({
        context,
        packStationId: packStation.value?.id
      })

      if (!result || result.total === 0) {
        return result
      }

      if (requiresConfirmation) {
        await confirmYesNo({
          title: `Are all documents (${result.total}) printed?`,
          noButtonText: 'No, reprint',
          noButtonCallback: async () => {
            result = await reprintDocumentsFor(context, yesCallback, noCallback, false)
          },
          yesButtonCallback: yesCallback
        })
      }

      return result
    } catch (e) {
      if (showErrorsAsToast) {
        await toast.error(e.message)
      }

      throw e
    }
  }

  const reprintDocumentsFor = async (
    context: IPrintContext,
    yesCallback?: () => void,
    noCallback?: () => void,
    showErrorAsToast = true,
    requiresConfirmation: true
  ) => {
    let printResult: IPrintedDocumentsResult | null = null

    await f7.dialog.confirm(
      `Are you sure you want to reprint the documents for ${context.type.toLowerCase()} #${
        context.identifier
      }`,
      async () => {
        await withTextPreloaderAndNetworkErrorHandlingAsync({
          title: 'Printing documents...',
          maxRetries: 1,
          callback: async () => {
            printResult = await printDocumentsFor(
              context,
              yesCallback,
              noCallback,
              showErrorAsToast,
              requiresConfirmation
            )
          }
        })
      }
    )

    return printResult
  }

  return {
    printSubjectDocuments,
    printDocumentsFor,
    reprintDocumentsFor
  }
}
