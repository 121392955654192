import { IRootState } from '@store/types'
import { IPackState } from '@store/modules/pack/types'
import actions from '@store/modules/pack/actions'
import { Module } from 'vuex'

const state: IPackState = {}

const pack: Module<IPackState, IRootState> = {
  namespaced: true,
  state,
  getters: {},
  actions,
  mutations: {}
}

export default pack
