import { ILocation } from '@graphql/location/types'
import { ILocationState } from '@store/modules/location/types'
import { MutationTree } from 'vuex'

const mutations: MutationTree<ILocationState> = {
  setActiveLocation(state, payload: ILocation | undefined) {
    state.activeLocation = payload
  },

  setLocationSuggestions(state, payload: ILocation[]) {
    state.suggestions = payload
  }
}

export default mutations
