<template>
  <slot v-if="granted" />
</template>
<script lang="ts" setup>
import useAuthentication from '@composables/useAuthentication'
import { ref } from 'vue'

const props = defineProps<{
  attributes: string | string[]
}>()

const { isGranted } = useAuthentication()
const granted = ref(true)

granted.value = isGranted(props.attributes)
</script>
