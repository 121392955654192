<template>
  <f7-page :page-content="false" class="page-with-subnavbar">
    <navigation>
      <f7-subnavbar class="no-padding">
        <search-bar
          :search-strategy="searchStrategy"
          :search-type="searchType"
          @result="onResult"
          @results="onResults"
          @no-result="onNoResult"
          style="width: 100%"
        />
      </f7-subnavbar>
    </navigation>

    <f7-page-content>
      <f7-block class="margin">
        <f7-progressbar :infinite="true" class="no-margin" />
        <p class="text-align-center">
          <strong> Please enter/scan: {{ searchType }} </strong>
        </p>
      </f7-block>

      <search-result-list
        :items="preFilledItems"
        @result="onResult"
        :key="0"
        :is-loading="isLoading"
        class="no-margin"
      />
    </f7-page-content>

    <f7-popup
      :opened="popupOpened"
      class="search-results-popup"
      @popup:closed="popupOpened = false"
      :close-on-ecape="false"
      :close-by-backdrop-click="false"
      :tablet-fullscreen="true"
      :swipe-to-close="true"
    >
      <f7-navbar>
        <f7-nav-title> Select {{ searchType }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <f7-icon material="close" />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <search-result-list
        :items="currentSearchResults"
        :search-strategy="defaultSearchStrategy"
        @result="onResult"
        :is-loading="isLoading"
        :key="1"
      />
    </f7-popup>
  </f7-page>
</template>
<script lang="ts" setup>
import { lcFirst } from '@/utilities/string'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import SearchResultList from '@components/search/ResultList.vue'
import useSearch from '@composables/useSearch'
import { SearchStrategy } from '@services/search/search'
import { toast } from '@services/toast'
import { SearchResults, UnionTypeSearchResult } from '@store/modules/search/types'
import { store } from '@store/store'
import { f7 } from 'framework7-vue'
import { computed, onMounted, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    items: UnionTypeSearchResult[]
    /**
     * Navigates to the given url after selection has been made.
     */
    successUrl?: string
    /**
     * Determines which search strategy will be executed
     * @see SearchStrategy
     */
    searchStrategy: string
    /**
     * When set, it will be used to filter the search results by the given type (ensures only result of given type).
     * @see TypeName
     */
    searchType?: string
    /**
     * Restricts the search results by a given set via the `items` property.
     * E.g. you want to check if an item in the given set via search matches
     */
    restricted: boolean
    /**
     * Callback to fetch more items for the restricted/prefilled items set
     */
    itemsCallback?: () => void
    /**
     * Props to pass through for the success page.
     */
    successProps?: object
  }>(),
  {
    items: () => [],
    successUrl: undefined,
    searchStrategy: SearchStrategy.Default,
    searchType: undefined,
    restricted: false,
    itemsCallback: undefined,
    successProps: undefined
  }
)

const emits = defineEmits<{
  (e: 'item-selected', payload: UnionTypeSearchResult): void
}>()

const popupOpened = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const preFilledItems = ref<SearchResults>([])
const searchResults = ref<SearchResults>([])

const currentSearchResults = computed<SearchResults>(() => searchResults.value)
const defaultSearchStrategy = computed<string>(() => SearchStrategy.ScanInput)

const { onNoResult } = useSearch()

const notFoundInListNotification = () => {
  toast
    .error('Result found, but not allowed to select. Only items visible in the list can be used.')
    .open()
}

const isInRestrictedResults = (item: UnionTypeSearchResult) => {
  return (
    preFilledItems.value.filter(
      (i: UnionTypeSearchResult) => i.__typename === item.__typename && i.id === item.id
    ).length > 0
  )
}

const onResult = async (result: UnionTypeSearchResult) => {
  if (props.restricted && !isInRestrictedResults(result)) {
    notFoundInListNotification()

    return
  }

  if (popupOpened.value) {
    popupOpened.value = false
  }

  //TODO: Replace hack...
  store.commit(`${lcFirst(result.__typename)}/setActive${result.__typename}`, result)

  emits('item-selected', result)

  if (props.successUrl) {
    f7.views.main.router.navigate(props.successUrl, { props: props.successProps })
  }
}

const onResults = (results: UnionTypeSearchResult[]) => {
  searchResults.value = results

  popupOpened.value = true
}

watch(
  () => props.items,
  (items) => {
    preFilledItems.value = items
  }
)

onMounted(async () => {
  isLoading.value = true

  preFilledItems.value = props.items

  if (props.itemsCallback) {
    const items = await props.itemsCallback()
    preFilledItems.value = preFilledItems.value
      .concat(items)
      .filter((item, index, self) => {
        return index === self.findIndex((s) => s.id === item.id)
      })
      .sort((a, b) => {
        // @ts-ignore
        return a.name.localeCompare(b.name)
      })
  }

  isLoading.value = false
})
</script>
