import { GetterTree } from 'vuex'
import { IRootState } from '../../types'
import { SearchResults, ISearchState } from './types'

const getters: GetterTree<ISearchState, IRootState> = {
  searchResults(state): SearchResults {
    return state.searchResults
  }
}

export default getters
