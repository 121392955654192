import { IVoter, VoterResult } from '@services/security/types'
import { IUser } from '@store/modules/auth/types'

export class PermissionVoter implements IVoter {
  vote(user: IUser, attributes: string[]): VoterResult {
    let result = VoterResult.ACCESS_ABSTAIN

    for (let index = 0; index < attributes.length; index++) {
      if (!this.isValidAttribute(attributes[index])) {
        continue
      }

      if (user.permissions.includes(attributes[index])) {
        return VoterResult.ACCESS_GRANTED
      }
      result = VoterResult.ACCESS_DENIED
    }

    return result
  }

  public supports(attributes: string[]): boolean {
    return attributes.filter((attribute) => this.isValidAttribute(attribute)).length > 0
  }

  private isValidAttribute(attribute: string): boolean {
    return attribute.startsWith('PERMISSION_')
  }
}
