import smb3Bump from '@assets/audio/smb3_bump.wav'
import smb3Coin from '@assets/audio/smb3_coin.wav'
import smb3Pause from '@assets/audio/smb3_pause.wav'
import smb1Up from '@assets/audio/smb_1-up.wav'
import smbBowserFalls from '@assets/audio/smb_bowserfalls.wav'
import smbStomp from '@assets/audio/smb_stomp.wav'

export async function playSound(filename: string) {
  const event = new CustomEvent('play-sound', { detail: filename })

  dispatchEvent(event)
}

export async function playSoundListener(element: HTMLAudioElement, event: Event | CustomEvent) {
  // TODO: find a way to detect if a sound is already playing, then enqueue incoming sounds.

  // @ts-ignore-line
  const audio = new Audio(event.detail.toString())
  await audio.play()
  audio.remove()

  await event.stopImmediatePropagation()
}

export class SoundBoard {
  private static instance: SoundBoard

  async playSearchNoHitsSound() {
    await playSound(smb3Bump)
  }

  async playSearchSingleHit() {
    await playSound(smb3Coin)
  }

  async playSearchMultiHitSound() {
    await playSound(smb3Pause)
  }

  async playFailedSound() {
    await playSound(smbBowserFalls)
  }

  async playSuccessSound() {
    await playSound(smbStomp)
  }

  async playTaskCompleteSound() {
    await playSound(smb1Up)
  }

  public static getInstance(): SoundBoard {
    if (!this.instance) {
      this.instance = new SoundBoard()
    }

    return this.instance
  }
}

export const soundBoard = SoundBoard.getInstance()
