<template>
  <f7-page :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar :search-id="searchId" expandable />
    </navigation>

    <f7-page-content>
      <template v-if="article">
        <article-information-card
          v-model="showAllBarcodes"
          :article="article"
          :is-loading="isLoading"
        />
        <article-stock-card :article="article" :is-loading="isLoading" />
      </template>
    </f7-page-content>

    <template v-if="article">
      <app-actions :actions="actions" grid @action-clicked="onActionClick" :fixed="true" />

      <barcode-sheet :article="article" v-model="showAllBarcodes"></barcode-sheet>
      <previous-location-sheet
        v-model="showPreviousLocations"
        :previous-locations="previousLocations"
      ></previous-location-sheet>
      <stock-count-sheet
        v-model="showStockCounts"
        :article-stock-counts="articleStockCounts"
      ></stock-count-sheet>
      <stock-sheet :article="article" v-model="showAllStock"></stock-sheet>
    </template>
  </f7-page>
</template>
<script lang="ts" setup>
import { IAction } from '@components/actions'
import AppActions from '@components/AppActions.vue'
import ArticleInformationCard from '@components/Article/InformationCard.vue'
import ArticleStockCard from '@components/Article/StockCard.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import useAction from '@composables/useAction'
import useArticle from '@composables/useArticle'
import useLoading from '@composables/useLoading'
import { IArticle, IPreviousLocation } from '@graphql/article/types'
import { IArticleLocation, ILocation } from '@graphql/location/types'
import { TypeName } from '@graphql/search/types'
import { SearchStrategy } from '@services/search/search'
import { getPhysicalLocations } from '@store/modules/article/helper'
import { computed, onBeforeMount, ref } from 'vue'
import { ID, IEdge } from '@graphql/types'
import { IStockCountItem } from '@graphql/stock/types'
import PreviousLocationSheet from '@components/Article/PreviousLocationSheet.vue'
import BarcodeSheet from '@components/Article/BarcodeSheet.vue'
import StockCountSheet from '@components/Article/StockCountSheet.vue'
import { ActionGroup } from '@components/ActionGroup'
import StockSheet from '@components/Article/StockSheet.vue'

const props = defineProps<{
  id: ID
}>()

const { getArticle, articleLocations } = useArticle()
const { isLoading, withAsyncLoading } = useLoading()
const { onActionClick } = useAction()

const article = ref<IArticle | undefined>()
const articleStockCounts = ref<IStockCountItem[]>([])
const previousLocations = ref<IPreviousLocation[]>([])
const showPreviousLocations = ref<boolean>(false)
const showAllBarcodes = ref<boolean>(false)
const showAllStock = ref<boolean>(false)
const showStockCounts = ref<boolean>(false)
const actions = ref<IAction[]>([])
const locationToLoadThreshold = ref<number>(4)

const locations = computed<ILocation[]>(() =>
  articleLocations.value.map((articleLocation) => articleLocation.location)
)
const physicalLocations = computed<ILocation[]>(() => getPhysicalLocations(locations.value))

const resolvePhysicalLocations = async (): Promise<ILocation[]> => {
  if (locations.value.length < locationToLoadThreshold.value) {
    return physicalLocations.value
  }

  const response = await getArticle({
    id: (article.value as IArticle).id,
    articleLocationLimit: null
  })
  const loadedLocations = response.articleLocation.map((al: IArticleLocation) => al.location)

  return getPhysicalLocations(loadedLocations)
}

onBeforeMount(async () => {
  await withAsyncLoading(async () => {
    article.value = await getArticle({
      id: props.id,
      articleLocationLimit: locationToLoadThreshold.value,
      previousLocationsLimit: 10
    })

    articleStockCounts.value = article.value.articleStockCountItems.edges.map(
      (edge: IEdge<IStockCountItem>) => edge.node
    )

    previousLocations.value = article.value.previousLocations.edges.map(
      (edge: IEdge<IPreviousLocation>) => edge.node
    )
  })

  actions.value.push({
    name: 'Start count',
    href: '/scan-input/',
    icon: 'magnifying-glass-location',
    group: ActionGroup.OPERATIONS,
    props: {
      items: physicalLocations.value,
      searchType: TypeName.Location,
      searchStrategy: SearchStrategy.ScanInput,
      successUrl: '/count/',
      itemsCallback: resolvePhysicalLocations
    }
  })

  if (physicalLocations.value.length > 0) {
    actions.value.push({
      name: 'Relocate',
      href: '/scan-input/',
      icon: 'arrows-left-right-to-line',
      group: ActionGroup.OPERATIONS,
      props: {
        items: getPhysicalLocations(locations.value),
        searchType: TypeName.Location,
        searchStrategy: SearchStrategy.ScanInput,
        successUrl: '/remove/',
        restricted: true,
        itemsCallback: resolvePhysicalLocations
      }
    })
  }

  actions.value.push({
    name: 'Previous locations',
    icon: 'history',
    group: ActionGroup.VIEW,
    callback: () => {
      showPreviousLocations.value = true
    }
  })

  actions.value.push({
    name: 'Barcodes',
    icon: 'barcode',
    group: ActionGroup.VIEW,
    callback: () => {
      showAllBarcodes.value = true
    }
  })

  actions.value.push({
    name: 'Stock',
    icon: 'cubes',
    group: ActionGroup.VIEW,
    callback: () => {
      showAllStock.value = true
    }
  })

  actions.value.push({
    name: 'Stock counts',
    icon: 'abacus',
    group: ActionGroup.VIEW,
    callback: () => {
      showStockCounts.value = true
    }
  })
})
</script>
<style scoped lang="less">
.actions-header {
  margin: 0 0 5px 0;
}
</style>
