import { IArticle } from '@graphql/article/types'
import { IArticleLocation } from '@graphql/location/types'
import { IArticleRequestContext } from '@store/modules/article/types'
import { useStore } from '@store/store'
import { computed } from 'vue'

export default function () {
  const store = useStore()

  const article = computed<IArticle | null>(() => store.getters['article/activeArticle'])
  const activeArticleCount = computed<number>(() => {
    if (!article.value || !article.value.stockLevel) {
      return 0
    }
    return article.value.stockLevel.physicalTotal
  })
  const hasClassifications = computed<boolean>(() => {
    if (!article.value || !article.value.classifications) {
      return false
    }

    return article.value.classifications.length > 0
  })

  const setActiveArticle = async (article: IArticle) =>
    await store.dispatch('article/setActiveArticle', article)
  const getArticle = async (context: IArticleRequestContext): Promise<IArticle> =>
    await store.dispatch('article/getArticle', context)
  const getAllArticleLocations = async (article: IArticle) => {
    if (article.articleLocation && article.articleLocation.length < 4) {
      return article.articleLocation.map((al) => al.location)
    }

    article = await getArticle({ id: article.id, articleLocationLimit: null })

    return article.articleLocation.map((al) => al.location)
  }

  const articleLocations = computed<IArticleLocation[]>(() => {
    if (!article.value || article.value.articleLocation.length === 0) {
      return []
    }

    return article.value.articleLocation
  })

  return {
    activeArticle: article,
    activeArticleCount,
    hasClassifications,
    articleLocations,
    setActiveArticle,
    getArticle,
    getAllArticleLocations
  }
}
