import Base from '@graphql/base'
import { IGraphQLResponse } from '@graphql/types'
import { ISearchFilter } from '@services/search/search'
import { AxiosInstance, AxiosResponse } from 'axios'
import GRAPHQL_API_CLIENT from './client'

class Search extends Base {
  constructor(axios: AxiosInstance) {
    super(axios, 'search')
  }

  public async scan(
    q: string,
    exact = false,
    type: string | null = null,
    filters: ISearchFilter[] = []
  ): Promise<AxiosResponse<IGraphQLResponse>> {
    return this.makeRequest('search', {
      q,
      exact,
      type,
      filters
    })
  }
}

export default new Search(GRAPHQL_API_CLIENT)
