<template>
  <f7-page :page-content="false">
    <navigation />
    <f7-page-content>
      <f7-block-title class="margin-top"> Pick projects </f7-block-title>
      <f7-block v-if="!itemCount">
        <p>No open pick tasks.</p>
      </f7-block>
      <f7-list media-list>
        <f7-list-item v-for="item in items" link="#" :key="item.id" @click="onClick">
          <template #title> Pick Batch: #{{ item.id }} </template>
          <template #subtitle>
            Progress: {{ item.processedLines }} / {{ item.lineCount }}
          </template>
        </f7-list-item>
      </f7-list>
      <is-granted :attributes="Permissions.SCANNER_DELETE_PROJECTS">
        <f7-fab position="right-bottom" @click="onDeleteProjects">
          <font-awesome-icon :icon="['far', 'trash']" />
        </f7-fab>
      </is-granted>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import Navigation from '@components/AppNavigation.vue'
import useProjectPick from '@composables/useProjectPick'
import { f7 } from 'framework7-vue'
import { Permissions } from '@services/security/permissions'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import IsGranted from '@components/IsGranted.vue'
import { confirmYesNo } from '@/functions/dialog'
import { toast } from '@services/toast'

const { itemCount, items, removeProjects } = useProjectPick()

const onClick = async () => {
  return f7.views.main.router.navigate(`/pick-entry/`)
}

const onDeleteProjects = async () => {
  await confirmYesNo({
    title: 'Are you sure that you want to clear all pick projects?',
    yesButtonCallback: async () => {
      await removeProjects()

      await toast.success('Cleared pick projects.').open()

      await f7.views.main.router.navigate('/home/')
    }
  })
}
</script>
