import { ActionTree } from 'vuex'
import {
  IGetLoadCarrierByReferenceRequest,
  IGetPickBatchSortStateRequest,
  IGetSortResultRequest,
  IMarkPickBatchSortedRequest,
  IMarkSortLoadCarrierSortedRequest,
  IPutItemInCompartmentRequest,
  ISortState,
  IWantsNewCompartmentRequest
} from '@graphql/sort/types'
import { IRootState } from '@store/types'
import sortApi from '@graphql/sort'

const actions: ActionTree<ISortState, IRootState> = {
  async getLoadCarrierByReference(context, request: IGetLoadCarrierByReferenceRequest) {
    const response = await sortApi.getLoadCarrierByReference(request)

    return response.data.data.getLoadCarrierByReference
  },
  async putItemInCompartment(context, request: IPutItemInCompartmentRequest) {
    const response = await sortApi.putItemInCompartment(request)

    return response.data.data.putItemInCompartment
  },
  async wantsNewCompartment(context, request: IWantsNewCompartmentRequest) {
    const response = await sortApi.wantsNewCompartment(request)

    return response.data.data.wantsNewCompartment
  },
  async getSortResult(context, request: IGetSortResultRequest) {
    const response = await sortApi.getSortResult(request)

    return response.data.data.getSortResult
  },
  async getPickBatchSortState(context, request: IGetPickBatchSortStateRequest) {
    const response = await sortApi.getPickBatchSortState(request)

    return response.data.data.getPickBatchSortState
  },
  async markSortLoadCarrierSorted(context, request: IMarkSortLoadCarrierSortedRequest) {
    const response = await sortApi.markSortLoadCarrierSorted(request)

    return response.data.data.markSortLoadCarrierSorted
  },
  async markPickBatchSorted(context, request: IMarkPickBatchSortedRequest) {
    const response = await sortApi.markPickBatchSorted(request)

    return response.data.data.markPickBatchSorted
  }
}

export default actions
