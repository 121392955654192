import { computed } from 'vue'
import { useStore } from '@store/store'
import { ISortingProject } from '@store/modules/projects/sorting/types'
import { ID } from '@graphql/types'

export default () => {
  const store = useStore()

  const isActive = computed<boolean>(() => store.getters['projects/sorting/isActive'])
  const itemCount = computed<number>(() => store.getters['projects/sorting/itemCount'])
  const items = computed<ISortingProject[]>(() => store.getters['projects/sorting/items'])
  const activeItem = computed<ISortingProject | null>(
    () => store.getters['projects/sorting/activeItem']
  )

  const addProject = async (project: ISortingProject) =>
    store.dispatch('projects/sorting/addProject', project)

  const updateProject = async (project: ISortingProject) =>
    store.dispatch('projects/sorting/updateProject', project)
  const clearProjects = async () => store.dispatch('projects/sorting/clearProjects')

  const removeProject = async (pickBatchId: ID) =>
    store.dispatch('projects/sorting/removeProject', pickBatchId)

  return {
    isActive,
    itemCount,
    items,
    activeItem,
    clearProjects,
    addProject,
    updateProject,
    removeProject
  }
}
