import { IProjectPickState } from '@store/modules/projects/pick/types'
import { IRootState } from '@store/types'
import { GetterTree } from 'vuex'

const getters: GetterTree<IProjectPickState, IRootState> = {
  isActive: (state) => !!state.assignedPickBatch,
  itemCount: (state) => (state.assignedPickBatch !== undefined ? 1 : 0),
  items: (state) => [state.assignedPickBatch],
  count: (state) => (state.assignedPickBatch !== undefined ? 1 : 0),
  assignedPickBatch: (state) => state.assignedPickBatch
}

export default getters
