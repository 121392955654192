import pickApi from '@graphql/pick'
import { IPickBatchState } from '@store/modules/pick-batch/types'
import { IRootState } from '@store/types'
import { ActionTree } from 'vuex'
import { ID } from '@graphql/types'

const actions: ActionTree<IPickBatchState, IRootState> = {
  async getById(context, pickBatchId: ID) {
    const response = await pickApi.getById(pickBatchId)

    return response.data.data.getPickBatchById
  }
}

export default actions
