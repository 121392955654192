export function stringLimit(value: string, limit = 50, excerpt = '') {
  if (excerpt.length > 0) {
    limit -= excerpt.length
  }

  return `${value.substring(0, limit)}${excerpt}`
}

export function lcFirst(value: string) {
  if (value.length <= 1) {
    return value.toLowerCase()
  }

  return `${value.charAt(0).toLowerCase()}${value.slice(1)}`
}

export function ucFirst(value: string) {
  if (value.length <= 1) {
    return value.toUpperCase()
  }

  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`
}

export const asBoolean = (value?: string | boolean | null): boolean => {
  if (value === undefined || value === '' || value === null) {
    return false
  }

  if (typeof value === 'boolean') {
    return value
  }

  const stringValue = String(value).toLowerCase()

  return stringValue === 'true' || stringValue === '1'
}

/** StudlyCase is the same as PascalCase */
export const studlyToSentence = (value: string) => {
  let words = value.split(/(?=[A-Z])/)

  words = words.map((word) => word.trim().toLowerCase())

  words[0] = ucFirst(words[0])

  return words.join(' ')
}

export const randomString = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0

  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter++
  }

  return result
}

export const cleanScanInput = (value: string, eventKey: string) => {
  if (value.startsWith(eventKey) || value.endsWith(eventKey)) {
    value.replace(eventKey, '')
  }

  return value.replace(/Shift/g, '').replace(/\r?\n|\r/g, '')
}

export const arrayToString = (values: any[], glue = ' - '): string => {
  return values.filter((n) => n).join(glue)
}
