<template>
  <f7-sheet
    swipe-to-close
    backdrop
    :opened="sheetOpen"
    class="sheet bg-color-white"
    @sheet:closed="onClosed"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-title>Previous locations</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onClosed">
            <f7-icon material="close" />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <previous-location-table :previous-locations="previousLocations" />
    </f7-page>
  </f7-sheet>
</template>
<script lang="ts" setup>
import { IPreviousLocation } from '@graphql/article/types'
import PreviousLocationTable from '@components/Article/PreviousLocationTable.vue'

const sheetOpen = defineModel<boolean>()

defineProps<{
  previousLocations: IPreviousLocation[]
}>()

const onClosed = () => {
  sheetOpen.value = false
}
</script>

<style scoped>
.sheet {
  height: 85vh;
}
</style>
