import { ICountItem } from '@store/modules/projects/count/types'
import { useStore } from '@store/store'
import { computed } from 'vue'

export default () => {
  const store = useStore()

  const isActive = computed<boolean>(() => store.getters['projects/count/isActive'])
  const itemCount = computed<number>(() => store.getters['projects/count/itemCount'])
  const items = computed<ICountItem[]>(() => store.getters['projects/count/items'])

  const confirm = async () => store.dispatch('projects/count/confirm')

  const add = async (project: ICountItem) => store.commit('projects/count/add', project)
  const remove = async (project: ICountItem) => store.commit('projects/count/remove', project)
  const clear = async () => store.commit('projects/count/clear')
  const update = async (project: ICountItem) => store.commit('projects/count/update', project)

  return {
    isActive,
    itemCount,
    items,
    confirm,
    add,
    remove,
    clear,
    update
  }
}
