<template>
  <f7-page :page-content="false">
    <f7-navbar>
      <f7-nav-title>
        {{ studlyToSentence(parserResult.type) }}
      </f7-nav-title>
    </f7-navbar>
    <f7-page-content>
      <f7-list media-list>
        <ul>
          <component
            v-for="(result, key) in parserResult.results"
            :result="result"
            :is="`${parserResult.type}Result`"
            :key="key"
            @over-swipe="onSelect(result)"
          >
            <template #actions>
              <f7-swipeout-actions left>
                <f7-swipeout-button color="dimass" overswipe close>
                  <f7-icon material="swipe-right" class="text-color-white" />
                </f7-swipeout-button>
              </f7-swipeout-actions>
            </template>
          </component>
        </ul>
      </f7-list>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import { soundBoard } from '@/services/sound'
import { eventBus, IEvent } from '@/utilities/scanInput'
import { studlyToSentence } from '@/utilities/string'
import { ParserResult } from '@services/search/parser'
import { toast } from '@services/toast'
import { UnionTypeSearchResult } from '@store/modules/search/types'
import { computed, onBeforeUnmount } from 'vue'

const props = defineProps<{
  parserResult: ParserResult
  scanInputEnabled: boolean
}>()

const emits = defineEmits<{
  (e: 'result', payload: ParserResult)
}>()

const captureScanInput = computed<boolean>(() => props.scanInputEnabled)

const onSelect = (result: UnionTypeSearchResult) => {
  emits('result', result)
}

const onScanInput = async (event: IEvent) => {
  event.stopPropagation()

  const value = String(event.payload)

  if (!value || value.length === 0) {
    await soundBoard.playFailedSound()
    await toast.error('Scan not recognized!').open()
    return
  }

  const identifier = '##LH##'

  if (!value.startsWith(identifier)) {
    await soundBoard.playFailedSound()
    await toast.error('Scan a height code!').open()
    return
  }

  const heightCode = value.replace(identifier, '')

  const results = props.parserResult.results
  const index = results.findIndex((result) => result?.name?.endsWith(heightCode))

  if (index === -1) {
    await soundBoard.playFailedSound()
    await toast.error('Invalid height code: ' + heightCode).open()
    return
  }

  await onSelect(results[index])
}

const unsubscribe = eventBus.on(
  'scanInput',
  async (event) => {
    if (!captureScanInput.value) {
      return
    }

    await onScanInput(event)
  },
  true
)

onBeforeUnmount(() => {
  unsubscribe()
})
</script>
