<template>
  <f7-page :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar :search-id="searchId" expandable />
    </navigation>

    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#po-detail" tab-link-active>Detail</f7-link>
      <f7-link tab-link="#po-articles">Articles</f7-link>
      <f7-link tab-link="#po-goods-receipts">Goods receipts</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="po-detail" class="page-content" tab-active>
        <f7-block-title
          v-if="purchaseOrder"
          class="margin-top"
          :class="{ 'skeleton-text': isLoading }"
        >
          Purchase order #{{ purchaseOrder.id }}
        </f7-block-title>

        <f7-card media-list :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list v-if="purchaseOrder" media-list>
              <f7-list-item header="Reference">{{ purchaseOrder.reference }}</f7-list-item>
              <f7-list-item header="Delivery ETA">
                <date-time-label :value="purchaseOrder.deliveredAt"></date-time-label>
              </f7-list-item>
              <f7-list-item header="Supplier">{{ purchaseOrder.supplier.name }}</f7-list-item>
              <f7-list-item header="Supplier Creditor" v-if="purchaseOrder.supplierCreditor">
                {{ purchaseOrder.supplierCreditor.supplier.name }}
              </f7-list-item>
              <f7-list-item header="State">{{ purchaseOrder.stateString }}</f7-list-item>
              <f7-list-item header="Site" v-if="purchaseOrder.site">{{
                purchaseOrder.site.name
              }}</f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-tab>

      <f7-tab id="po-articles" class="page-content">
        <f7-card class="data-table data-table-init" :class="{ 'skeleton-text': isLoading }">
          <f7-card-content
            v-if="
              purchaseOrder &&
              purchaseOrder.purchaseOrderLine &&
              purchaseOrder.purchaseOrderLine.length === 0
            "
          >
            No purchase order lines available.
          </f7-card-content>
          <f7-card-content class="no-padding" v-else>
            <table>
              <thead>
                <tr>
                  <th class="numeric-cell">Qty</th>
                  <th class="label-cell">Article</th>
                </tr>
              </thead>
              <tbody v-if="purchaseOrder && purchaseOrder.purchaseOrderLine">
                <tr
                  v-for="(purchaseOrderLine, index) in purchaseOrder.purchaseOrderLine"
                  :key="index"
                >
                  <td class="numeric-cell">
                    {{ purchaseOrderLine.amount }} ({{ purchaseOrderLine.qtyDelivered }})
                  </td>
                  <td class="label-cell">{{ purchaseOrderLine.article.name }}</td>
                </tr>
              </tbody>
            </table>
          </f7-card-content>
        </f7-card>
      </f7-tab>

      <f7-tab id="po-goods-receipts" class="page-content">
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list v-if="purchaseOrder" media-list>
              <f7-list-item
                v-for="goodsReceipt in purchaseOrder.goodsReceipt"
                :key="goodsReceipt.id"
                @click="onGoodsReceiptClick(goodsReceipt)"
              >
                <template #title> GoodsReceipt #{{ goodsReceipt.id }} </template>
                <template #subtitle>
                  <date-time-label :value="goodsReceipt.deliveredAt"></date-time-label>
                </template>
              </f7-list-item>
              <f7-list-item v-if="purchaseOrder.goodsReceipt.length === 0"
                >No goods receipt available!</f7-list-item
              >
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-tab>
    </f7-tabs>

    <app-actions
      v-if="actions.length > 0"
      :actions="actions"
      :fixed="true"
      @action-clicked="onActionClicked"
    />
  </f7-page>
</template>
<script lang="ts" setup>
import { IAction } from '@components/actions'
import AppActions from '@components/AppActions.vue'
import DateTimeLabel from '@components/label/DateTime.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import useAuthentication from '@composables/useAuthentication'
import useGoodsReceipt from '@composables/useGoodsReceipt'
import useLoading from '@composables/useLoading'
import useProjectInbound from '@composables/useProjectInbound'
import usePurchaseOrder from '@composables/usePurchaseOrder'
import useRouter from '@composables/useRouter'
import { IGoodsReceipt, StateEnum as GoodsReceiptStateEnum } from '@graphql/goodsReceipt/types'
import { IPurchaseOrder, StateEnum } from '@graphql/purchaseOrder/types'
import { IInboundProject } from '@store/modules/projects/inbound/types'
import { f7 } from 'framework7-vue'
import { onBeforeMount, ref } from 'vue'

const props = defineProps<{
  item: Partial<IPurchaseOrder>
}>()

const { isLoading } = useLoading()
const { getIdParamFromRoute } = useRouter()
const { currentUser } = useAuthentication()
const { purchaseOrder, getPurchaseOrderById } = usePurchaseOrder()
const { add } = useProjectInbound()
const { startGoodsEntry } = useGoodsReceipt()

const actions = ref<IAction[]>([])

const onActionClicked = async (action: IAction) => {
  if (action.href === '/projects/inbound/') {
    const project: IInboundProject = {
      id: action.props.item.id,
      items: [],
      addedBy: currentUser.value,
      addedOn: new Date()
    }

    await add(project)

    await startGoodsEntry({ goodsReceiptId: project.id })

    await f7.views.main.router.navigate(`/goodsentry/${project.id}/`)
  }
}

const hasOpenGoodsReceipts = (purchaseOrder: IPurchaseOrder) => {
  return getOpenGoodsReceipts(purchaseOrder).length > 0
}

const getOpenGoodsReceipts = (purchaseOrder: IPurchaseOrder): IGoodsReceipt[] => {
  return purchaseOrder.goodsReceipt.filter((g: IGoodsReceipt) => {
    return (
      g.state === GoodsReceiptStateEnum.GOODS_RECEPTION ||
      g.state === GoodsReceiptStateEnum.GOODS_ENTRY
    )
  })
}

const getPurchaseOrder = async () => {
  const id = await getIdParamFromRoute(props.item)
  if (id) {
    await getPurchaseOrderById(id)
    isLoading.value = false
  }
}

const onGoodsReceiptClick = async (goodsReceipt: IGoodsReceipt) => {
  await f7.views.main.router.navigate(`/goodsreceipt/${goodsReceipt.id}/`)
}

onBeforeMount(async () => {
  await getPurchaseOrder()

  if (purchaseOrder.value.state < StateEnum.DELIVERED) {
    if (hasOpenGoodsReceipts(purchaseOrder.value)) {
      actions.value.push({
        name: 'Goods entry',
        href: '/projects/inbound/',
        props: {
          item: getOpenGoodsReceipts(purchaseOrder.value)[0]
        }
      })
    }
  }
})
</script>
<style scoped>
.actions-header {
  margin: 0 0 5px 0;
}
</style>
