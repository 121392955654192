import actions from '@store/modules/projects/inbound/actions'
import getters from '@store/modules/projects/inbound/getters'
import mutations from '@store/modules/projects/inbound/mutations'
import { IProjectInboundState } from '@store/modules/projects/inbound/types'
import { IRootState } from '@store/types'
import { Module } from 'vuex'

const state: IProjectInboundState = {
  count: 0,
  title: 'Inbound',
  type: 'inbound',
  isActive: false,
  itemCount: 0,
  items: [],
  goodsEntries: undefined,
  activePurchaseOrder: undefined,
  activeGoodsEntry: undefined
}

const projectInbound: Module<IProjectInboundState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default projectInbound
