<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-title> Location Suggester</f7-nav-title>
      <f7-nav-right>
        <f7-link @click="$emit('close', true)">
          <f7-icon material="close" />
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block>
      <f7-list id="form">
        <f7-list-input
          v-if="warehouseId == undefined"
          label="Warehouse"
          type="select"
          :value="form.warehouseId"
          validate
          required
          error-message="Warehouse can't be empty..."
          @input="form.warehouseId = $event.target.value"
          placeholder="Select a warehouse..."
        >
          <option value="">Select warehouse</option>
          <option
            :value="warehouse.id"
            v-for="warehouse in warehouses"
            :key="warehouse.id"
          >
            {{ warehouse.name }}
          </option>
        </f7-list-input>

        <f7-list-input
          label="Location type"
          type="select"
          :value="form.locationTypeCode"
          @input="form.locationTypeCode = $event.target.value"
          placeholder="Select an location type..."
        >
          <option value="">Select location type</option>
          <option
            :value="locationType.key"
            v-for="locationType in locationTypes"
            :key="locationType.key"
          >
            {{ locationType.value }}
          </option>
        </f7-list-input>

        <f7-list-input
          label="Hallway (and rack)"
          :value="form.locationQuery"
          @input="form.locationQuery = $event.target.value"
          type="text"
          placeholder="Hallway (and rack) e.g. 1A or 2-B"
        />

        <f7-list-item
          checkbox
          title="Only empty locations"
          :checked="form.onlyEmptyLocations"
          @change="form.onlyEmptyLocations = $event.target.checked"
        />
      </f7-list>

      <f7-row>
        <f7-col>
          <f7-button large fill-md color="text-secondary" @click="onSubmit"> Search</f7-button>
        </f7-col>
        <f7-col>
          <f7-button large outline @click="onClear"> Clear</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-block :class="{ 'skeleton-text': isLoading }">
      <f7-list media-list>
        <f7-list-item
          v-for="(searchResult, key) in suggestions"
          :title="searchResult.name"
          :search-result="searchResult"
          :key="key"
          swipeout
          @swipeout:opened="onOverSwipeLeft(searchResult)"
        >
          <template #subtitle v-if="searchResult.articleLocation">
            <span v-for="articleLocation in searchResult.articleLocation" :key="articleLocation.id">
              {{ articleLocation.article.primaryBarcode ?? articleLocation.article.code }} (Qty:
              {{ articleLocation.stockLevel.physicalTotal }})<br />
            </span>
          </template>

          <template #after-title>
            <location-height-badge :location-properties="searchResult.locationProperties" />
          </template>
          <f7-swipeout-actions left>
            <f7-swipeout-button color="dimass" overswipe close>
              <f7-icon material="swipe-right" class="text-color-white" />
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
        <f7-list-item
          v-if="suggestions.length === 0 && searchPerformed"
          title="No results found..."
        />
      </f7-list>
    </f7-block>
  </f7-page>
</template>
<script lang="ts" setup>
import LocationHeightBadge from '@components/LocationHeightBadge.vue'
import { ILocation } from '@graphql/location/types'
import { ILocationSuggestionContext } from '@store/modules/location/types'
import { ID } from '@graphql/types'
import useLoading from '@composables/useLoading'
import useLocation from '@composables/useLocation'
import { onMounted, onUnmounted, ref } from 'vue'
import useWarehouse from '@composables/useWarehouse'
import { IWarehouse } from '@graphql/warehouse/types'
import { f7 } from 'framework7-vue'

const { getList } = useWarehouse()

const props = withDefaults(
  defineProps<{
    clearState?: boolean
    companyId?: ID
    warehouseId?: ID
    items?: ILocation[]
  }>(),
  {
    companyId: undefined,
    warehouseId: undefined,
    clearState: false,
    items: () => []
  }
)

const emits = defineEmits<{
  (e: 'selected-item', payload: ILocation): void
  (e: 'close', payload: unknown): void
}>()

const form = ref<{
  warehouseId: ID | null
  locationTypeCode: string | null
  locationQuery: string | null
  onlyEmptyLocations: boolean
}>({
  warehouseId: props.warehouseId ?? null,
  locationTypeCode: null,
  locationQuery: null,
  onlyEmptyLocations: false
})

const searchPerformed = ref(false)
const warehouses = ref<IWarehouse[]>([])

const locationTypes = [
  {
    key: 'PALLET',
    value: 'Pallet'
  },
  {
    key: 'SHELF_MEDIUM',
    value: 'Shelf medium'
  },
  {
    key: 'SHELF_SMALL',
    value: 'Shelf small'
  }
]

const { isLoading } = useLoading()
const { clearSuggestions, getSuggestions, suggestions } = useLocation()

const setWarehouses = async () => {
  warehouses.value = await getList()
}

const onSubmit = async () => {
  const isValid = f7.input.validateInputs('#form');
  if (!isValid) {
    return;
  }

  isLoading.value = true
  searchPerformed.value = true

  const context: ILocationSuggestionContext = {
    warehouseId: form.value.warehouseId,
    companyId: props.companyId,
    onlyEmptyLocations: form.value.onlyEmptyLocations
  }

  if (form.value.locationTypeCode) {
    context.locationTypeCode = form.value.locationTypeCode
  }

  if (form.value.locationQuery) {
    context.locationQuery = form.value.locationQuery
  }

  await getSuggestions(context)

  isLoading.value = false
}

const onOverSwipeLeft = async (item: ILocation) => {
  await emits('selected-item', item)
}

const onClear = async () => {
  searchPerformed.value = false
  form.value.onlyEmptyLocations = false
  form.value.locationTypeCode = null
  form.value.locationQuery = null

  await clearSuggestions()
}

onMounted(() => {
  setWarehouses()
})

onUnmounted(async () => {
  await clearSuggestions()
})
</script>
