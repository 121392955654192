import actions from '@store/modules/warehouse/actions'
import { IRootState } from '@store/types'
import { Module } from 'vuex'
import { IWarehouseState } from '@store/modules/warehouse/types'

const namespaced = true

const warehouse: Module<IWarehouseState, IRootState> = {
  namespaced,
  actions,
}

export default warehouse
