import PackPage from '@pages/pack/StartPage.vue'
import ProcessPage from '@pages/pack/ProcessPage.vue'
import PackingControlPage from '@pages/pack/ControlPage.vue'
import { Router } from 'framework7/types'

const routes: Partial<Router.RouteParameters>[] = [
  {
    path: '/pack/',
    component: PackPage
  },
  {
    path: '/pack/process-load-carrier/',
    component: ProcessPage,
    options: {
      props: {
        loadCarrierId: undefined
      }
    }
  },
  {
    path: '/pack/packing-control/',
    component: PackingControlPage,
    options: {
      props: {
        packingList: undefined,
        article: undefined
      }
    }
  }
]

export default routes
