import actions from '@store/modules/pick-batch/actions'
import getters from '@store/modules/pick-batch/getters'
import mutations from '@store/modules/pick-batch/mutations'
import { IPickBatchState } from '@store/modules/pick-batch/types'
import { IRootState } from '@store/types'
import { Module } from 'vuex'

const state: IPickBatchState = {}

const pickBatch: Module<IPickBatchState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default pickBatch
