<template>
  <f7-card
    class="data-table data-table-init"
    :class="{
      'skeleton-text': isLoading,
      'data-table-collapsible': isCollapsible
    }"
  >
    <f7-card-header v-if="slots.header">
      <slot name="header"></slot>
    </f7-card-header>
    <f7-card-content v-if="items.length === 0">
      <slot name="no-items">No items to show.</slot>
    </f7-card-content>
    <f7-card-content class="no-padding" v-if="items.length > 0">
      <slot></slot>
    </f7-card-content>
  </f7-card>
</template>
<script lang="ts" setup>
import { useSlots } from 'vue'

withDefaults(
  defineProps<{
    isLoading: boolean
    items: any[]
    isCollapsible: boolean
  }>(),
  {
    isLoading: true,
    isCollapsible: false
  }
)

const slots = useSlots()
</script>
