<template>
  <f7-page :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar
        :search-id="searchId"
        expandable
        @result="onResult"
        @results="onResults"
      ></search-bar>
    </navigation>

    <f7-page-content>
      <template v-if="location">
        <f7-block-title class="margin-top" :class="{ 'skeleton-text': isLoading }">
          {{ location?.name }}
        </f7-block-title>
        <f7-block-header> Articles on location </f7-block-header>
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content v-if="!isLoading && location?.articleLocation.length > 0">
            <f7-list media-list>
              <f7-list-item
                v-for="(articleLocation, index) in location?.articleLocation"
                :key="index"
                link="#"
                @click="onArticleClick(articleLocation.article)"
              >
                <template #title>
                  <string-label :value="`(${articleLocation.article.company.code})`" />&nbsp;
                  <string-label
                    :value="articleLocation.article.primaryBarcode"
                    fallback-value="NO BARCODE"
                  />
                  <br />
                </template>
                <template #media>
                  <div style="display: block">
                    <string-label :value="`${getPhysicalTotal(articleLocation.stockLevel)}`" />
                    <div v-if="articleLocation.stockLevel.packing > 0">
                      <string-label :value="`~${articleLocation.stockLevel.packing}`" />
                    </div>
                  </div>
                </template>
                <template #subtitle>
                  {{ articleLocation.article.name }}
                </template>
              </f7-list-item>
            </f7-list>
          </f7-card-content>
          <f7-card-content v-if="!isLoading && location?.articleLocation.length === 0">
            <icon-with-text :icon="['far', 'map-marker-smile']" title="Location is empty" />
          </f7-card-content>
        </f7-card>

        <div v-if="isActive">
          <f7-block-header> Relocatable articles </f7-block-header>
          <f7-card :class="{ 'skeleton-text': isLoading }">
            <f7-card-content>
              <f7-list media-list>
                <f7-list-item
                  after="Put on location"
                  link="#"
                  v-for="(item, key) in items"
                  :key="key"
                  @click="relocate(key, item.article, location)"
                >
                  <template #title>
                    <string-label :value="`(${item.article.company.code})`" />&nbsp;
                    <string-label :value="item.article.primaryBarcode" />
                  </template>
                  <template #media>
                    <string-label :value="`${item.qty}`" />
                  </template>
                  <template #subtitle>
                    <string-label :value="item.article.name" />
                  </template>
                </f7-list-item>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </div>
      </template>
    </f7-page-content>

    <app-actions :actions="actions" @action-clicked="onActionClick" />
  </f7-page>
</template>
<script lang="ts" setup>
import { isArticle } from '@/functions/search'
import { IAction } from '@components/actions'
import AppActions from '@components/AppActions.vue'
import IconWithText from '@components/IconWithText.vue'
import StringLabel from '@components/label/StringLabel.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import useAction from '@composables/useAction'
import useArticle from '@composables/useArticle'
import useLoading from '@composables/useLoading'
import useLocation from '@composables/useLocation'
import useProjectRelocate from '@composables/useProjectRelocate'
import { IArticle } from '@graphql/article/types'
import { IArticleLocation, ILocation } from '@graphql/location/types'
import { TypeName } from '@graphql/search/types'
import { SearchStrategy } from '@services/search/search'
import { getPhysicalTotal } from '@store/modules/article/helper'
import { IRelocateItem } from '@store/modules/projects/relocate/types'
import { UnionTypeSearchResult } from '@store/modules/search/types'
import { f7 } from 'framework7-vue'
import { onBeforeMount, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    id: ID
    project?: string
  }>(),
  {
    project: undefined
  }
)

const { getById } = useLocation()
const { isLoading, withAsyncLoading } = useLoading()
const { items, isActive } = useProjectRelocate()
const { onActionClick } = useAction()
const { getArticle } = useArticle()

const actions = ref<IAction[]>([])
const location = ref<ILocation | undefined>()

onBeforeMount(async () => {
  await withAsyncLoading(async () => {
    location.value = await getById({ id: props.id })

    actions.value.push({
      name: 'Count',
      href: '/scan-input/',
      props: {
        items: location.value?.articleLocation?.map((al: Partial<IArticleLocation>) => al.article),
        searchType: TypeName.Article,
        searchStrategy: SearchStrategy.ScanInput,
        successUrl: '/count/'
      }
    })
  })
})

const onArticleClick = async (article: IArticle) => {
  await f7.views.main.router.navigate(`/article/${article.id}/`)
}

const onResult = async (result: UnionTypeSearchResult) => {
  if (isArticle(result)) {
    const index = items.value.findIndex((r: IRelocateItem) => r.article.id === result.id)

    if (index > -1) {
      const article = await getArticle({ id: result.id })

      await relocate(index, article, location.value as ILocation)
    }
  }
}

const relocate = async (
  index: number,
  article: IArticle | Partial<IArticle>,
  location: ILocation | Partial<ILocation>
) => {
  return f7.views.main.router.navigate(`/relocate/${index}/`, { props: { article, location } })
}

const onResults = (results: UnionTypeSearchResult[]) =>
  results.forEach((result: UnionTypeSearchResult) => onResult(result))
</script>
