<template>
  <f7-view>
    <f7-page :page-content="false">
      <navigation show-search-icon v-slot="{ searchId }" hide-back-button>
        <search-bar
          :search-id="searchId"
          :search-strategy="searchStrategy"
          :singular-result-callback="handleSuccessResult"
          :plural-result-callback="onPluralResult"
          @no-result="onNoResult"
          expandable
        />
      </navigation>
      <f7-page-content ptr :ptr-distance="50" ptr-mousewheel @ptr:refresh="onPullToRefresh">
        <pull-to-refresh-preloader />
        <location-header
          :is-loading="isLoading"
          :location-name="line.location.name"
          :is-scanned="isLocationScanned"
        />
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list media-list>
              <pick-list-item
                v-for="lineItem in line.lineItems"
                :key="lineItem.pickBatchLineItemId"
                :line-item="lineItem"
                @article-popup-open="articleDetailPopupOpened = true"
                @quantity-enter="onEnterQuantity(lineItem)"
              />
            </f7-list>
          </f7-card-content>
        </f7-card>

        <progress-card :progress="progression" :is-loading="isLoading" />
      </f7-page-content>
      <f7-popup
        :opened="selectionPopupOpened"
        class="search-results-popup"
        @popup:closed="selectionPopupOpened = false"
        :close-on-ecape="false"
        :close-by-backdrop-click="false"
        :tablet-fullscreen="true"
        :swipe-to-close="false"
      >
        <default-popup-header title="Select Location or article" />
        <search-result-list
          :items="selectionPopupResults"
          :search-strategy="searchStrategy"
          @result="handleSuccessResult"
          :is-loading="isLoading"
          :key="1"
        />
      </f7-popup>
      <article-detail-popup
        v-if="activeArticle"
        :article="activeArticle"
        :is-loading="isLoading"
        :popup-opened="articleDetailPopupOpened"
        @popup-closed="articleDetailPopupOpened = false"
      />

      <quantity-popup
        :popup-opened="quantityPopupOpened"
        @popup-closed="quantityPopupOpened = false"
        :scanned-item="scannedItem"
        :max-value="selectedLineItem?.quantityTodo"
        @quantity-entered="(value) => onPickedQuantitiesEntered(value, selectedLineItem)"
      />

      <template #fixed v-if="canAddLoadCarrier">
        <f7-fab position="center-bottom" v-if="isLoading">
          <f7-preloader />
        </f7-fab>
        <f7-fab position="center-button" v-else @click="onAddLoadCarrier">
          <font-awesome-icon :icon="['far', 'pallet-boxes']" />
        </f7-fab>
      </template>
    </f7-page>
  </f7-view>
</template>
<script lang="ts" setup>
import { isArticle, isLocation } from '@/functions/search'
import { NonEmptyArray } from '@/typings/types'
import ArticleDetailPopup from '@components/Article/DetailPopup.vue'
import Navigation from '@components/AppNavigation.vue'
import PickListItem from '@components/pick/PickListItem.vue'
import QuantityPopup from '@components/QuantityPopup.vue'
import SearchBar from '@components/search/SearchBar.vue'
import SearchResultList from '@components/search/ResultList.vue'
import useArticle from '@composables/useArticle'
import useLoading from '@composables/useLoading'
import usePickBatchLine from '@composables/usePickBatchLine'
import useProjectPick from '@composables/useProjectPick'
import { IArticle } from '@graphql/article/types'
import { ILine, ILineItem, IPickBatchLineItemInput, IProgress } from '@graphql/pick/types'
import { SearchStrategy } from '@services/search/search'
import { soundBoard } from '@services/sound'
import { toast } from '@services/toast'
import { UnionTypeSearchResult } from '@store/modules/search/types'
import { computed, ref, watch } from 'vue'
import { perceptibleToast } from '@services/perceptibleToast'
import ProgressCard from '@components/pick/ProgressCard.vue'
import LocationHeader from '@components/pick/LocationHeader.vue'
import DefaultPopupHeader from '@components/DefaultPopupHeader.vue'
import { f7 } from 'framework7-vue'
import { confirmYesNo } from '@/functions/dialog'
import { sleep } from '@/utilities/generic'
import PullToRefreshPreloader from '@components/PullToRefreshPreloader.vue'
import useLoadCarrier from '@composables/useLoadCarrier'
import { ID } from '@graphql/types'

const props = defineProps<{
  modelValue: ILine
  progression: IProgress
  process: string
  loadCarrierLineCount: number
}>()

const emit = defineEmits([
  'completed',
  'no-result',
  'refreshPickBatch',
  'update:model-value',
  'refreshState'
])

const selectionPopupOpened = ref<boolean>(false)
const selectionPopupResults = ref<UnionTypeSearchResult[]>([])
const articleDetailPopupOpened = ref<boolean>(false)
const quantityPopupOpened = ref<boolean>(false)
const selectedLineItem = ref<ILineItem | null>(null)
const scannedItem = ref<UnionTypeSearchResult | null>(null)

const { savePickBatchLineState } = useProjectPick()
const { addLoadCarrier } = useLoadCarrier()
const { withAsyncLoading, isLoading } = useLoading()
const { activeArticle } = useArticle()
const { isMatchingArticle, isMatchingLocation, quantityFulFilled, isQuantityFulfilled } =
  usePickBatchLine()

const searchStrategy = ref<string>(SearchStrategy.PickLine)
const currentLocation = ref<ID | undefined>()

const line = computed<ILine>(() => props.modelValue)
const lineItems = computed<ILineItem[]>(() => (line.value ? line.value.lineItems : []))
const loadCarrierLineCount = computed(() => props.loadCarrierLineCount)

const completedItems = computed<ILineItem[]>(() => {
  return lineItems.value.filter((lineItem) => {
    // cannot use `isQuantityFulfilled` here, causes behavior issues
    return lineItem.quantityTodo - (lineItem.quantityDone + lineItem.quantityMissed) === 0
  })
})

const isLocationScanned = computed<boolean>(() => isCurrentLocation(line.value.location.id))

const articlesScanned = computed<boolean>(
  () => completedItems.value.length === lineItems.value.length
)

const isComplete = computed<boolean>(() => isLocationScanned.value && articlesScanned.value)
const canAddLoadCarrier = computed(
  () =>
    loadCarrierLineCount.value > 0 ||
    (isLocationScanned.value &&
      lineItems.value.filter((li) => li.quantityDone > 0 && li.quantityDone < li.quantityTodo)
        .length > 0)
)

const isCurrentLocation = (locationId?: ID) => {
  if (!currentLocation.value || !locationId) {
    return false
  }
  return currentLocation.value === locationId
}

const handleSuccessResult = async (result: UnionTypeSearchResult) => {
  selectionPopupResults.value = []
  selectionPopupOpened.value = false

  if (isLocation(result)) {
    if (isMatchingLocation(line.value, result)) {
      await onLocation(result)
      return
    }
  }

  if (isArticle(result)) {
    const lineIndex = lineItems.value.findIndex((lineItem) => isMatchingArticle(lineItem, result))

    if (lineIndex !== -1) {
      await onArticle(result)
      return
    }
  }

  await perceptibleToast.error('The scan did not match any item(s) on this pick batch line!')
  emit('no-result', '')
}

const onLocation = async (result: UnionTypeSearchResult) => {
  currentLocation.value = result.id
  await soundBoard.playSearchSingleHit()
  return
}

const onConfirmQuantityEntered = async (result: IArticle) => {
  if (!selectedLineItem.value) {
    return
  }
  if (result.id !== selectedLineItem.value.article.id) {
    await perceptibleToast.error('Article does not match line where you enter quantity for!')
    return
  }

  scannedItem.value = result as IArticle

  await soundBoard.playSuccessSound()
}

const onArticle = async (result: UnionTypeSearchResult) => {
  const itemCount = line.value.lineItems.length

  for (let i = 0; i < itemCount; i++) {
    const lineItem = line.value.lineItems[i]

    if (lineItem.article.id !== result.id) {
      continue
    }

    if (isQuantityFulfilled(lineItem)) {
      continue
    }

    if (quantityPopupOpened.value) {
      await onConfirmQuantityEntered(result as IArticle)
      return
    }

    line.value.lineItems[i].quantityDone++
    await soundBoard.playSearchSingleHit()
    break
  }
}

const onPluralResult = async (results: UnionTypeSearchResult[]) => {
  for (let i = 0; i < results.length; i++) {
    const result = results[i]

    if (isLocation(result) && !isCurrentLocation(result.id)) {
      selectionPopupResults.value.push(result)
    }

    if (isArticle(result)) {
      const index = line.value.lineItems.findIndex((i) => isMatchingArticle(i, result))

      if (index === -1 || isQuantityFulfilled(line.value.lineItems[index])) {
        continue
      }

      selectionPopupResults.value.push(result)
    }
  }

  if (selectionPopupResults.value.length === 0) {
    await perceptibleToast.error('Your scan does not match any of the current items.')
    return
  }

  if (selectionPopupResults.value.length === 1) {
    await handleSuccessResult(selectionPopupResults.value[0])
    return
  }

  selectionPopupOpened.value = true
}

function getPopulatedItemsInput(quantityMustBeFulfilled = true) {
  const itemsInput: IPickBatchLineItemInput[] = []

  line.value.lineItems.forEach((lineItem) => {
    if (!quantityFulFilled(lineItem) && quantityMustBeFulfilled) {
      const message = `Quantity for ${lineItem.article.primaryBarcode} (${lineItem.article.name}) is not fulfilled!`
      toast.error(message, undefined, true).open()
      throw Error(message)
    }

    itemsInput.push({
      pickBatchLineItemId: lineItem.pickBatchLineItemId,
      quantityDone: lineItem.quantityDone,
      quantityMissed: lineItem.quantityMissed,
      quantityReturned: 0
    })
  })

  return itemsInput as NonEmptyArray<IPickBatchLineItemInput>
}

const onCompleted = async () => {
  try {
    await withAsyncLoading(async () => {
      const response = await savePickBatchLineState({
        pickBatchLineId: line.value.pickBatchLineId,
        pickBatchLineItemsInput: getPopulatedItemsInput()
      })

      if (!response) {
        return
      }

      await emit('completed', line.value)
    })
  } catch (e: any) {
    await toast.error(e.message).open()
    throw e
  }
}

const onNoResult = async (query: string) => {
  await perceptibleToast.error(`No results found for "${query}"`)
}

const onPickedQuantitiesEntered = async (value: number, lineItem: ILineItem) => {
  if (value > lineItem.quantityTodo) {
    await toast.error('Cannot enter more quantity then to pick from this location!').open()
    return
  }

  lineItem.quantityDone = value

  quantityPopupOpened.value = false
  scannedItem.value = null
  selectedLineItem.value = null
}

const onEnterQuantity = async (lineItem: ILineItem) => {
  selectedLineItem.value = lineItem
  quantityPopupOpened.value = true
}

const onAddLoadCarrier = async () => {
  await confirmYesNo({
    title:
      'Are you sure that you want to proceed with finalizing this load carrier and add another?',
    yesButtonCallback: async () => {
      await withAsyncLoading(async () => {
        try {
          const result = await addLoadCarrier({
            pickBatchLineId: line.value.pickBatchLineId,
            pickBatchLineItemsInput: getPopulatedItemsInput(false)
          })

          if (result.addLoadCarrier.startVerificationProcess) {
            await f7.views.main.router.navigate('/pick/confirm-picked/')
            return
          }

          await perceptibleToast.error('Adding load carrier failed!')
        } catch (e: any) {
          await perceptibleToast.error(e.message)
        }
      })
    }
  })
}

const onPullToRefresh = async (done: () => void) => {
  await emit('refreshState', true)
  await sleep(200) // simulate api call...
  await done()
}

watch(isComplete, async (newValue: boolean, oldValue: boolean) => {
  if (newValue && !oldValue) {
    await onCompleted()
  }
})
</script>
<style scoped>
.fab {
  position: absolute;
  bottom: 6rem;
  right: 1rem;
}
</style>
