import { IArticleLocation } from '@graphql/location/types'
import { store } from '@store/store'
import { IRootState } from '@store/types'
import { GetterTree } from 'vuex'
import { IProjectRelocateState, IRelocateItem } from './types'

const getters: GetterTree<IProjectRelocateState, IRootState> = {
  isActive(state): boolean {
    return state.items.length > 0
  },

  items(state): IRelocateItem[] {
    return state.items
  },

  count(state): number {
    return state.isActive ? 1 : 0
  },

  itemCount: function (state): number {
    const projectItems = [...state.items]
    const activeArticle = store.getters['stock/activeArticle']

    if (projectItems.length && activeArticle) {
      const activeProjectItem = projectItems.find((item: IRelocateItem) => {
        const articleLocations = activeArticle.articleLocation.filter(
          (al: IArticleLocation) => al.location.id === item.location.id
        )

        if (articleLocations.length > 0) {
          const articleLocation = articleLocations[0]
          return (
            `${item.articleID}-${item.locationID}` ===
            `${activeArticle.id}-${articleLocation.location.id}`
          )
        }
      })
      // TODO fix active issue (e.g. setting a project active in the store)
      if (activeProjectItem) {
        return activeProjectItem.qty
      }
    }

    return projectItems.length
  }
}

export default getters
