import { IEventState } from '@store/modules/event/types'
import { IKeyValuePair, IRootState } from '@store/types'
import { Module } from 'vuex'

const state: IEventState = {
  events: []
}

const event: Module<IEventState, IRootState> = {
  namespaced: true,
  state,
  getters: {
    events: (state) => state.events
  },
  actions: {
    scanInput({ commit }, payload: IKeyValuePair) {
      commit('scanInput', payload)
    },
    clear({ commit }) {
      commit('clear')
    }
  },
  mutations: {
    scanInput(state, payload: IKeyValuePair) {
      state.events.push(payload)
      state.events = []
    },
    clear(state) {
      state.events = []
    }
  }
}

export default event
