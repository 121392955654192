import warehouseApi from '@graphql/warehouse'
import { IWarehouse } from '@graphql/warehouse/types'
import { IRootState } from '@store/types'
import { ActionTree } from 'vuex'
import { IWarehouseState } from '@store/modules/warehouse/types'

const actions: ActionTree<IWarehouseState, IRootState> = {
  async getList(): Promise<IWarehouse[]> {
    const response = await warehouseApi.getList()

    if (response.data.data == undefined) {
      throw new Error('Response doesn\'t contain any data.')
    }

    return response.data.data.warehouseList.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
  }
}

export default actions
