<template>
  <f7-block-title class="margin-top" :class="{ 'skeleton-text': isLoading }">
    <string-label v-if="article" :value="article.name" />
  </f7-block-title>
  <f7-card :class="{ 'skeleton-text': isLoading }">
    <f7-card-content>
      <f7-list media-list>
        <f7-list-item header="Company">
          <string-label :value="article?.company.name" />
        </f7-list-item>
        <f7-list-item header="Code">
          <string-label :value="article?.code" />
        </f7-list-item>
        <f7-list-item header="Primary barcode">
          <template #title>
            <string-label :value="article?.primaryBarcode" />
          </template>
          <template #after v-if="article?.articleBarcodes.length > 1">
            <f7-button color="dimass" text-color="secondary" @click="showAllBarcodes = true">
              +{{ article.articleBarcodes.length - 1 }}
            </f7-button>
          </template>
        </f7-list-item>
        <f7-list-item header="Weight (gr.)">
          <string-label :value="article?.weight" fallback-value="0" />
        </f7-list-item>
        <f7-list-item header="Classifications" v-if="hasClassifications">
          <classifications-label :items="article?.classifications" />
        </f7-list-item>
      </f7-list>
    </f7-card-content>
  </f7-card>
</template>
<script lang="ts" setup>
import ClassificationsLabel from '@components/label/ClassificationsLabel.vue'
import StringLabel from '@components/label/StringLabel.vue'
import useArticle from '@composables/useArticle'
import { IArticle } from '@graphql/article/types'

const showAllBarcodes = defineModel<boolean>()

withDefaults(
  defineProps<{
    article: IArticle
    isLoading?: boolean
  }>(),
  {
    isLoading: false
  }
)

const { hasClassifications } = useArticle()
</script>
