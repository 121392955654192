import { useStore } from '@store/store'
import { IGetStockLevelRequest } from '@graphql/stock/types'

export default () => {
  const store = useStore()

  const getStockLevels = async (request: IGetStockLevelRequest) =>
    store.dispatch('stock/getStockLevels', request)

  return {
    getStockLevels
  }
}
