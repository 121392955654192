import { Attributes } from '@services/security/attributes'
import { IVoter, VoterResult } from '@services/security/types'
import { IUser } from '@store/modules/auth/types'
import { store } from '@store/store'

export class PackAccessVoter implements IVoter {
  vote(user: IUser, attributes: string[]): VoterResult {
    let result = VoterResult.ACCESS_ABSTAIN

    for (let index = 0; index < attributes.length; index++) {
      if (!this.isValidAttribute(attributes[index])) {
        continue
      }

      if (attributes[index] === Attributes.PACK_ACCESS) {
        if (store.getters['settings/packStation']) {
          return VoterResult.ACCESS_GRANTED
        }
        result = VoterResult.ACCESS_DENIED
      }
    }

    return result
  }

  public supports(attributes: string[]): boolean {
    return attributes.filter((attribute) => this.isValidAttribute(attribute)).length > 0
  }

  private isValidAttribute(attribute: string): boolean {
    return attribute.startsWith('ATTRIBUTE_')
  }
}
