<template>
  <f7-card :class="{ 'skeleton-text': isLoading }">
    <f7-card-content>
      <div v-if="compartmentDirectionResult && compartmentDirectionResult.compartmentReference">
        <div class="compartment-direction">
          <div class="load-carrier-reference">
            {{
              `${compartmentDirectionResult.compartmentReference.carrier}-${compartmentDirectionResult.compartmentReference.carrierType}`
            }}
          </div>
          <div class="compartment-direction-reference">
            {{ composeCompartmentName(compartmentDirectionResult.compartmentReference) }}
          </div>
          <p>
            Put article {{ compartmentDirectionResult.sortableItem.name }} ({{
              compartmentDirectionResult.sortableItem.barcode
            }}) in the displayed compartment
          </p>
        </div>
        <!-- SS-449 -->
        <!--        <f7-button @click="$emit('placement-confirmed')" raised fill color="text-secondary" style="height: 50px">-->
        <!--          <span>Confirm placement</span>-->
        <!--        </f7-button>-->
      </div>
      <icon-with-text
        v-else-if="sortCompleted"
        :icon="['far', 'laugh-beam']"
        title="Hooray, all done!"
      />
      <icon-with-text
        v-else
        :icon="['far', 'barcode-scan']"
        title="Scan article"
        description="Scan a barcode of an article to start the sorting process"
      />
    </f7-card-content>
  </f7-card>
  <f7-fab
    position="right-bottom"
    @click="onRequestNewCompartment"
    v-if="compartmentDirectionResult && compartmentDirectionResult.compartmentReference"
  >
    <font-awesome-icon :icon="['far', 'cart-plus']" size="lg" />
  </f7-fab>
</template>
<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import IconWithText from '@components/IconWithText.vue'
import { confirmYesNo } from '@/functions/dialog'
import { IPutItemInCompartmentResult } from '@graphql/sort/types'
import useSort from '@composables/useSort'

defineProps<{
  compartmentDirectionResult: IPutItemInCompartmentResult | undefined | null
  sortCompleted: boolean
  isLoading: boolean
}>()

const emits = defineEmits<{
  (e: 'wants-new-sort-compartment'): void
  (e: 'placement-confirmed'): void
}>()

const { composeCompartmentName } = useSort()

const onRequestNewCompartment = async () => {
  await confirmYesNo({
    title: 'Are you sure that you want a new compartment for the current item?',
    yesButtonCallback: async () => {
      await emits('wants-new-sort-compartment')
    }
  })
}
</script>
<style>
.compartment-direction {
  text-align: center;
}
.compartment-direction-reference {
  font-weight: 600;
  font-size: 140px;
  line-height: 120px;
  margin-bottom: 30px;
}

.load-carrier-reference {
  font-size: 20px;
}
</style>
