import { IArticle, IClassification, IStockLevel } from '@graphql/article/types'
import { IColorMap, ILocationProperties, ISearchResult } from '@graphql/search/types'
import { ID, INode } from '@graphql/types'
import { IWarehouse } from '@graphql/warehouse/types'

export interface ILocation extends ISearchResult {
  name: string
  locationProperties: ILocationProperties
  stockLevel: IStockLevel
  warehouse: IWarehouse
  locationType: ILocationType
  articleLocation: Partial<IArticleLocation>[]
}

export interface IArticleLocation extends INode {
  location: ILocation
  stockLevel: IStockLevel
  article: IArticle
}

export interface ILocationType extends INode {
  id: ID
  name: string
  code: string
  classifications: IClassification[]
}

export interface ILocationPickType extends INode {
  id: ID
  name: string
  priority: number
}

export const enum LocationTypeEnum {
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  VIRTUAL = 'VIRT',
  BOXES = 'BOXES',
  PALLET = 'PALLET',
  SHELF_SMALL = 'SHELF_SMALL',
  SHELF_MEDIUM = 'SHELF_MEDIUM',
  PICK_SHELF = 'PICK_SHELF',
  PICK_PALLET = 'PICK_PALLET',
  BULK_PALLET = 'BULK_PALLET',
  HAL_PALLET = 'HAL_PALLET',
  FLEX = 'FLEX',
  COOL_CELL = 'KC',
  CONTAINER_PSG15 = 'CP'
}

export class LocationHeightScan implements Partial<ILocation> {
  public __typename = 'Location'
  public id: ID

  constructor(
    public readonly name: string,
    public readonly locationProperties: ILocationProperties
  ) {
    this.id = Math.random()
  }
}

export class ColorMap implements IColorMap {
  constructor(
    public readonly foregroundColor: string,
    public readonly backgroundColor: string
  ) {}
}

export class LocationProperties implements ILocationProperties {
  public readonly __typename

  constructor(
    public readonly heightCode: string,
    public readonly colorMap: IColorMap
  ) {
    this.__typename = 'LocationProperties'
  }
}
