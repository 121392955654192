import useDialog from '@composables/useDialog'
import { ILine, ILineItem } from '@graphql/pick/types'
import { UnionTypeSearchResult } from '@store/modules/search/types'
import { confirmYesNo } from '@/functions/dialog'

export default () => {
  const { numberInputDialog } = useDialog()

  const isMatchingArticle = (lineItem: ILineItem, searchResult: UnionTypeSearchResult) =>
    lineItem.article.id === searchResult.id
  const isMatchingLocation = (line: ILine, searchResult: UnionTypeSearchResult) =>
    line.location.id === searchResult.id

  const quantityFulFilled = (lineItem: ILineItem): number => {
    return lineItem.quantityMissed + lineItem.quantityDone
  }

  const calculateQuantityNotFulfilled = (lineItem: ILineItem): number => {
    return lineItem.quantityTodo - quantityFulFilled(lineItem)
  }

  const isQuantityFulfilled = (lineItem: ILineItem): boolean => {
    return calculateQuantityNotFulfilled(lineItem) === 0
  }

  const calculateQuantityToPick = (lineItem: ILineItem) => {
    return lineItem.quantityTodo - lineItem.quantityMissed
  }

  const calculateQuantityToFulfill = (lineItem: ILineItem) => {
    return lineItem.quantityTodo - lineItem.quantityDone
  }
  const hasQuantityMissed = (lineItem: ILineItem): boolean => lineItem.quantityMissed > 0

  const showConfirmMispicksDialog = async (lineItem: ILineItem, quantityMissed: number) => {
    await confirmYesNo({
      title: `Are you sure, that quantity of ${quantityMissed} cannot be picked from the current location?`,
      yesButtonCallback: async () => (lineItem.quantityMissed = quantityMissed)
    })
  }

  const onQuantityMissed = async (lineItem: ILineItem) => {
    const quantityToFulfill = calculateQuantityToFulfill(lineItem)

    if (quantityToFulfill === 0) {
      return
    }

    if (quantityToFulfill > 1) {
      await numberInputDialog(
        'Enter missing quantity',
        lineItem.quantityMissed,
        quantityToFulfill,
        async (dialog, event, value) => {
          await showConfirmMispicksDialog(lineItem, Number(value))
        }
      )
      return
    }

    await showConfirmMispicksDialog(lineItem, 1)
  }

  return {
    quantityFulFilled,
    isMatchingArticle,
    isMatchingLocation,
    isQuantityFulfilled,
    calculateQuantityNotFulfilled,
    hasQuantityMissed,
    onQuantityMissed,
    showConfirmMispicksDialog,
    calculateQuantityToPick,
    calculateQuantityToFulfill
  }
}
