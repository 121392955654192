import { IPickBatch } from '@graphql/pick/types'
import { IProjectPickState, IUpdatePickBatchLineContext } from '@store/modules/projects/pick/types'
import { MutationTree } from 'vuex'

const mutations: MutationTree<IProjectPickState> = {
  addProject(state, payload: IPickBatch) {
    state.assignedPickBatch = payload
  },
  updateProject(state, payload: IPickBatch) {
    state.assignedPickBatch = payload
  },
  updatePickBatchLine(state, payload: IUpdatePickBatchLineContext) {
    if (state.assignedPickBatch === undefined) {
      return
    }

    const item = state.assignedPickBatch
    const lineIndex = item.pickBatchLines.findIndex((l) => l.id === payload.pickBatchLine.id)

    if (lineIndex === -1) {
      return
    }

    item.pickBatchLines[lineIndex] = payload.pickBatchLine
    if (item.processedLines) {
      item.processedLines++
    }
  },
  updateProcessedLines(state) {
    if (state.assignedPickBatch) {
      state.assignedPickBatch.processedLines++
    }
  },
  remove(state, pickBatchId: number) {
    if (state.assignedPickBatch !== undefined && state.assignedPickBatch.id === pickBatchId) {
      state.assignedPickBatch = undefined
    }
  },
  removeProjects(state) {
    state.items = []
    state.assignedPickBatch = undefined
  }
}

export default mutations
