<template>
  <f7-list form>
    <f7-list-input
      label="Name"
      type="text"
      placeholder="Name"
      v-model:value="username"
      data-testid="username"
    />

    <f7-list-input
      label="Password"
      type="password"
      placeholder="Password"
      v-model:value="password"
      data-testid="password"
    />
  </f7-list>

  <f7-block>
    <f7-button fill color="text-secondary" @click.prevent="onLoginClick" data-testid="submit">
      <f7-preloader :size="30" v-if="isLoading" />
      <span v-else>Login</span>
    </f7-button>
  </f7-block>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { toast } from '@services/toast'
import { f7 } from 'framework7-vue'
import useAuthentication from '@composables/useAuthentication'
import useLoading from '@composables/useLoading'

const username = ref('')
const password = ref('')

const { usernamePasswordLogin } = useAuthentication()
const { isLoading, withAsyncLoading } = useLoading()

const onLoginClick = async () => {
  if (!username.value || !password.value) {
    return toast.error('Invalid or no username and/or password provided.').open()
  }

  await withAsyncLoading(async () => {
    try {
      await usernamePasswordLogin({
        username: username.value,
        password: password.value
      })

      f7.views.main.router.navigate('/home/', { reloadAll: true })
    } catch (e: any) {
      await toast.error(e.message, 10000).open()
    }
  })
}
</script>
