<template>
  <icon-with-text
    title="Hold the NFC-tag near the reader."
    :icon="['far', 'nfc-signal']"
    style="color: #d6d95f"
  />
  <f7-block>
    <f7-button
      class="login-link no-ripple margin-top"
      small
      @click="onLoginWithUsername"
      data-testid="enable-token-login"
    >
      Login with username and password
    </f7-button>
  </f7-block>
</template>
<script setup lang="ts">
import IconWithText from '@components/IconWithText.vue'
import useAuthentication from '@composables/useAuthentication'
import { eventBus } from '@/utilities/scanInput'
import { onBeforeUnmount } from 'vue'
import { toast } from '@services/toast'

const { authenticatorLogin } = useAuthentication()

const emits = defineEmits<{
  (e: 'login-with-username'): void
}>()

const onLoginWithUsername = async () => {
  emits('login-with-username')
}

const unsubscribe = eventBus.on('authenticator.login', async (event) => {
  try {
    await authenticatorLogin({ token: event.payload })
  } catch (e: any) {
    toast.error(e.message).open()
  }
})

onBeforeUnmount(() => {
  unsubscribe()
})
</script>
