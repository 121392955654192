<template>
  <f7-page :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar
        :search-id="searchId"
        :search-type="searchType"
        :search-filters="searchFilters"
        :search-strategy="searchStrategy"
        expandable
        @result="onResult"
        @results="onSearchResults"
        @no-result="onNoSearchResult"
        key="pack-packing-control-search-bar"
      />
    </navigation>
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#packing-todo-lines" tab-link-active>
        Todo ({{ todoLinesCount }})
      </f7-link>
      <f7-link tab-link="#packing-done-lines"> Done ({{ doneLinesCount }})</f7-link>
      <f7-link tab-link="#packing-details"> Detail</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="packing-todo-lines" class="page-content" tab-active>
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list media-list v-if="todoLines">
              <template v-if="todoLinesCount > 0">
                <f7-list-item v-for="(todoLine, index) in todoLines" :key="index">
                  <template #title>
                    {{ todoLine.packableIdentity.name }}
                  </template>
                  <template #subtitle>
                    {{ todoLine.quantityPacked }}/{{ todoLine.quantity }}
                  </template>
                </f7-list-item>
              </template>
              <f7-list-item v-else title="Hooorraay, all done!" />
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-tab>
      <f7-tab id="packing-done-lines" class="page-content">
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list media-list v-if="doneLines">
              <template v-if="doneLinesCount > 0">
                <f7-list-item v-for="(doneLine, index) in doneLines" :key="index">
                  <template #title>
                    {{ doneLine.packableIdentity.name }}
                  </template>
                  <template #subtitle>
                    {{ doneLine.quantity }}
                  </template>
                </f7-list-item>
              </template>
              <f7-list-item v-else title="Nothing to show yet..." />
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-tab>
      <f7-tab id="packing-details" class="page-content">
        <details-card :is-loading="isLoading" :shipment="shipment" />
      </f7-tab>
    </f7-tabs>
    <f7-popup
      :opened="popupOpened"
      class="search-results-popup"
      @popup:closed="popupOpened = false"
      :close-on-ecape="false"
      :close-by-backdrop-click="false"
      :tablet-fullscreen="true"
      :swipe-to-close="false"
      :search-type="searchType"
      key="packing-control-search-results-popup"
    >
      <default-popup-header :title="`Select ${searchType}`" />

      <search-result-list
        :items="searchResults"
        :search-strategy="searchStrategy"
        @result="onPopupSearchResult"
        :search-type="searchType"
        :is-loading="isLoading"
        key="packing-control-search-results-list"
      />
    </f7-popup>
  </f7-page>
</template>
<script lang="ts" setup>
import DefaultPopupHeader from '@components/DefaultPopupHeader.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import SearchResultList from '@components/search/ResultList.vue'
import useLoading from '@composables/useLoading'
import useShipment from '@composables/useShipment'
import { IArticle } from '@graphql/article/types'
import { IPackableItem, IPackableIdentity, IPackingList } from '@graphql/pack/types'
import { ISearchFilter, SearchStrategy } from '@services/search/search'
import { soundBoard } from '@services/sound'
import { toast } from '@services/toast'
import { f7 } from 'framework7-vue'
import { computed, nextTick, onBeforeMount, ref, watch } from 'vue'
import { perceptibleToast } from '@services/perceptibleToast'
import DetailsCard from '@components/pack/DetailsCard.vue'
import { TypeName } from '@graphql/search/types'
import usePack from '@composables/usePack'
import { IShipment } from '@graphql/shipment/types'
import { UnionTypeSearchResult } from '@store/modules/search/types'

const props = withDefaults(
  defineProps<{
    packingList: IPackingList
    packableIdentity?: IPackableIdentity
    barcode: string
  }>(),
  {
    packableIdentity: undefined
  }
)

const { isLoading, withAsyncLoading } = useLoading()
const { finalizePackingControl } = useShipment()
const { packableItemIdMatches, transformSearchResult, printDocuments, IsMatchingIdentity } =
  usePack()

const searchType = TypeName.Article
const searchStrategy = SearchStrategy.PackingControlShipmentLine

const searchFilters = ref<ISearchFilter[]>([])
const searchResults = ref<Array<Partial<IArticle>>>([])
const todoLines = ref<Array<IPackableItem>>(props.packingList.packableItems)
const doneLines = ref<Array<IPackableItem>>([])
const popupOpened = ref<boolean>(false)
const initialLineCount = todoLines.value.length

const shipment = computed<IShipment>(() => props.packingList.shipment)

searchFilters.value.push({
  key: 'companyId',
  operator: '=',
  value: shipment.value.partner.id
})

const controlCompleted = computed<boolean>(() => {
  return initialLineCount > 0 && initialLineCount === doneLinesCount.value
})

const todoLinesCount = computed<number>(() => {
  if (!todoLines.value) {
    return 0
  }
  return todoLines.value.length
})

const doneLinesCount = computed<number>(() => {
  if (!doneLines.value) {
    return 0
  }
  return doneLines.value.length
})

const onSearchResults = async (results: Array<Partial<IArticle>>): Promise<void> => {
  searchResults.value = results
  popupOpened.value = true
}

const onSearchResult = async (result: IPackableIdentity): Promise<void> => {
  const matchingTodoEdges = todoLines.value.filter((packableItem) =>
    packableItemIdMatches(packableItem, result)
  )

  const matchingDoneEdges = doneLines.value.filter((packableItem) =>
    packableItemIdMatches(packableItem, result)
  )

  if (matchingTodoEdges.length === 0 && matchingDoneEdges.length === 0) {
    await handleArticleNotMatchingShipmentLine()

    return
  }

  if (matchingTodoEdges.length === 0) {
    await perceptibleToast.noSearchHit(`Item "${result.name}" is already in done!`)
    return
  }

  await computeResult(matchingTodoEdges[0])
}

const onNoSearchResult = async (query: string): Promise<void> => {
  await toast
    .error(`No item with barcode "${query}" found for partner ${shipment.value.flatPartner}!`)
    .open()
}

const handleArticleNotMatchingShipmentLine = async () => {
  await perceptibleToast.error('No shipment lines found for given barcode!')

  await f7.views.main.router.navigate('/pack/process-load-carrier/', {
    props: {
      loadCarrierId: props.packingList.loadCarrierId
    }
  })
}

const onPopupSearchResult = async (result: Partial<IArticle>): Promise<void> => {
  popupOpened.value = false
  searchResults.value = []

  await onSearchResult(transformSearchResult(result))
}

const computeResult = async (packableItem: IPackableItem): Promise<void> => {
  packableItem.quantityPacked++

  if (packableItem.quantity === packableItem.quantityPacked) {
    const todoLine = await findFirstMatchingLine(packableItem)
    if (todoLine) {
      doneLines.value.push(todoLine)
    }

    const index = todoLines.value.findIndex((item) => IsMatchingIdentity(item, packableItem))
    if (index === -1) {
      return
    }

    nextTick(() => {
      todoLines.value.splice(index, 1)
    }).then()

    if (!controlCompleted.value) {
      await soundBoard.playSuccessSound()
    }

    return
  }

  await soundBoard.playSearchSingleHit()
}

const findFirstMatchingLine = async (packableItem: IPackableItem) => {
  const results = todoLines.value.filter((pi) => IsMatchingIdentity(pi, packableItem))

  if (results.length === 0) {
    return
  }

  return results[0]
}

const onResult = async (result: UnionTypeSearchResult) => {
  return onSearchResult(transformSearchResult(result))
}

const finalize = async () => {
  const shipmentId = shipment.value.id

  try {
    await finalizePackingControl({ shipmentId })

    await perceptibleToast.finishedTask(`Control of shipment "${shipmentId}" succeeded!`, 5000)

    await printDocuments(
      {
        identifier: shipmentId,
        type: TypeName.Shipment,
        documentKind: null
      },
      props.packingList
    )
  } catch (e: any) {
    await toast.error(e.message).open()
    throw e
  }
}

watch(
  controlCompleted,
  async (newValue, oldValue) => {
    if (oldValue === newValue) {
      return
    }

    if (newValue) {
      await finalize()
    }
  },
  {
    immediate: true
  }
)

onBeforeMount(async () => {
  await withAsyncLoading(async () => {
    doneLines.value = []
  })
})

//incoming item is already scanned, therefore should be (pre-)processed.
if (props.packableIdentity) {
  onSearchResult(props.packableIdentity).then()
}
</script>
