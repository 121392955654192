import { ActionTree } from 'vuex'
import { IProjectSortingState, ISortingProject } from '@store/modules/projects/sorting/types'
import { IRootState } from '@store/types'

const actions: ActionTree<IProjectSortingState, IRootState> = {
  async clearProjects({ commit }) {
    await commit('clearProjects')
  },
  async addProject({ commit }, project: ISortingProject) {
    await commit('add', project)
  },
  async updateProject({ commit }, project: ISortingProject) {
    await commit('update', project)
  },
  async removeProject({ commit }, pickBatchId) {
    await commit('remove', pickBatchId)
  }
}

export default actions
