import { Module } from 'vuex'
import { IRootState } from '../../types'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { ISearchState } from './types'

const state: ISearchState = {
  searchResults: []
}

const search: Module<ISearchState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default search
